
	import Vue from 'vue';
	import {Component, Emit, Prop} from 'vue-property-decorator';

	@Component
	export default class DeleteButton extends Vue {

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public xSmall!: boolean;

		@Prop({type: String, default: null})
		public confirmText!: string | null;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		@Prop({type: Boolean, default: false})
		public icon!: boolean;

		@Prop({type: String, default: undefined})
		public color!: string | undefined;

		private dialog: boolean = false;

		@Emit('delete')
		public confirmDelete(event: Event): Event {
			this.dialog = false;
			return event;
		}

	}
