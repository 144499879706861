
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import SkinAutocomplete from "@/components/skin/SkinAutocomplete.vue";
	import SkinAvatar from "@/components/skin/SkinAvatar.vue";
	import {ISkin} from "@/models/ISkin";
	import ItemAutocomplete from "@/components/item/ItemAutocomplete.vue";

	@Component({
		components: {ItemAutocomplete, SkinAvatar, SkinAutocomplete}
	})
	export default class SkinSetPriceItemStoreDialog extends Vue {

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		@Prop({type: Object, required: true})
		public skin!: ISkin;

		private loading: boolean = false;
		private workshopId: number | null = null;

		private reset(): void {
			this.workshopId = this.skin.workshop_id;
		}

		private trySave(): void {
			this.loading = true;
			this.$store.dispatch('skin/setWorkshopId', {id: this.skin.id, workshopId: this.workshopId})
				.then(() => this.save(this.workshopId))
				.finally(() => this.loading = false);
		}

		@Watch('skin.workshop_id', {immediate: true})
		private onSkinWorkshopIdChanged(): void {
			this.reset();
		}

		@Watch('open')
		private onOpenChanged(value: boolean): void {
			if (!value) this.reset();
		}

		@Emit('cancel')
		public cancel(): void {
			//do nothing
		}

		@Emit('save')
		public save(workshopId: number | null): number | null {
			return workshopId;
		}

	}
