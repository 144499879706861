import AsiListTableOptions from "@/components/common/AsiListTableOptions";

export default abstract class AxiosService {

	private readonly baseUrl!: string;

	public constructor() {
		this.baseUrl = this.defineBaseUrl().trim();
		if (!this.baseUrl.startsWith('http') && !this.baseUrl.startsWith('/')) {
			this.baseUrl = '/' + this.baseUrl;
		}
	}

	/**
	 * Method will create an url by prefixing the base url to the path specified. Leading slash
	 * is optional, since it will be added automatically.
	 * @param path the optional url path after the base url (leading slash will be added automatically)
	 * @param baseUrl optional base url override for cross service calls (defaults to defined base url of this service)
	 * @protected
	 */
	public url(path: string | null = null, baseUrl: string = this.baseUrl): string {
		if (path !== null && !path.startsWith('/')) {
			path = '/' + path;
		}

		return `${baseUrl}${path === null ? '' : path}`;
	}

	/**
	 * finalizes and translates the options for transmission to the api
	 * @param options
	 * @protected
	 */
	protected static finalizeOptions(options: AsiListTableOptions): object {
		return {
			'page': options.page,
			'per-page': options.itemsPerPage,
			'sort-by': options.sortBy,
			'sort-desc': options.sortDesc,
		};
	}

	/**
	 * Defines the base url of the service class. Leading slash is optional, since it will be added
	 * automatically.
	 * @protected
	 */
	protected abstract defineBaseUrl(): string;

}
