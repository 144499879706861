
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {Routes} from "@/helpers/Routes";
	import {IKit} from "@/models/IKit";
	import {ISkin} from "@/models/ISkin";
	import KitHelper from "@/helpers/KitHelper";
	import EntityCard from "@/components/common/EntityCard.vue";
	import IProgress from "@/models/IProgress";

	@Component({
		components: {EntityCard}
	})
	export default class KitCard extends Vue {

		@Prop({type: Object, required: true})
		public kit!: IKit;

		@Prop({type: Boolean, default: false})
		public showFavourite!: boolean;

		@Prop({type: Boolean, default: false})
		public noValues!: boolean;

		@Prop({type: Boolean, default: false})
		public valuesVertical!: boolean;

		private get skins(): ISkin[] {
			return this.$store.state.skin.skins.filter((skin: ISkin) => this.kit.skin_ids.includes(skin.id));
		}

		private get iconUrl(): string | null {
			return this.$store.state.ui.showAvatars
				? KitHelper.iconUrl(this.kit)
				: null;
		}

		private get progress(): IProgress {
			return this.$store.getters['inventory/skinProgress'](this.kit.skin_ids);
		}

		private get progressBgColor(): string | null {
			if (this.progress.progress === 100) return '#e1e9e5';
			if (this.progress.progress > 0) return '#e1e5e9';
			return null;
		}

		private get kitValue(): number {
			return this.$store.getters['skin/valueOfSkins'](this.kit.skin_ids);
		}

		private get kitValueOwned(): number {
			return this.$store.getters['inventory/ownedValueOfSkins'](this.kit.skin_ids);
		}

		private get kitValueOpen(): number {
			return this.$store.getters['inventory/openValueOfSkins'](this.kit.skin_ids);
		}

		private get isFavourite(): boolean {
			return this.$store.getters['kit/isFavourite'](this.kit.id);
		}

		private get isGlobal(): boolean {
			return this.kit.user_id === null;
		}

		private showKit(): void {
			this.$router.push({name: Routes.kit, params: {id: this.kit.id.toString()}});
		}

	}
