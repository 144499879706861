
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import ItemAutocomplete from "@/components/item/ItemAutocomplete.vue";
	import ItemAvatar from "@/components/item/ItemAvatar.vue";
	import {IItem} from "@/models/IItem";

	@Component({
		components: {ItemAvatar, ItemAutocomplete}
	})
	export default class LoadoutSlotDialog extends Vue {

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		@Prop({type: Number, default: null})
		public itemId!: number | null;

		@Prop({type: Number, default: 1})
		public itemAmount!: number | null;

		@Prop({type: Number, default: null})
		public description!: string | null;

		private loading: boolean = false;
		private itemIdInternal: number | null = null;
		private itemAmountInternal: number = 1;
		private descriptionInternal: string | null = null;

		private get selectedItem(): IItem | null {
			return this.itemIdInternal === null
				? null
				: this.$store.getters['item/itemById'](this.itemIdInternal);
		}

		public created(): void {
			this.reset();
		}

		private reset(): void {
			this.itemIdInternal = this.itemId;
			this.itemAmountInternal = this.itemAmount ?? 1;
			this.descriptionInternal = this.description;
		}

		@Watch('itemId')
		@Watch('itemAmount')
		@Watch('description')
		private onPropsChanged(): void {
			this.reset();
		}

		@Watch('open')
		private onOpenChanged(value: boolean): void {
			if (!value) this.reset();
		}

		@Emit('cancel')
		public cancel(): void {
			//do nothing
		}

		@Emit('requestSave')
		public requestSave(): { item_id: number, item_amount: number, description: string | null } | null {
			return this.itemIdInternal === null
				? null
				: {
					item_id: this.itemIdInternal,
					item_amount: this.itemAmountInternal,
					description: this.descriptionInternal,
				};
		}

		private executeClear(): void {
			this.itemIdInternal = null;
			this.requestSave();
		}

	}
