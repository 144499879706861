
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import SkinList from "@/components/skin/SkinList.vue";
	import InventoryList from "@/components/inventory/InventoryList.vue";

	@Component({
		components: {InventoryList, SkinList}
	})
	export default class InventoryView extends Vue {

	}
