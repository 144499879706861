import {State} from "@/store";
import {Module} from "vuex";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import Vue from "vue";
import {IKit} from "@/models/IKit";

export interface Kit {
	loading: boolean,
	loaded: boolean,
	loadingFavourites: boolean,
	loadedFavourites: boolean,
	loadingKitIds: number[],
	kits: IKit[],
	cardView: boolean,
	favourites: number[],
	latestChange: number | null,
	lastAssignedIds: number[],
}

const kit: Module<Kit, State> = {
	namespaced: true,
	state: {
		loading: false,
		loaded: false,
		loadingFavourites: false,
		loadedFavourites: false,
		loadingKitIds: [] as number[],
		kits: [] as IKit[],
		cardView: false,
		favourites: [] as number[],
		latestChange: null,
		lastAssignedIds: [] as number[],
	},
	getters: {
		kitById: (state: Kit) => (id: number): IKit | null => {
			return state.kits.find(i => i.id === id) ?? null;
		},
		kitsByIds: (state: Kit) => (ids: number[]): IKit[] => {
			return state.kits.filter(k => ids.includes(k.id));
		},
		isFavourite: (state: Kit) => (id: number): boolean => {
			return state.favourites.includes(id);
		},
		isKitLoading: (state: Kit) => (id: number): boolean => {
			return state.loadingKitIds.includes(id);
		},
		kitsBySkinId: (state: Kit) => (skinId: number): IKit[] => {
			return state.kits.filter(k => k.skin_ids.includes(skinId));
		},
		favourites: (state: Kit) => (): IKit[] => {
			return state.kits.filter(k => state.favourites.includes(k.id));
		},
		hasKitPermission: (state: Kit, getters, rootState, rootGetters) => (id: number): boolean => {
			const userId = rootState.user.userId;
			const kit = getters['kitById'](id);
			if (userId === null || kit === null) return false;
			const isAdmin = rootGetters['user/isAdmin'](userId);
			return kit.user_id === userId || isAdmin;
		},
		lastAssignedKits: (state: Kit, getters) => (): IKit[] => {
			return state.lastAssignedIds.map(id => getters['kitById'](id));
		},
	},
	mutations: {
		setLoading(state: Kit, isLoading: boolean): void {
			state.loading = isLoading;
		},
		setLoaded(state: Kit, isLoaded: boolean): void {
			state.loaded = isLoaded;
		},
		setLoadingFavourites(state: Kit, isLoading: boolean): void {
			state.loadingFavourites = isLoading;
		},
		setLoadedFavourites(state: Kit, isLoaded: boolean): void {
			state.loadedFavourites = isLoaded;
		},
		setKitLoading(state: Kit, payload: { id: number, isLoading: boolean }): void {
			const isIncluded = state.loadingKitIds.includes(payload.id);
			if (payload.isLoading && !isIncluded) {
				state.loadingKitIds.push(payload.id);
			} else if (!payload.isLoading && isIncluded) {
				state.loadingKitIds = state.loadingKitIds.filter(id => id !== payload.id);
			}
		},
		setKits(state: Kit, entries: IKit[]): void {
			state.kits = entries;
			state.lastAssignedIds = state.lastAssignedIds.filter(id => entries.some(e => e.id === id));
		},
		setKit(state: Kit, kit: IKit): void {
			const index = state.kits.findIndex(k => k.id === kit.id);
			state.kits.splice(
				index < 0 ? state.kits.length + 1 : index,
				index < 0 ? 0 : 1,
				kit,
			);
		},
		removeKit(state: Kit, id: number): void {
			state.kits = state.kits.filter(k => k.id !== id);
		},
		setCardView(state: Kit, cardView: boolean): void {
			state.cardView = cardView;
		},
		setFavourites(state: Kit, favouriteIds: number[]): void {
			state.favourites = favouriteIds;
		},
		clearUserData(state: Kit): void {
			state.favourites = [] as number[];
			state.loadedFavourites = false;
		},
		setLatestChange(state: Kit, latestChange: number | null): void {
			state.latestChange = latestChange;
		},
		setLastAssignedKit(state: Kit, id: number): void {
			state.lastAssignedIds = [id, ...state.lastAssignedIds.slice(0, 4)];
		}
	},
	actions: {
		async loadLatestChange(context) {
			const latestChange = await Vue.$kitService.latestChange();
			context.commit('setLatestChange', latestChange);
			return latestChange;
		},
		async loadKits(context, force: boolean = false) {
			if (context.state.loading) {
				console.log('loadKits: already loading -> skip');
				return;
			}
			if (!force && context.state.loaded) {
				const latestChangeBefore = context.state.latestChange;
				const latestChangeCurrent = await context.dispatch('loadLatestChange');
				if (latestChangeBefore === latestChangeCurrent) {
					console.log('loadKits: latest change checked and same as local -> skip');
					return;
				} else {
					console.log('loadKits: latest change checked and NOT same as local!', latestChangeBefore, latestChangeCurrent);
				}
			}
			console.log(`loadKits: loading (${force ? 'forced' : 'unforced'})`);

			const options = new AsiListTableOptions();
			options.page = 1;
			options.itemsPerPage = 0;

			context.commit('setLoading', true);
			try {
				context.commit('setKits', (await Vue.$kitService.kits(options)).data);
			} catch (e) {
				context.commit('setKits', []);
				console.error('error while loading kits', e);
			} finally {
				context.commit('setLoading', false);
				context.commit('setLoaded', true);
			}
		},
		async loadKit(context, id: number) {
			context.commit('setKitLoading', {id: id, isLoading: true});
			try {
				const kit = await Vue.$kitService.kit(id);
				context.commit('setKit', kit);
			} catch (e) {
				console.error(`error while loading kit ${id}`, e);
			} finally {
				context.commit('setKitLoading', {id: id, isLoading: false});
			}
		},
		async loadFavourites(context, force: boolean = false) {
			if (context.state.loadingFavourites) {
				console.log('loadFavourites Kit: already loading -> skip');
				return;
			}
			if (context.state.loadedFavourites && !force) {
				console.log('loadFavourites Kit: already loaded -> skip');
				return;
			}

			context.commit('setLoadingFavourites', true);
			try {
				context.commit('setFavourites', await Vue.$kitService.favourites());
			} catch (e) {
				context.commit('setFavourites', []);
				console.error(`error while loading favourite kits:`, e);
			} finally {
				context.commit('setLoadingFavourites', false);
				context.commit('setLoadedFavourites', true);
			}
		},
		async setFavourite(context, payload: { id: number, isFavourite: boolean }) {
			const newFavourite = payload.isFavourite;
			const isFavourite = context.state.favourites.includes(payload.id);
			if (newFavourite === isFavourite) return;

			try {
				await Vue.$kitService.setFavourite(payload.id, newFavourite);
				if (newFavourite) {
					context.commit('setFavourites', context.state.favourites.concat([payload.id]));
				} else {
					context.commit('setFavourites', context.state.favourites.filter(id => id !== payload.id));
				}
			} catch (err) {
				console.error('error while setting kit favourite', payload);
			}
		},
		async create(context, payload: { label: string, userId: number | null }) {
			try {
				const model = await Vue.$kitService.create(payload.label, payload.userId);
				await context.dispatch('loadKit', model.id);
				return Promise.resolve(model);
			} catch (e) {
				console.error(`error while creating kit ${payload.label}`, e);
			}
		},
		async delete(context, id: number) {
			try {
				await Vue.$kitService.delete(id);
				context.commit('removeKit', id);
			} catch (e) {
				console.error(`error while deleting kit ${id}`, e);
			}
		},
		async rename(context, payload: { id: number, label: string }) {
			context.commit('setKitLoading', {id: payload.id, isLoading: true});
			try {
				await Vue.$kitService.rename(payload.id, payload.label);
				await context.dispatch('loadKit', payload.id);
			} catch (e) {
				console.error(`error while renaming kit ${payload.id}`, e);
			} finally {
				context.commit('setKitLoading', {id: payload.id, isLoading: false});
			}
		},
		async setSkins(context, payload: { id: number, skinIds: number[] }) {
			context.commit('setKitLoading', {id: payload.id, isLoading: true});
			try {
				await Vue.$kitService.setSkins(payload.id, payload.skinIds);
				context.commit('setLastAssignedKit', payload.id);
				await context.dispatch('loadKit', payload.id);
			} catch (e) {
				console.error(`error while setting skins of kit ${payload.id}`, e);
			} finally {
				context.commit('setKitLoading', {id: payload.id, isLoading: false});
			}
		},
		async setCoverSkin(context, payload: { id: number, coverSkinId: number | null }) {
			context.commit('setKitLoading', {id: payload.id, isLoading: true});
			try {
				await Vue.$kitService.setCoverSkin(payload.id, payload.coverSkinId);
				await context.dispatch('loadKit', payload.id);
			} catch (e) {
				console.error(`error while setting cover skin of kit ${payload.id}`, e);
			} finally {
				context.commit('setKitLoading', {id: payload.id, isLoading: false});
			}
		},
	},

};

export default kit;
