
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import {IKit} from "@/models/IKit";
	import {ISkin} from "@/models/ISkin";
	import SkinList from "@/components/skin/SkinList.vue";

	@Component({
		components: {SkinList}
	})
	export default class KitSkinsDialog extends Vue {

		@Prop({type: Object, required: true})
		public kit!: IKit;

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		private loading: boolean = false;
		private skinIds: number[] = [];

		private get options(): { text: string, value: number } {
			return this.$store.state.skin.skins
				.sort((a: ISkin, b: ISkin) => {
					return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
				})
				.map((skin: ISkin) => {
					return {text: skin.label, value: skin.id};
				});
		}

		private reset(): void {
			this.skinIds = this.kit.skin_ids;
		}

		private trySave(): void {
			this.loading = true;
			this.$store.dispatch('kit/setSkins', {id: this.kit.id, skinIds: this.skinIds})
				.then(() => this.save(this.skinIds))
				.finally(() => this.loading = false);
		}

		@Watch('kit', {deep: true, immediate: true})
		private onKitChanged(): void {
			this.reset();
		}

		@Watch('open')
		private onOpenChanged(value: boolean): void {
			if (!value) this.reset();
		}

		@Emit('cancel')
		public cancel(): void {
			//do nothing
		}

		@Emit('save')
		public save(skinIds: number[]): number[] {
			return skinIds;
		}

	}
