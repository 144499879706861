
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import {IItem} from "@/models/IItem";
	import IItemData from "@/models/IItemData";
	import ItemAutocomplete from "@/components/item/ItemAutocomplete.vue";
	import ItemAvatar from "@/components/item/ItemAvatar.vue";

	@Component({
		components: {ItemAvatar, ItemAutocomplete}
	})
	export default class CraftOrderCreateCard extends Vue {

		@Prop({type: Number, default: null})
		public itemId!: number | null;

		@Prop({type: Number, default: 1})
		public amount!: number;

		@Prop({type: Boolean, default: false})
		public tooManyCraftOrders!: boolean;

		@Prop({type: String, default: undefined})
		public minWidth!: string;

		private loading: boolean = false;
		private itemIdInternal: number | null = null;
		private amountInternal: number = 1;
		private selectedItem: IItem | null = null;
		private selectedItemData: IItemData | null = null;
		private numberFormatter: Intl.NumberFormat = new Intl.NumberFormat('de-CH');

		private get amountForm(): string {
			return this.amountInternal.toString();
		}

		private set amountForm(value: string) {
			const num = Number.parseInt(value);
			this.amountInternal = Number.isNaN(num) ? 1 : num;
		}

		private get canCreateOrder(): boolean {
			return !this.loading && this.itemIdInternal !== null && this.amountInternal > 0;
		}

		private get canCraftItem(): boolean {
			return this.selectedItemData !== null && this.selectedItemData.ingredients.length > 0;
		}

		private get resultingAmount(): number {
			return this.amountInternal * (this.selectedItemData?.craft_batch_size ?? 1);
		}

		@Watch('itemId', {immediate: true})
		private onItemIdChanged(value: number | null): void {
			if (this.itemIdInternal === value) return;
			this.itemIdInternal = value;
		}

		@Watch('amount')
		private onAmountChanged(value: number): void {
			if (this.amountInternal === value) return;
			this.amountInternal = value;
		}

		@Watch('itemIdInternal')
		private onItemIdInternalChanged(value: number | null): void {
			this.loadSelectedItemModels();
			this.amountInternal = 1;
			if (this.itemId !== value) return;
			this.$emit('update:itemId', this.itemIdInternal);
		}

		@Watch('amountInternal')
		private onAmountInternalChanged(value: number): void {
			if (this.amount === value) return;
			this.$emit('update:amount', this.amountInternal);
		}

		private requestCreateOrder(): void {
			if (!this.canCreateOrder) return;
			this.createOrder(this.itemIdInternal!, this.amountInternal);
		}

		@Emit('createOrder')
		public createOrder(itemId: number, amount: number): { itemId: number, amount: number } {
			this.reset();
			return {itemId: itemId, amount: amount};
		}

		private loadSelectedItemModels(): void {
			this.selectedItem = null;
			this.selectedItemData = null;
			if (this.itemIdInternal === null) return;

			this.selectedItem = this.$store.getters['item/itemById'](this.itemIdInternal);
			this.loading = true;
			this.$store.dispatch('item/loadItemData', this.itemIdInternal)
				.then(data => this.selectedItemData = data)
				.catch(err => console.error(err))
				.finally(() => this.loading = false);
		}

		private reset(): void {
			this.itemIdInternal = this.itemId;
			this.amountInternal = this.amount;
			this.selectedItem = null;
			this.selectedItemData = null;
		}

	}
