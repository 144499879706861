
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import IPropertyBlockEntry from "@/models/IPropertyBlockEntry";

	@Component
	export default class PropertyBlockEntry extends Vue {

		@Prop({type: Object, required: true})
		public entry!: IPropertyBlockEntry;

		private get valueFinal(): string[] | null {
			if (this.entry.value === null) return null;

			return Array.isArray(this.entry.value)
				? this.entry.value
				: [this.entry.value];
		}

		private get hasSlotEntryIcon(): boolean {
			return !!this.$slots.entryIcon || !!this.$scopedSlots['entryIcon'];
		}

	}
