
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';

	@Component
	export default class Logo extends Vue {

		@Prop({type: String, default: 'black'})
		public color!: string;

	}
