
	import Vue from 'vue';
	import {Component, Prop, Watch} from 'vue-property-decorator';
	import AsiListTableHeader from "@/components/common/AsiListTableHeader";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import {Routes} from "@/helpers/Routes";
	import ArrayHelper, {MultiSortHandler} from "@/helpers/ArrayHelper";
	import ReloadButton from "@/components/common/ReloadButton.vue";
	import ListHelper from "@/helpers/ListHelper";
	import ItemListPreference from "@/models/list-preference/ItemListPreference";
	import FilterControl from "@/components/common/FilterControl.vue";
	import SortControl from "@/components/common/SortControl.vue";
	import ItemGroupAutocomplete from "@/components/item-group/ItemGroupAutocomplete.vue";
	import ItemGroupListFilter from "@/models/filter/ItemGroupListFilter";
	import {IItemGroup} from "@/models/IItemGroup";
	import ItemGroupListPreference from "@/models/list-preference/ItemGroupListPreference";
	import ItemGroupCard from "@/components/item-group/ItemGroupCard.vue";
	import ItemGroupAvatar from "@/components/item-group/ItemGroupAvatar.vue";

	@Component({
		components: {
			ItemGroupAvatar,
			ItemGroupCard,
			ItemGroupAutocomplete, SortControl, FilterControl, ReloadButton
		}
	})
	export default class ItemGroupList extends Vue {

		@Prop({type: String, default: null})
		public memoryKey!: string | null;

		private readonly defaultSortBy: string[] = ['label'];
		private readonly defaultSortDesc: boolean[] = [false];

		private options: AsiListTableOptions = new AsiListTableOptions();
		private filter: ItemGroupListFilter = new ItemGroupListFilter();
		private preference: ItemListPreference = new ItemListPreference();

		private filterText: string | null = null;
		private filterTextTimeout: any = null;

		public created(): void {
			this.options.sortBy = this.defaultSortBy;
			this.options.sortDesc = this.defaultSortDesc;

			if (this.memoryKey !== null) {
				const options = this.$store.getters['ui/getListOptions'](this.memoryKey);
				if (options !== null) this.options = options;

				const filter = this.$store.getters['ui/getListFilter'](this.memoryKey);
				if (filter !== null) {
					Object.setPrototypeOf(filter, new ItemGroupListFilter());
					this.$store.commit('ui/setListFilter', {key: this.memoryKey, filter: filter});
					this.filter = filter;
					this.filterText = filter.text;
				}

				const preference = this.$store.getters['ui/getListPreference'](this.memoryKey);
				if (preference !== null) this.preference = preference;
			}

			this.loadItemGroups();
		}

		private get itemsPerPageOptions(): number[] {
			return ListHelper.itemsPerPageOptions;
		}

		private get itemGroups(): IItemGroup[] {
			return this.filter.applyFilter(this.$store.state.itemGroup.itemGroups);
		}

		private get headers(): AsiListTableHeader[] {
			return [
				new AsiListTableHeader('', 'avatar', false, false, 'center', '5rem'),
				new AsiListTableHeader('Label', 'label', true, true, 'start'),
			];
		}

		@Watch('options', {deep: true})
		private onOptionsChanged(value: AsiListTableOptions): void {
			if (this.memoryKey !== null) {
				this.$store.commit('ui/setListOptions', {key: this.memoryKey, options: value});
			}
		}

		@Watch('filter', {deep: true})
		private onFilterChanged(value: ItemGroupListFilter): void {
			if (this.memoryKey !== null) {
				this.$store.commit('ui/setListFilter', {key: this.memoryKey, filter: value});
			}
			if (this.filterText !== value.text) {
				this.filterText = value.text;
			}
		}

		@Watch('filterText')
		private onFilterTextChanged(value: string | null): void {
			clearTimeout(this.filterTextTimeout);
			this.filterTextTimeout = setTimeout(() => {
				this.filter.text = value;
				console.log('changing after debouncing');
			}, value !== null && value.length > 0 ? 500 : 25);
		}

		@Watch('preference', {deep: true})
		private onPreferenceChanged(value: ItemGroupListPreference): void {
			if (this.memoryKey !== null) {
				this.$store.commit('ui/setListPreference', {key: this.memoryKey, preference: value});
			}
		}

		private loadItemGroups(force: boolean = false): void {
			this.$store.dispatch('itemGroup/loadItemGroups', force);
		}

		private sortEntries(entries: IItemGroup[], sortBy: string[], sortDesc: boolean[]): IItemGroup[] {
			return ArrayHelper.multiSort<IItemGroup>(entries, sortBy, sortDesc, new Map<string, MultiSortHandler<IItemGroup>>([
				['label', (a, b, column, desc) => {
					return a.label.toLowerCase().localeCompare(b.label.toLowerCase()) * (desc ? -1 : 1);
				}],
			]));
		}

		private showItemGroup(id: number): void {
			const itemGroup = this.$store.getters['itemGroup/itemGroupById'](id);
			if (itemGroup === null) return;
			this.$router.push({name: Routes.itemGroup, params: {id: itemGroup.id.toString()}});
		}

	}
