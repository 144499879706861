
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {ISkin} from "@/models/ISkin";
	import {Routes} from "@/helpers/Routes";
	import SkinHelper from "@/helpers/SkinHelper";

	@Component
	export default class SkinChip extends Vue {

		@Prop({type: [String, Number], required: true})
		public id!: string | number;

		@Prop({type: String, default: null})
		public color!: string;

		private get skin(): ISkin | null {
			return this.$store.getters['skin/skinById'](this.id);
		}

		private get iconUrl(): string | null {
			return this.skin === null ? null : SkinHelper.iconUrl(this.skin);
		}

		private showSkin(): void {
			this.$router.push({name: Routes.skin, params: {id: this.id.toString()}});
		}

	}
