
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import SkinPricingEntriesMultiFetchDialog from "@/components/skin/SkinPricingEntriesMultiFetchDialog.vue";
	import DialogHandler from "@/components/common/DialogHandler";

	@Component({
		components: {SkinPricingEntriesMultiFetchDialog}
	})
	export default class SkinPricingEntriesMultiFetchButton extends Vue {

		@Prop({type: Array, required: true})
		public skinIds!: number[];

		@Prop({type: String, default: 'grey'})
		public color!: string;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		private dialog: DialogHandler = new DialogHandler();

	}
