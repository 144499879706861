
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import ItemGroupList from "@/components/item-group/ItemGroupList.vue";

	@Component({
		components: {ItemGroupList}
	})
	export default class ItemGroupsView extends Vue {

	}
