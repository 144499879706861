
	import Vue from 'vue';
	import {Component, Prop, Watch} from 'vue-property-decorator';
	import ISkinWorkshopData from "@/models/ISkinWorkshopData";
	import SkinHelper from "@/helpers/SkinHelper";
	import DialogHandler from "@/components/common/DialogHandler";

	@Component
	export default class SkinWorkshopGallery extends Vue {

		@Prop({type: Object, required: true})
		public workshopData!: ISkinWorkshopData;

		@Prop({type: Boolean, default: false})
		public showImages!: boolean;

		private showImagesInternal: boolean = false;
		private thumbLoadedIndexes: number[] = [];
		private detailImageIndex: number = -1;
		private detailDialog: DialogHandler = new DialogHandler(() => this.detailImageIndex = -1);
		private detailLoadedIndexes: number[] = [];

		private get thumbImageUrls(): string[] {
			return SkinHelper.workshopImagesThumbs(this.workshopData);
		}

		private get currentDetailUrl(): string | null {
			return this.detailImageIndex < 0 ? null : (this.detailImageUrls[this.detailImageIndex] ?? null);
		}

		private get detailImageUrls(): string[] {
			return SkinHelper.workshopImagesFull(this.workshopData);
		}

		private get canPrev(): boolean {
			return this.detailImageIndex !== -1 && this.detailImageIndex > 0;
		}

		private get canNext(): boolean {
			return this.detailImageIndex !== -1 && this.detailImageIndex < this.detailImageUrls.length - 1;
		}

		@Watch('showImages', {immediate: true})
		private onShowImagesChanged(value: boolean): void {
			if (this.showImagesInternal !== value) this.showImagesInternal = value;
		}

		@Watch('showImagesInternal')
		private onShowImagesInternalChanged(value: boolean): void {
			if (this.showImages !== value) this.$emit('update:showImages', value);
		}

		private openDetailDialog(index: number): void {
			this.detailImageIndex = index;
			this.detailDialog.open();
		}

		private closeDetailDialog(): void {
			this.detailImageIndex = -1;
			this.detailDialog.close();
		}

		private prev(): void {
			if (this.canPrev) this.detailImageIndex--;
		}

		private next(): void {
			if (this.canNext) this.detailImageIndex++;
		}

	}
