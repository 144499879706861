import store from "@/store";
import SkinHelper from "@/helpers/SkinHelper";
import {IKit} from "@/models/IKit";
import {ISkin} from "@/models/ISkin";
import IOption from "@/models/IOption";

export default class KitHelper {

	public static readonly ownedOptions: IOption<number|null>[] = [
		{text: '', value: null},
		{text: 'Fully', value: 1},
		{text: 'Partially', value: 2},
		{text: 'None', value: 3}
	]

	public static iconUrl(kit: IKit): string | null {
		const skin =
			kit.cover_skin_id !== null
				? store.getters['skin/skinById'](kit.cover_skin_id)
				: store.getters['skin/skinsByIds'](kit.skin_ids)
				.filter((skin: ISkin) => skin.has_icon)
				.sort((a: ISkin, b: ISkin) => {
					if (a.price !== null && b.price === null) return -1;
					if (a.price === null && b.price !== null) return 1;
					if (a.price === null && b.price === null) return 0;
					if (a.price !== null && b.price !== null) return b.price - a.price;
					return 0;
				})[0] ?? null;

		return skin === null ? null : SkinHelper.iconUrl(skin);
	}

	public static iconUrlHd(kit: IKit): string | null {
		const skin =
			kit.cover_skin_id !== null
				? store.getters['skin/skinById'](kit.cover_skin_id)
				: store.getters['skin/skinsByIds'](kit.skin_ids)
				.filter((skin: ISkin) => skin.has_icon)
				.sort((a: ISkin, b: ISkin) => {
					if (a.price !== null && b.price === null) return -1;
					if (a.price === null && b.price !== null) return 1;
					if (a.price === null && b.price === null) return 0;
					if (a.price !== null && b.price !== null) return b.price - a.price;
					return 0;
				})[0] ?? null;

		return skin === null ? null : SkinHelper.iconUrlHd(skin);
	}

}
