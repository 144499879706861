export default class WordFilter {

	public static matches(searchString: string, haystack: string, allWords: boolean = true): boolean {
		haystack = haystack.toLowerCase();
		const words = searchString
			.trim()
			.toLowerCase()
			.split(/\s+/)
			.map(w => w.trim())
			.filter(w => w.length > 0) ?? [];

		if (words.length === 0) {
			return true;
		}

		if (allWords) {
			return !words.some(w => haystack.indexOf(w) < 0);
		} else {
			return words.some(w => haystack.indexOf(w) > -1);
		}
	}

}
