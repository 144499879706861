
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import {IItem} from "@/models/IItem";
	import ICraftCalculatorLevel from "@/models/ICraftCalculatorLevel";
	import CraftTreeLevel from "@/components/craft-calculator/CraftTreeLevel.vue";
	import CraftIngredients from "@/components/craft-calculator/CraftIngredients.vue";
	import IItemData from "@/models/IItemData";

	@Component({
		components: {CraftIngredients, CraftTreeLevel}
	})
	export default class CraftOrder extends Vue {

		@Prop({type: Number, required: true})
		public itemId!: number;

		@Prop({type: Number, required: true})
		public amount!: number;

		private numberFormatter: Intl.NumberFormat = new Intl.NumberFormat('de-CH');
		private loading: boolean = false;
		private amountInternalValue: number = 1;
		private topLevel: ICraftCalculatorLevel | null = null;

		public created(): void {
			this.calculate();
		}

		private get item(): IItem {
			return this.$store.getters['item/itemById'](this.itemId);
		}

		private get amountFormatted(): string {
			return this.topLevel === null
				? '?'
				: (new Intl.NumberFormat('de-CH')).format(this.amount * (this.topLevel.itemData.craft_batch_size ?? 1));
		}

		private get notCraftedItemIds(): number[] {
			return this.$store.state.craftCalculator.notCraftedItemIds;
		}

		private get amountInternal(): string {
			return this.amountInternalValue.toString();
		}

		private set amountInternal(value: string) {
			const num = Number.parseInt(value);
			this.amountInternalValue = Number.isNaN(num) ? 1 : num;
		}

		@Watch('itemId')
		private onItemIdChanged(value: number, oldValue: number): void {
			if (value === oldValue) return;
			this.topLevel = null;
		}

		@Watch('amount', {immediate: true})
		private onAmountChanged(value: number, oldValue: number): void {
			if (value === oldValue) return;
			this.amountInternal = value.toString();
			if (this.topLevel !== null) this.calculate();
		}

		@Watch('topLevel', {deep: true})
		private onTopLevelChanged(value: ICraftCalculatorLevel | null): ICraftCalculatorLevel | null {
			this.topLevelChanged(value);
			return value;
		}

		@Emit('topLevelChanged')
		public topLevelChanged(level: ICraftCalculatorLevel | null): ICraftCalculatorLevel | null {
			return level;
		}

		@Emit('delete')
		public requestDelete(): number {
			return this.itemId;
		}

		private amountInternalChanged(): void {
			if (this.amount === this.amountInternalValue) return;
			this.$emit('update:amount', this.amountInternalValue);
			this.calculate();
		}

		private calculate(): void {
			let amount = this.amountInternalValue;
			if (this.itemId === null) return;

			this.loading = true;
			this.fetchCraftTreeRecursive(this.itemId, amount)
				.then(level => this.topLevel = level)
				.catch(err => console.error('Error while fetching craft tree', err))
				.finally(() => this.loading = false);
		}

		private async fetchCraftTreeRecursive(itemId: number, amount: number, depth: number = 0, itemData: IItemData | null = null): Promise<ICraftCalculatorLevel> {
			let item = this.$store.getters['item/itemById'](itemId);
			if (item === null) return Promise.reject(`Item with id ${itemId} not found`);
			if (itemData === null) {
				itemData = await this.$store.dispatch('item/loadItemData', itemId) as IItemData;
			}
			if (depth === 0) {
				amount = amount * (itemData.craft_batch_size ?? 1);
			}

			const childLevels: ICraftCalculatorLevel[] = [];
			const ingredientQuotient = amount / (itemData.craft_batch_size ?? 1);

			for (let i = 0; i < itemData.ingredients.length; i++) {
				const ingredient = itemData.ingredients[i];
				const ingredientData = await this.$store.dispatch('item/loadItemData', ingredient.item_id) as IItemData;
				const ingredientAmount = ingredient.amount * ingredientQuotient;

				const level = await this.fetchCraftTreeRecursive(ingredient.item_id, ingredientAmount, depth + 1, ingredientData)
					.catch(err => Promise.reject(err));
				childLevels.push(level);
			}

			return Promise.resolve({
				item: item,
				itemData: itemData,
				amount: amount,
				depth: depth,
				childLevels: childLevels,
			} as ICraftCalculatorLevel);
		}

	}
