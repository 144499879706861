
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import SkinAvatar from "@/components/skin/SkinAvatar.vue";
	import {ISkin} from "@/models/ISkin";

	@Component({
		components: {SkinAvatar}
	})
	export default class SkinAutocomplete extends Vue {

		@Prop({type: Number, default: null})
		public value!: number | null;

		@Prop({type: Array, default: null})
		public skins!: ISkin[] | null;

		@Prop({type: Boolean, default: false})
		public filled!: boolean;

		@Prop({type: Boolean, default: false})
		public clearable!: boolean;

		private valueInternal: number | null = null;

		private get selectedSkin(): ISkin | null {
			return this.valueInternal === null ? null : this.$store.getters['skin/skinById'](this.valueInternal);
		}

		private get options(): ISkin[] {
			return this.skins ?? this.$store.state.skin.skins;
		}

		@Watch('value', {immediate: true})
		private onValueChanged(value: number | null): void {
			this.valueInternal = value;
		}

		@Watch('valueInternal')
		@Emit('input')
		public input(): number | null {
			return this.valueInternal;
		}

	}
