
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import {IKit} from "@/models/IKit";
	import {ISkin} from "@/models/ISkin";
	import KitList from "@/components/kit/KitList.vue";
	import KitChip from "@/components/kit/KitChip.vue";

	@Component({
		components: {KitChip, KitList}
	})
	export default class SkinKitsDialog extends Vue {

		@Prop({type: Object, required: true})
		public skin!: ISkin;

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		private loading: boolean = false;
		private kitIds: number[] = [];
		private kitIdsInternal: number[] = [];

		private get options(): { text: string, value: number } {
			return this.$store.state.kit.kits
				.sort((a: IKit, b: IKit) => {
					return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
				})
				.map((kit: IKit) => {
					return {text: kit.label, value: kit.id};
				});
		}

		private reset(): void {
			const ids = this.$store.getters['kit/kitsBySkinId'](this.skin.id).map((k: IKit) => k.id);
			this.kitIds = ids;
			this.kitIdsInternal = ids;
		}

		private trySave(): void {
			const removedIds = this.kitIds.filter(id => !this.kitIdsInternal.includes(id));
			const newIds = this.kitIdsInternal.filter(id => !this.kitIds.includes(id));

			if (removedIds.length === 0 && newIds.length === 0) {
				return;
			}

			const promises: Promise<null>[] = [];
			this.loading = true;

			for (let i = 0; i < removedIds.length; i++) {
				const kit = this.$store.getters['kit/kitById'](removedIds[i]);
				let skinIds = kit.skin_ids;
				if (skinIds.includes(this.skin.id)) {
					skinIds = skinIds.filter((skinId: number) => skinId !== this.skin.id);
					promises.push(this.$store.dispatch('kit/setSkins', {id: kit.id, skinIds: skinIds}));
				}
			}
			for (let i = 0; i < newIds.length; i++) {
				const kit = this.$store.getters['kit/kitById'](newIds[i]);
				const skinIds = kit.skin_ids;
				if (!skinIds.includes(this.skin.id)) {
					skinIds.push(this.skin.id);
					promises.push(this.$store.dispatch('kit/setSkins', {id: kit.id, skinIds: skinIds}));
				}
			}

			Promise.all(promises)
				.then(() => this.save(this.kitIdsInternal))
				.finally(() => this.loading = false);
		}

		@Watch('skin', {deep: true, immediate: true})
		private onSkinChanged(): void {
			this.reset();
		}

		@Watch('open')
		private onOpenChanged(value: boolean): void {
			if (!value) this.reset();
		}

		@Emit('cancel')
		public cancel(): void {
			//do nothing
		}

		@Emit('save')
		public save(kitIds: number[]): number[] {
			return kitIds;
		}

	}
