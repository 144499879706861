
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import {IInventoryEntry} from "@/models/IInventoryEntry";
	import {Routes} from "@/helpers/Routes";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import AsiListTableHeader from "@/components/common/AsiListTableHeader";
	import ListHelper from "@/helpers/ListHelper";
	import SkinFavouriteButton from "@/components/skin/SkinFavouriteButton.vue";
	import {ISkin} from "@/models/ISkin";
	import SkinAvatar from "@/components/skin/SkinAvatar.vue";
	import SkinChipLinkRustlabs from "@/components/skin/SkinChipLinkRustlabs.vue";
	import SkinChipLinkMarket from "@/components/skin/SkinChipLinkMarket.vue";
	import SkinChipLinkWorkshop from "@/components/skin/SkinChipLinkWorkshop.vue";
	import SkinAmountChip from "@/components/skin/SkinAmountChip.vue";
	import DateHelper from "@/helpers/DateHelper";
	import ArrayHelper, {MultiSortHandler} from "@/helpers/ArrayHelper";

	@Component({
		components: {
			SkinAmountChip,
			SkinChipLinkWorkshop, SkinChipLinkMarket, SkinChipLinkRustlabs, SkinAvatar, SkinFavouriteButton
		}
	})
	export default class InventoryList extends Vue {

		private readonly defaultSortBy: string[] = ['created_at', 'updated_at'];
		private readonly defaultSortDesc: boolean[] = [true, true];

		private options: AsiListTableOptions = new AsiListTableOptions();

		public created(): void {
			this.options.sortBy = this.defaultSortBy;
			this.options.sortDesc = this.defaultSortDesc;
		}

		private get entries(): IInventoryEntry[] {
			return this.$store.state.inventory.inventoryEntries;
		}

		private get headers(): AsiListTableHeader[] {
			return [
				new AsiListTableHeader('', 'favourite', true, true, 'center', '5rem'),
				new AsiListTableHeader('', 'avatar', false, false, 'center', '5rem'),
				new AsiListTableHeader('Label', 'label', true, true, 'start'),
				new AsiListTableHeader('Owned', 'amount', true, true, 'center', '7rem'),
				new AsiListTableHeader('Created', 'created_at', true, true, 'center', '10%'),
				new AsiListTableHeader('Updated', 'updated_at', true, true, 'center', '10%'),
			];
		}

		private get itemsPerPageOptions(): number[] {
			return ListHelper.itemsPerPageOptions;
		}

		private skinById(skinId: number): ISkin | null {
			return this.$store.getters['skin/skinById'](skinId);
		}

		private showSkin(skinId: number): void {
			const skin = this.skinById(skinId);
			if (skin === null) return;
			this.$router.push({name: Routes.skin, params: {id: skin.id.toString()}});
		}

		private formatDateTime(unixTimestamp: number | null): string | null {
			if (unixTimestamp === null) return null;
			return DateHelper.formatDateTime(unixTimestamp, true);
		}

		private sortEntries(entries: IInventoryEntry[], sortBy: string[], sortDesc: boolean[]): IInventoryEntry[] {
			return ArrayHelper.multiSort<IInventoryEntry>(entries, sortBy, sortDesc, new Map<string, MultiSortHandler<IInventoryEntry>>([
				['label', (a, b, column, desc) => {
					const skinA = this.$store.getters['skin/skinById'](a.skin_id);
					const skinB = this.$store.getters['skin/skinById'](b.skin_id);
					return skinA.label.toLowerCase().localeCompare(skinB.label.toLowerCase()) * (desc ? -1 : 1);
				}],
				['favourite', (a, b, column, desc) => {
					return (this.$store.getters['skin/isFavourite'](b.skin_id) - this.$store.getters['skin/isFavourite'](a.skin_id)) * (desc ? -1 : 1);
				}],
			]));
		}

	}
