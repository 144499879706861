
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {ISkin} from "@/models/ISkin";

	@Component
	export default class SkinTradeableIcon extends Vue {

		@Prop({type: Object, required: true})
		public skin!: ISkin;

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public xSmall!: boolean;

		private get icon(): string {
			return this.skin.tradeable === null ? 'mdi-help' : 'mdi-cart';
		}

		private get color(): string {
			switch (this.skin.tradeable) {
				case true:
					return 'success';
				case false:
					return 'error';
				case null:
				default:
					return 'grey';

			}
		}

	}
