import IListPreference from "@/models/list-preference/IListPreference";

export default class KitListPreference implements IListPreference {

	public static readonly defaultImgSize: number = 38;
	public static readonly defaultCardSize: number = 13;

	public cardView: boolean = false;
	public imgSize: number = KitListPreference.defaultImgSize;
	public cardSize: number = KitListPreference.defaultCardSize;

	public reset(): void {
		this.cardView = false;
		this.imgSize = KitListPreference.defaultImgSize;
		this.cardSize = KitListPreference.defaultCardSize;
	}

}
