
	import Vue from 'vue';
	import {Component, Prop, Watch} from 'vue-property-decorator';
	import {IItem} from "@/models/IItem";
	import {ISkin} from "@/models/ISkin";
	import ItemGroupChip from "@/components/item-group/ItemGroupChip.vue";
	import SkinList from "@/components/skin/SkinList.vue";
	import SkinHelper from "@/helpers/SkinHelper";
	import ItemHelper from "@/helpers/ItemHelper";
	import DetailHeader from "@/components/common/DetailHeader.vue";
	import RouterHelper from "@/helpers/RouterHelper";
	import ItemChipLinkRustlabs from "@/components/item/ItemChipLinkRustlabs.vue";
	import IItemData from "@/models/IItemData";
	import ItemData from "@/components/item/ItemData.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import ItemSetStackSizeDialog from "@/components/item/ItemSetStackSizeDialog.vue";

	@Component({
		components: {ItemSetStackSizeDialog, ItemData, ItemChipLinkRustlabs, DetailHeader, SkinList, ItemGroupChip}
	})
	export default class ItemView extends Vue {

		@Prop({type: String, required: true})
		public id!: string;

		private itemData: IItemData | null = null;
		private setStackSizeDialog: DialogHandler = new DialogHandler();

		private get isAdmin(): boolean {
			return this.$store.getters['user/isAdmin']();
		}

		private get idNumeric(): number | null {
			const id = Number.parseInt(this.id);
			return Number.isNaN(id) ? null : id;
		}

		private get item(): IItem | null {
			return this.idNumeric === null ? null : this.$store.getters['item/itemById'](this.idNumeric);
		}

		private get iconUrl(): string | null {
			return this.item === null || !this.$store.state.ui.showAvatars
				? null
				: ItemHelper.iconUrl(this.item);
		}

		private get hasSkins(): boolean {
			return this.idNumeric === null ? false : this.$store.state.skin.skins.some((s: ISkin) => s.item_id === this.idNumeric);
		}

		private get activeTab(): number {
			return this.$store.state.item.activeTab;
		}

		private set activeTab(activeTab: number) {
			this.$store.commit('item/setActiveTab', activeTab);
		}

		private skinIconUrl(skin: ISkin): string | null {
			return SkinHelper.iconUrl(skin);
		}

		@Watch('item.label', {immediate: true})
		private onItemLabelChanged(value: string | null): void {
			RouterHelper.updateTitle(value);
		}

		private formatNumber(value: number): string {
			return (new Intl.NumberFormat('de-CH')).format(value);
		}

	}
