
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {Routes} from "@/helpers/Routes";
	import EntityCard from "@/components/common/EntityCard.vue";
	import {IItemGroup} from "@/models/IItemGroup";
	import ItemGroupHelper from "@/helpers/ItemGroupHelper";

	@Component({
		components: {EntityCard}
	})
	export default class ItemCard extends Vue {

		@Prop({type: Object, required: true})
		public itemGroup!: IItemGroup;

		private get iconUrl(): string | null {
			return ItemGroupHelper.iconUrl(this.itemGroup);
		}

		private showItemGroup(): void {
			this.$router.push({name: Routes.itemGroup, params: {id: this.itemGroup.id.toString()}});
		}

	}
