
	import Vue from 'vue';
	import {Component, Emit, Prop} from 'vue-property-decorator';
	import {ISkin} from "@/models/ISkin";
	import {IItem} from "@/models/IItem";
	import {IKit} from "@/models/IKit";
	import SkinAvatar from "@/components/skin/SkinAvatar.vue";
	import {Routes} from "@/helpers/Routes";
	import KitAvatar from "@/components/kit/KitAvatar.vue";
	import ItemAvatar from "@/components/item/ItemAvatar.vue";
	import WordFilter from "@/helpers/WordFilter";

	@Component({
		components: {ItemAvatar, KitAvatar, SkinAvatar}
	})
	export default class GlobalSearchDialog extends Vue {

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		private loading: boolean = false;
		private term: string | null = null;

		private get validSearch(): boolean {
			return this.term !== null && this.term.trim().length > 3;
		}

		private get termFinal(): string {
			return this.term?.trim().toLowerCase() ?? '';
		}

		private get numResults(): number {
			return this.skins.length + this.kits.length + this.items.length;
		}

		private get skins(): ISkin[] {
			return this.validSearch
				? this.$store.state.skin.skins
					.filter((s: ISkin) => WordFilter.matches(this.termFinal, s.label))
					.sort((a: ISkin, b: ISkin) => a.label.toLowerCase().localeCompare(b.label))
				: [];
		}

		private get kits(): IKit[] {
			return this.validSearch
				? this.$store.state.kit.kits
					.filter((k: IKit) => WordFilter.matches(this.termFinal, k.label))
					.sort((a: IKit, b: IKit) => a.label.toLowerCase().localeCompare(b.label))
				: [];
		}

		private get items(): IItem[] {
			return this.validSearch
				? this.$store.state.item.items
					.filter((i: IItem) => WordFilter.matches(this.termFinal, i.label))
					.sort((a: IItem, b: IItem) => a.label.toLowerCase().localeCompare(b.label))
				: [];
		}

		@Emit('cancel')
		public cancel(): void {
			return;
		}

		private showSkin(skin: ISkin): void {
			this.cancel();
			this.$router.push({name: Routes.skin, params: {id: skin.id.toString()}});
		}

		private showKit(kit: IKit): void {
			this.cancel();
			this.$router.push({name: Routes.kit, params: {id: kit.id.toString()}});
		}

		private showItem(item: IItem): void {
			this.cancel();
			this.$router.push({name: Routes.item, params: {id: item.id.toString()}});
		}

	}
