
	import Vue from 'vue';
	import {Component, Emit, Prop} from 'vue-property-decorator';
	import {Routes} from "@/helpers/Routes";
	import KitHelper from "@/helpers/KitHelper";
	import {IKit} from "@/models/IKit";

	@Component
	export default class KitChip extends Vue {

		@Prop({type: [String, Number], required: true})
		public id!: string | number;

		@Prop({type: String, default: null})
		public color!: string;

		@Prop({type: Boolean, default: false})
		public close!: boolean;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		private get kit(): IKit | null {
			return this.$store.getters['kit/kitById'](this.id);
		}

		private get iconUrl(): string | null {
			return this.kit === null ? null : KitHelper.iconUrl(this.kit);
		}

		private showItem(): void {
			this.$router.push({name: Routes.kit, params: {id: this.id.toString()}});
		}

		@Emit('close')
		private requestClose(): void {
			//so nothing
		}

	}
