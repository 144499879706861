
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";

	@Component
	export default class ConfirmDialog extends Vue {

		@Prop({type: Boolean, default: false})
		public secure!: boolean;

		@Prop({type: String, default: null})
		public secureWord!: string;

		@Prop({type: String, default: 'success'})
		public yesButtonColor!: string;

		@Prop({type: String, default: 'error'})
		public noButtonColor!: string;

		@Prop({type: Boolean, default: false})
		public persistent!: boolean;

		private dialog = false;
		private resolve: ((res: boolean) => void) | null = null;
		private reject: (() => void) | null = null;

		private title: string | null = null;
		private message: string | null = null;

		private secureInput: string | null = null;

		private get secureWordFinal(): string {
			return this.secureWord ?? 'yes';
		}

		private get secureApproved(): boolean {
			return !this.secure || this.secureInput?.toLowerCase() === this.secureWordFinal.toLowerCase();
		}

		public openDialog(title: string | null = null, message: string | null = null): Promise<boolean> {
			this.dialog = true;
			this.title = title ?? 'Are you sure?';
			this.message = message ?? 'This action needs additional confirmation.';
			this.secureInput = null;

			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		}

		private agree(): void {
			if (!this.secureApproved) return;

			if (null !== this.resolve) {
				this.resolve(true);
			}
			this.dialog = false;
		}

		private cancel(): void {
			if (null !== this.resolve) {
				this.resolve(false);
			}
			this.dialog = false;
		}

	}
