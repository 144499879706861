
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import IOption from "@/models/IOption";
	import {IItemGroup} from "@/models/IItemGroup";

	@Component
	export default class ItemGroupAutocomplete extends Vue {

		@Prop({type: Number, default: null})
		public value!: number | null;

		private valueInternal: number | null = null;

		private get options(): IOption<number>[] {
			return this.$store.state.itemGroup.itemGroups
				.map((g: IItemGroup) => {
					return {
						text: g.label,
						value: g.id,
					};
				})
				.sort((a: IOption<number>, b: IOption<number>) => {
					return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
				});
		}

		@Watch('value', {immediate: true})
		private onValueChanged(value: number | null): void {
			this.valueInternal = value;
		}

		@Watch('valueInternal')
		@Emit('input')
		public input(event: Event): Event {
			return event;
		}

	}
