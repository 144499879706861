import AxiosService from "@/services/AxiosService";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import Vue from "vue";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IUserService from "@/services/definition/IUserService";
import {IUser} from "@/models/IUser";

export default class AxiosUserService extends AxiosService implements IUserService {

	protected defineBaseUrl(): string {
		return 'user';
	}

	public users(options: AsiListTableOptions): Promise<IPaginatedResponse<IUser>> {
		return Vue.$axios.get<IUser[]>(this.url('index'), {
			params: {
				...AxiosService.finalizeOptions(options)
			}
		})
			.then(res => {
				return {
					data: res.data,
					total: Number.parseInt(res.headers['x-pagination-total-count']),
				} as IPaginatedResponse<IUser>;
			});
	}

	public user(id: number): Promise<IUser> {
		return Vue.$axios.get<IUser>(this.url('view'), {
			params: {
				id: id,
			}
		}).then(res => res.data);
	}

	public create(username: string, steamId: string, password: string): Promise<IUser> {
		return Vue.$axios.post<IUser>(this.url('create'), {
			username: username,
			steam_id: steamId,
			password: password,
		}).then(res => res.data);
	}

	public update(id: number, username: string, steamId: number): Promise<null> {
		return Vue.$axios.patch<null>(this.url(`update`), {
			id: id,
			username: username,
			steam_id: steamId,
		}).then(res => res.data);
	}

	public delete(id: number): Promise<null> {
		return Vue.$axios.delete(this.url('delete'), {
			params: {
				id: id
			},
		}).then(null);
	}

	public activate(id: number): Promise<null> {
		return Vue.$axios.post<null>(this.url('activate'), {
			id: id,
		}).then(res => res.data);
	}

	public deactivate(id: number): Promise<null> {
		return Vue.$axios.post<null>(this.url('deactivate'), {
			id: id,
		}).then(res => res.data);
	}

	public updatePassword(id: number, password: string): Promise<null> {
		return Vue.$axios.patch<null>(this.url(`update-password`), {
			id: id,
			password: password,
		}).then(res => res.data);
	}

}
