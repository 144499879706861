import {Route} from "vue-router";
import Router from "@/router";

export default class RouterHelper {

	public static updateTitle(prefix: string | null = null, route: Route | null = null): void {
		const r = route ?? Router.currentRoute;
		const titleParts = ['PLM\'s Skin Manager'];
		if (r.meta?.title !== undefined) titleParts.unshift(r.meta.title);
		if (prefix !== null) titleParts.unshift(prefix);

		document.title = titleParts.join(' | ');
	}

}
