
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import SkinCard from "@/components/skin/SkinCard.vue";
	import SkinPricingEntriesMultiFetchButton from "@/components/skin/SkinPricingEntriesMultiFetchButton.vue";
	import {ISkin} from "@/models/ISkin";

	@Component({
		components: {SkinPricingEntriesMultiFetchButton, SkinCard}
	})
	export default class HomeFavouriteSkins extends Vue {

		@Prop({type: Number, required: true})
		public cardSize!: number;

		@Prop({type: Object, required: true})
		public cardContainerStyles!: object;

		@Prop({type: Boolean, required: true})
		public isAdmin!: boolean;

		private get favouriteSkins(): ISkin[] {
			return this.$store.getters['skin/favourites']()
				.sort((a: ISkin, b: ISkin) => {
					const resLabel = a.label.localeCompare(b.label);
					return resLabel === 0 ? (b.price ?? 0) - (a.price ?? 0) : resLabel;
				});
		}

		private reloadFavouriteSkins(): void {
			this.$store.dispatch('skin/loadFavourites', true);
		}

	}
