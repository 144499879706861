
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import {ISkin} from "@/models/ISkin";
	import ISkinIconUrls from "@/models/ISkinIconUrls";

	@Component
	export default class SkinSetIconUrlHdDialog extends Vue {

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		@Prop({type: Object, required: true})
		public skin!: ISkin;

		private loading: boolean = false;
		private iconUrls: ISkinIconUrls | null = null;
		private iconUrlHd: string | null = null;

		@Watch('skin.has_icon_hd')
		private onSkinHasIconHdChanged(): void {
			this.loadIconUrls();
		}

		@Watch('open', {immediate: true})
		private onOpenChanged(value: boolean): void {
			if (value) {
				this.loadIconUrls();
			} else {
				this.iconUrlHd = null;
				this.iconUrls = null;
			}
		}

		@Emit('cancel')
		public cancel(): void {
			//do nothing
		}

		@Emit('save')
		public save(url: string | null): string | null {
			return url;
		}

		private loadIconUrls(): void {
			this.loading = true;
			this.$skinService.iconUrls(this.skin.id)
				.then(data => {
					this.iconUrls = data;
					this.iconUrlHd = this.iconUrls.icon_url_hd;
				})
				.catch(err => console.error(err))
				.finally(() => this.loading = false);
		}

		private trySave(): void {
			this.loading = true;
			this.$store.dispatch('skin/setIconUrlHd', {id: this.skin.id, url: this.iconUrlHd})
				.then(() => this.save(this.iconUrlHd))
				.finally(() => this.loading = false);
		}

	}
