
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import ItemAutocomplete from "@/components/item/ItemAutocomplete.vue";
	import {IItem} from "@/models/IItem";

	@Component({
		components: {ItemAutocomplete}
	})
	export default class ItemSetStackSizeDialog extends Vue {

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		@Prop({type: Object, required: true})
		public item!: IItem;

		private loading: boolean = false;
		private stackSize: number | null = null;

		private reset(): void {
			this.stackSize = this.item.stack_size;
		}

		private trySave(): void {
			const stackSizeFinal = this.stackSize === 0 ? null : this.stackSize;

			this.loading = true;
			this.$store.dispatch('item/setStackSize', {id: this.item.id, stackSize: stackSizeFinal})
				.then(() => this.save(this.stackSize))
				.finally(() => this.loading = false);
		}

		@Watch('item.stack_size', {immediate: true})
		private onItemStackSizeChanged(): void {
			this.reset();
		}

		@Watch('open')
		private onOpenChanged(value: boolean): void {
			if (!value) this.reset();
		}

		@Emit('cancel')
		public cancel(): void {
			//do nothing
		}

		@Emit('save')
		public save(stackSize: number | null): number | null {
			return stackSize;
		}

	}
