
	import Vue from 'vue';
	import Component from "vue-class-component";
	import MainNav from "@/components/layout/MainNav.vue";
	import {Watch} from "vue-property-decorator";
	import LoginView from "@/views/LoginView.vue";
	import {Routes} from "@/helpers/Routes";

	@Component({
		components: {LoginView, MainNav}
	})
	export default class App extends Vue {

		private readonly reloadIntervalDuration: number = 5 * 60 * 1000;

		private reloadTimeout: any = null;

		public beforeDestroy(): void {
			clearInterval(this.reloadTimeout);
		}

		private get isLoggedIn(): boolean {
			return this.$store.getters['user/isLoggedIn']();
		}

		@Watch('isLoggedIn', {immediate: true})
		private onIsLoggedInChanged(value: boolean): void {
			if (value) {
				if (this.$router.currentRoute.name === Routes.login) {
					this.$router.push({name: Routes.home});
				}

				this.loadIntervalEntities(true).then(() => this.loadOneTimeEntities(true));
				this.reloadTimeout = setTimeout(this.performRegularLoad, this.reloadIntervalDuration);
			} else if (this.$router.currentRoute.name !== Routes.login) {
				this.$router.push({name: Routes.login});
				clearTimeout(this.reloadTimeout);
			}
		}

		private async performRegularLoad(): Promise<void> {
			await this.loadIntervalEntities(false);
			this.reloadTimeout = setTimeout(this.performRegularLoad, this.reloadIntervalDuration);
		}

		private async loadIntervalEntities(force: boolean): Promise<void[]> {
			return Promise.all([
				this.$store.dispatch('itemGroup/loadItemGroups', force),
				this.$store.dispatch('item/loadItems', force),
				this.$store.dispatch('skin/loadSkins', force),
				this.$store.dispatch('kit/loadKits', force),
				this.$store.dispatch('inventory/loadInventoryEntries', force),
			]);
		}

		private async loadOneTimeEntities(force: boolean): Promise<void[]> {
			return Promise.all([
				this.$store.dispatch('skin/loadFavourites', force),
				this.$store.dispatch('kit/loadFavourites', force),
			]);
		}

	}
