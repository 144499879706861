import {State} from "@/store";
import {Module} from "vuex";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import Vue from "vue";
import {IItem} from "@/models/IItem";
import IItemData from "@/models/IItemData";

export interface Item {
	loading: boolean,
	loaded: boolean,
	loadingItemIds: number[],
	items: IItem[];
	cardView: boolean;
	latestChange: number | null;
	activeTab: number;
	itemDataLoadingIds: number[],
	itemData: object,
}

const item: Module<Item, State> = {
	namespaced: true,
	state: {
		loading: false,
		loaded: false,
		loadingItemIds: [] as number[],
		items: [] as IItem[],
		cardView: false,
		latestChange: null,
		activeTab: 0,
		itemDataLoadingIds: [] as number[],
		itemData: {},
	},
	getters: {
		itemById: (state: Item) => (id: number): IItem | null => {
			return state.items.find(i => i.id === id) ?? null;
		},
		itemsByIds: (state: Item) => (ids: number[]): IItem[] => {
			return state.items.filter(i => ids.includes(i.id));
		},
		itemDataById: (state: Item) => (id: number): IItemData | null => {
			//@ts-ignore
			return state.itemData[id] ?? null;
		},
	},
	mutations: {
		setLoading(state: Item, isLoading: boolean): void {
			state.loading = isLoading;
		},
		setLoaded(state: Item, isLoaded: boolean): void {
			state.loaded = isLoaded;
		},
		setItemLoading(state: Item, payload: { id: number, isLoading: boolean }): void {
			const isIncluded = state.loadingItemIds.includes(payload.id);
			if (payload.isLoading && !isIncluded) {
				state.loadingItemIds.push(payload.id);
			} else if (!payload.isLoading && isIncluded) {
				state.loadingItemIds = state.loadingItemIds.filter(id => id !== payload.id);
			}
		},
		setItems(state: Item, entries: IItem[]): void {
			state.items = entries;
		},
		setItem(state: Item, item: IItem): void {
			const index = state.items.findIndex(s => s.id === item.id);
			state.items.splice(
				index < 0 ? state.items.length + 1 : index,
				index < 0 ? 0 : 1,
				item,
			);
		},
		setCardView(state: Item, cardView: boolean): void {
			state.cardView = cardView;
		},
		setLatestChange(state: Item, latestChange: number | null): void {
			state.latestChange = latestChange;
		},
		setActiveTab(state: Item, activeTab: number): void {
			state.activeTab = activeTab;
		},
		setItemDataLoading(state: Item, payload: { id: number, isLoading: boolean }): void {
			const isIncluded = state.itemDataLoadingIds.includes(payload.id);
			if (payload.isLoading && !isIncluded) {
				state.itemDataLoadingIds.push(payload.id);
			} else if (!payload.isLoading && isIncluded) {
				state.itemDataLoadingIds = state.itemDataLoadingIds.filter(id => id !== payload.id);
			}
		},
		setItemData(state: Item, payload: { id: number, data: IItemData | null }): void {
			//@ts-ignore
			state.itemData[payload.id] = payload.data ?? undefined;
		},
	},
	actions: {
		async loadLatestChange(context) {
			const latestChange = await Vue.$itemService.latestChange();
			context.commit('setLatestChange', latestChange);
			return latestChange;
		},
		async loadItems(context, force: boolean = false) {
			if (context.state.loading) {
				console.log('loadItems: already loading -> skip');
				return;
			}
			if (!force && context.state.loaded) {
				const latestChangeBefore = context.state.latestChange;
				const latestChangeCurrent = await context.dispatch('loadLatestChange');
				if (latestChangeBefore === latestChangeCurrent) {
					console.log('loadItems: latest change checked and same as local -> skip');
					return;
				} else {
					console.log('loadItems: latest change checked and NOT same as local!', latestChangeBefore, latestChangeCurrent);
				}
			}
			console.log(`loadItems: loading (${force ? 'forced' : 'unforced'})`);

			const options = new AsiListTableOptions();
			options.page = 1;
			options.itemsPerPage = 0;

			context.commit('setLoading', true);
			try {
				const entries = (await Vue.$itemService.items(options)).data;
				context.commit('setItems', entries);
			} catch (e) {
				context.commit('setItems', []);
				console.error('error while loading items', e);
			} finally {
				context.commit('setLoading', false);
				context.commit('setLoaded', true);
			}
		},
		async loadItem(context, id: number) {
			context.commit('setItemLoading', {id: id, isLoading: true});
			try {
				const item = await Vue.$itemService.item(id);
				context.commit('setItem', item);
			} catch (e) {
				console.error(`error while loading item ${id}`, e);
			} finally {
				context.commit('setItemLoading', {id: id, isLoading: false});
			}
		},
		async setStackSize(context, payload: { id: number, stackSize: number | null }) {
			context.commit('setItemLoading', {id: payload.id, isLoading: true});
			try {
				await Vue.$itemService.setStackSize(payload.id, payload.stackSize);
				await context.dispatch('loadItem', payload.id);
			} catch (e) {
				console.error(`error while setting stack size of item ${payload.id}`, e);
			} finally {
				context.commit('setItemLoading', {id: payload.id, isLoading: false});
			}
		},
		async loadItemData(context, id: number) {
			//wait for other loading ops to finish
			while (context.state.itemDataLoadingIds.includes(id)) {
				await new Promise(r => setTimeout(r, 250));
			}

			//check existing
			const existing = context.getters['itemDataById'](id);
			if (existing !== null) return Promise.resolve(existing);

			context.commit('setItemDataLoading', {id: id, isLoading: true});
			try {
				const data = await Vue.$itemService.data(id);
				context.commit('setItemData', {id: id, data: data});
				return Promise.resolve(data);
			} catch (e) {
				console.error(`error while loading item data ${id}`, e);
				return Promise.reject(`error while loading item data ${id}`);
			} finally {
				context.commit('setItemDataLoading', {id: id, isLoading: false});
			}
		},
	},

};

export default item;
