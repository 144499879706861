
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import LoadoutSlots from "@/components/loadout/LoadoutSlots.vue";
	import ILoadout from "@/models/ILoadout";
	import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
	import LoadoutLabelDialog from "@/components/loadout/LoadoutLabelDialog.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import LoadoutDescriptionDialog from "@/components/loadout/LoadoutDescriptionDialog.vue";

	@Component({
		components: {LoadoutDescriptionDialog, LoadoutLabelDialog, ConfirmDialog, LoadoutSlots}
	})
	export default class Loadout extends Vue {

		@Prop({type: Object, required: true})
		public loadout!: ILoadout;

		@Prop({type: Number, default: 64})
		public slotSize!: number;

		@Prop({type: Boolean, default: false})
		public showUsername!: boolean;

		@Prop({type: Boolean, default: false})
		public showIsPublic!: boolean;

		@Prop({type: Boolean, default: false})
		public editEnabled!: boolean;

		@Prop({type: Number, default: null})
		public width!: number | null;

		@Prop({type: Number, default: 500})
		public maxWidth!: number;

		private loading: boolean = false;
		private labelDialog: DialogHandler = new DialogHandler();
		private descriptionDialog: DialogHandler = new DialogHandler();

		private get loadingFinal(): boolean {
			return this.loading || this.$store.getters['loadout/isLoadoutLoading'](this.loadout.id);
		}

		private get hasLoadoutPermission(): boolean {
			return this.$store.getters['loadout/hasLoadoutPermission'](this.loadout.id);
		}

		private get canEdit(): boolean {
			return this.editEnabled && this.hasLoadoutPermission && !this.loadingFinal;
		}

		private get isOwnLoadout(): boolean {
			return this.loadout.user_id === this.$store.state.user.userId;
		}

		private get relevantWidth(): number {
			return this.width ?? this.maxWidth;
		}

		private requestDelete(): void {
			if (this.loadingFinal || !this.editEnabled || !this.hasLoadoutPermission) return;

			const confirm = this.$refs.confirm as unknown as ConfirmDialog;
			confirm.openDialog().then((res: boolean) => {
				if (!res) return;

				this.loading = true;
				this.$store.dispatch('loadout/delete', this.loadout.id)
					.finally(() => this.loading = false);
			});
		}

		private requestToggleIsPublic(): void {
			this.$store.dispatch('loadout/setIsPublic', {
				id: this.loadout.id,
				isPublic: !this.loadout.is_public,
			});
		}

		private requestSaveSlot(event: { type: number, position: number, data: { item_id: number, item_amount: number, description: string | null } | null }): void {
			this.$store.dispatch('loadout/setSlot', {
				id: this.loadout.id,
				type: event.type,
				position: event.position,
				data: event.data,
			});
		}

	}
