
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import IOption from "@/models/IOption";
	import {IItem} from "@/models/IItem";
	import SkinAvatar from "@/components/skin/SkinAvatar.vue";
	import ItemAvatar from "@/components/item/ItemAvatar.vue";

	@Component({
		components: {ItemAvatar, SkinAvatar}
	})
	export default class ItemAutocomplete extends Vue {

		@Prop({type: Number, default: null})
		public value!: number | null;

		@Prop({type: Number, default: null})
		public groupId!: number | null;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		@Prop({type: Boolean, default: true})
		public simple!: boolean;

		@Prop({type: Boolean, default: false})
		public filled!: boolean;

		@Prop({type: String, default: undefined})
		public backgroundColor!: string | undefined;

		@Prop({type: Boolean, default: false})
		public fullWidth!: boolean;

		private valueInternal: number | null = null;

		private get options(): IOption<number>[] {
			return this.$store.state.item.items
				.filter((i: IItem) => {
					return this.groupId === null ? true : i.group_id === this.groupId;
				})
				.sort((a: IItem, b: IItem) => {
					return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
				});
		}

		private get selectedItem(): IItem | null {
			return this.valueInternal === null ? null : this.$store.getters['item/itemById'](this.valueInternal);
		}

		@Watch('value', {immediate: true})
		private onValueChanged(value: number | null): void {
			this.valueInternal = value;
		}

		@Watch('groupId', {immediate: true})
		private onGroupIdChanged(value: number | null): void {
			if (value !== null && this.selectedItem !== null && this.selectedItem.group_id !== value) {
				this.valueInternal = null;
			}
		}

		@Watch('valueInternal')
		@Emit('input')
		public input(event: Event): Event {
			return event;
		}

	}
