
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import DetailAvatar from "@/components/common/DetailAvatar.vue";
	@Component({
		components: {DetailAvatar}
	})
	export default class DetailHeader extends Vue {

		@Prop({type: String, default: null})
		public title!: string | null;

		@Prop({type: String, default: null})
		public iconUrl!: string | null;

		@Prop({type: String, default: null})
		public iconUrlHd!: string | null;

		private get hasSubtitleSlot(): boolean {
			return !!this.$slots.subtitle || !!this.$scopedSlots['subtitle'];
		}

		private get hasTableSlot(): boolean {
			return !!this.$slots.table || !!this.$scopedSlots['table'];
		}

		private get hasAsideSlot(): boolean {
			return !!this.$slots.aside || !!this.$scopedSlots['aside'];
		}

	}
