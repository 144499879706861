
	import Vue from 'vue';
	import {Component, Prop, Watch} from 'vue-property-decorator';
	import {ISkinPricingEntry} from "@/models/ISkinPricingEntry";
	import DateHelper from "@/helpers/DateHelper";

	@Component
	export default class SkinPricingEntriesCard extends Vue {

		@Prop({type: Array, required: true})
		public pricingEntries!: ISkinPricingEntry[];

		@Prop({type: Boolean, default: false})
		public saleOffers!: boolean;

		@Prop({type: Boolean, default: false})
		public buyOrders!: boolean;

		@Prop({type: Boolean, default: false})
		public chart!: boolean;

		private index: number = 0;

		private get currentEntry(): ISkinPricingEntry | null {
			return this.pricingEntries[this.pricingEntries.length - 1 - this.index] ?? null;
		}

		private get currentEntryCreatedAt(): string {
			return this.currentEntry === null ? '?' : DateHelper.formatDateTime(this.currentEntry.created_at);
		}

		private get isSell(): boolean {
			return this.saleOffers || (!this.saleOffers && !this.buyOrders);
		}

		private get rows(): { price: number | null, amount: number | null }[] {
			if (this.currentEntry === null) return [];

			const rows = this.isSell
				? [
					{price: this.currentEntry.sell0, amount: this.currentEntry.sell0_amount},
					{price: this.currentEntry.sell1, amount: this.currentEntry.sell1_amount},
					{price: this.currentEntry.sell2, amount: this.currentEntry.sell2_amount},
					{price: this.currentEntry.sell3, amount: this.currentEntry.sell3_amount},
					{price: this.currentEntry.sell4, amount: this.currentEntry.sell4_amount},
					{price: this.currentEntry.sell_rest, amount: this.currentEntry.sell_rest_amount},
				]
				: [
					{price: this.currentEntry.buy0, amount: this.currentEntry.buy0_amount},
					{price: this.currentEntry.buy1, amount: this.currentEntry.buy1_amount},
					{price: this.currentEntry.buy2, amount: this.currentEntry.buy2_amount},
					{price: this.currentEntry.buy3, amount: this.currentEntry.buy3_amount},
					{price: this.currentEntry.buy4, amount: this.currentEntry.buy4_amount},
					{price: this.currentEntry.buy_rest, amount: this.currentEntry.buy_rest_amount},
				];

			return rows.filter(r => r.amount !== null);
		}

		private get numTotal(): number {
			return this.rows.reduce((acc: number, cur) => {
				return acc + (cur.amount ?? 0);
			}, 0);
		}

		private get startingPrice(): number | null {
			return this.rows[0]?.price ?? null;
		}

		@Watch('pricingEntries')
		private onPricingEntriesChanged(): void {
			this.index = 0;
		}

		private prev(): void {
			if (this.index > 0) this.index--;
		}

		private next(): void {
			if (this.index + 1 < this.pricingEntries.length) this.index++;
		}

	}
