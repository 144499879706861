import IListFilter from "@/models/filter/IListFilter";
import WordFilter from "@/helpers/WordFilter";
import {IItemGroup} from "@/models/IItemGroup";

export default class ItemGroupListFilter implements IListFilter<IItemGroup> {

	public text: string | null = null;

	public clear(): void {
		this.text = null;
	}

	public hasFilter(): boolean {
		return this.text !== null;
	}

	public applyFilter(models: IItemGroup[]): IItemGroup[] {
		if (!this.hasFilter()) return models;

		return models.filter(itemGroup => {
			if (this.text !== null && itemGroup.id.toString() !== this.text && !WordFilter.matches(this.text, itemGroup.label)) return false;
			return true;
		});
	}

}
