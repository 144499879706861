import IOption from "@/models/IOption";

export default class ListHelper {

	public static readonly itemsPerPageOptions: number[] = [10, 20, 30, 50, 100, 250];

	public static readonly nullableBooleanOptions: IOption<boolean|null>[] = [
		{text: '', value: null},
		{text: 'Yes', value: true},
		{text: 'No', value: false},
	];

}
