import Vue from 'vue';
import VueRouter, {NavigationGuardNext, Route, RouteConfig} from 'vue-router';
import HomeView from '../views/HomeView.vue';
import {Routes} from "@/helpers/Routes";
import {PositionResult} from "vue-router/types/router";
import InventoryView from "@/views/InventoryView.vue";
import ItemView from "@/views/ItemView.vue";
import ItemsView from "@/views/ItemsView.vue";
import SkinsView from "@/views/SkinsView.vue";
import SkinView from "@/views/SkinView.vue";
import KitsView from "@/views/KitsView.vue";
import KitView from "@/views/KitView.vue";
import LoginView from "@/views/LoginView.vue";
import RouterHelper from "@/helpers/RouterHelper";
import ItemGroupsView from "@/views/ItemGroupsView.vue";
import ItemGroupView from "@/views/ItemGroupView.vue";
import UsersView from "@/views/UsersView.vue";
import CrawlJobLogsView from "@/views/CrawlJobLogsView.vue";
import LoadoutsView from "@/views/LoadoutsView.vue";
import CraftCalculatorView from "@/views/CraftCalculatorView.vue";
import ProxiesView from "@/views/ProxiesView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: Routes.home,
		component: HomeView,
		meta: {
			title: 'Home',
		},
	},
	{
		path: '/login',
		name: Routes.login,
		component: LoginView,
		meta: {
			title: 'Login',
		},
	},
	{
		path: '/inventory',
		name: Routes.inventory,
		component: InventoryView,
		meta: {
			title: 'Inventory',
		},
	},
	{
		path: '/item-group',
		name: Routes.itemGroups,
		component: ItemGroupsView,
		meta: {
			title: 'Item Groups',
		},
	},
	{
		path: '/item-group/:id',
		name: Routes.itemGroup,
		component: ItemGroupView,
		props: true,
		meta: {
			title: 'Item Group',
		},
	},
	{
		path: '/item',
		name: Routes.items,
		component: ItemsView,
		meta: {
			title: 'Items',
		},
	},
	{
		path: '/item/:id',
		name: Routes.item,
		component: ItemView,
		props: true,
		meta: {
			title: 'Item',
		},
	},
	{
		path: '/skin',
		name: Routes.skins,
		component: SkinsView,
		meta: {
			title: 'Skins',
		},
	},
	{
		path: '/skin/:id',
		name: Routes.skin,
		component: SkinView,
		props: true,
		meta: {
			title: 'Skin',
		},
	},
	{
		path: '/kit',
		name: Routes.kits,
		component: KitsView,
		meta: {
			title: 'Kits',
		},
	},
	{
		path: '/kit/:id',
		name: Routes.kit,
		component: KitView,
		props: true,
		meta: {
			title: 'Kit',
		},
	},
	{
		path: '/loadout',
		name: Routes.loadouts,
		component: LoadoutsView,
		meta: {
			title: 'Loadouts',
		},
	},
	{
		path: '/user',
		name: Routes.users,
		component: UsersView,
		meta: {
			title: 'Users',
		},
	},
	{
		path: '/craft-calculator',
		name: Routes.craftCalculator,
		component: CraftCalculatorView,
		meta: {
			title: 'Craft Calculator',
		},
	},
	{
		path: '/crawl-job-log',
		name: Routes.crawlJobLogs,
		component: CrawlJobLogsView,
		meta: {
			title: 'Crawl Job Log',
		},
	},
	{
		path: '/proxies',
		name: Routes.proxies,
		component: ProxiesView,
		meta: {
			title: 'Proxies',
		},
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition): PositionResult | null {
		if (savedPosition) {
			return savedPosition;
		} else {
			return {
				x: 0,
				y: 0,
			};
		}
	},
});

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
	RouterHelper.updateTitle(null, to);
	next();
});

export default router;
