
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';

	@Component
	export default class UserUpdatePasswordDialog extends Vue {

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		@Prop({type: Number, default: null})
		public id!: number;

		private loading: boolean = false;
		private password: string | null = null;
		private showText: boolean = false;
		private regexMap: Map<string, RegExp> = new Map<string, RegExp>([
			['At least 8 characters in length', new RegExp(/^.{8,}$/g)],
			['At least one lowercase character', new RegExp(/[a-z]{1,}/g)],
			['At least one uppercase character', new RegExp(/[A-Z]{1,}/g)],
			['At least one number', new RegExp(/\d{1,}/g)],
			['At least one special character', new RegExp(/([^\w\s]|[_]){1,}/g)],
		]);

		private get pwValid(): boolean {
			if (this.password === null) return false;
			const regexEntries = Array.from(this.regexMap.values());
			for (let i = 0; i < regexEntries.length; i++) {
				if (!this.password.match(regexEntries[i])) return false;
			}
			return true;
		}

		private get canSave(): boolean {
			return this.userId !== null && this.password !== null && this.pwValid;
		}

		private get userId(): number | null {
			return this.id ?? this.$store.state.user.userId;
		}

		@Watch('open')
		private onOpenChanged(value: boolean): void {
			if (!value) this.reset();
		}

		@Emit('cancel')
		public cancel(): void {
			//do nothing
		}

		@Emit('save')
		public save(): void {
			return;
		}

		private trySave(): void {
			if (!this.canSave || this.userId === null || this.password === null) return;

			this.loading = true;
			this.$userService.updatePassword(this.userId, this.password)
				.then(() => {
					this.reset();
					this.save();
				})
				.catch(err => console.error(err))
				.finally(() => this.loading = false);
		}

		private reset(): void {
			this.password = null;
		}

		private regexResultIcon(regexp: RegExp): string {
			if (this.password === null || this.password.length === 0) 'mdi-help';
			return this.password !== null && this.password.match(regexp) ? 'mdi-check-bold' : 'mdi-close-thick';
		}

		private regexResultColor(regexp: RegExp): string {
			if (this.password === null || this.password.length === 0) 'black';
			return this.password !== null && this.password.match(regexp) ? 'success' : 'error';
		}

	}
