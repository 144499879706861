
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {ISkin} from "@/models/ISkin";

	@Component
	export default class SkinChipLinkRustlabs extends Vue {

		@Prop({type: Object, required: true})
		public skin!: ISkin;

		@Prop({type: Boolean, default: false})
		public dense!: boolean;

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public xSmall!: boolean;

		@Prop({type: String, default: 'yellow'})
		public color!: string;

		private get label(): string {
			return this.dense ? 'R' : 'Rustlabs';
		}

		private openLink(): void {
			if (this.skin.rustlabs_url === null) return;
			window.open(this.skin.rustlabs_url, '_blank');
		}

	}
