
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import {IUser} from "@/models/IUser";

	@Component
	export default class UserUpdateDialog extends Vue {

		@Prop({type: Object, required: true})
		public user!: IUser;

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		private loading: boolean = false;
		private username: string | null = null;
		private steamId: number | null = null;
		private password: string | null = null;
		private regexSteamId: RegExp = new RegExp(/^\d{17}$/);

		private get canSave(): boolean {
			return this.username !== null && this.username.trim().length > 0
				&& this.steamId !== null && this.regexSteamId.test(this.steamId.toString());
		}

		private reset(): void {
			this.username = this.user.username;
			this.steamId = this.user.steam_id;
		}

		private trySave(): void {
			if (!this.canSave || this.username === null || this.steamId === null) {
				return;
			}

			this.loading = true;
			this.$userService.update(this.user.id, this.username, this.steamId)
				.then(() => {
					this.save();
					if (this.user.id === this.$store.state.user.userId) {
						this.$store.dispatch('user/loadLoggedInUserData');
					}
				})
				.finally(() => this.loading = false);
		}

		@Watch('user', {deep: true, immediate: true})
		private onUserChanged(): void {
			this.reset();
		}

		@Watch('open')
		private onOpenChanged(value: boolean): void {
			if (!value) this.reset();
		}

		@Emit('cancel')
		public cancel(): void {
			this.reset();
		}

		@Emit('save')
		public save(): void {
			return;
		}

	}
