
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {IItem} from "@/models/IItem";
	import ItemHelper from "@/helpers/ItemHelper";

	@Component
	export default class ItemAvatar extends Vue {

		@Prop({type: Object, required: true})
		public item!: IItem;

		@Prop({type: [Number, String], default: 38})
		public size!: number | string;

		@Prop({type: Boolean, default: false})
		public noTooltip!: boolean;

		@Prop({type: Boolean, default: false})
		public noMargin!: boolean;

		private get iconUrl(): string | null {
			return this.$store.state.ui.showAvatars
				? ItemHelper.iconUrl(this.item)
				: null;
		}

	}
