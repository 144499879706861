import IListFilter from "@/models/filter/IListFilter";
import {IUser} from "@/models/IUser";

export default class UserListFilter implements IListFilter<IUser> {

	public text: string | null = null;

	public clear(): void {
		this.text = null;
	}

	public hasFilter(): boolean {
		return this.text !== null;
	}

	public applyFilter(models: IUser[]): IUser[] {
		return models.filter(user => {
			if (this.text !== null) {
				const text = this.text.toLowerCase();
				if (user.username.toLowerCase().indexOf(text) === -1 && user.steam_id.toString().indexOf(text) === -1) {
					return false;
				}
			}

			return true;
		});
	}

}
