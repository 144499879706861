
	import Vue from 'vue';
	import {Component, Prop, Watch} from 'vue-property-decorator';
	import {IKit} from "@/models/IKit";
	import SkinList from "@/components/skin/SkinList.vue";
	import KitHelper from "@/helpers/KitHelper";
	import KitSkinsDialog from "@/components/kit/KitSkinsDialog.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import KitFavouriteButton from "@/components/kit/KitFavouriteButton.vue";
	import KitCoverSkinDialog from "@/components/kit/KitCoverSkinDialog.vue";
	import DetailHeader from "@/components/common/DetailHeader.vue";
	import DeleteButton from "@/components/common/DeleteButton.vue";
	import KitRenameDialog from "@/components/kit/KitRenameDialog.vue";
	import {Routes} from "@/helpers/Routes";
	import RouterHelper from "@/helpers/RouterHelper";
	import Timestamp from "@/components/common/Timestamp.vue";
	import KitProgressCircular from "@/components/kit/KitProgressCircular.vue";
	import ProfitValues from "@/components/common/ProfitValues.vue";
	import {ISkin} from "@/models/ISkin";
	import ConfirmDialog from "@/components/common/ConfirmDialog.vue";

	@Component({
		components: {
			ConfirmDialog,
			ProfitValues,
			KitProgressCircular,
			Timestamp,
			KitRenameDialog,
			DeleteButton, DetailHeader, KitCoverSkinDialog, KitFavouriteButton, KitSkinsDialog, SkinList
		}
	})
	export default class KitView extends Vue {

		@Prop({type: String, required: true})
		public id!: string;

		private loading: boolean = false;
		private renameDialog: DialogHandler = new DialogHandler();
		private skinsDialog: DialogHandler = new DialogHandler();
		private coverSkinDialog: DialogHandler = new DialogHandler();

		private get idNumeric(): number | null {
			const id = Number.parseInt(this.id);
			return Number.isNaN(id) ? null : id;
		}

		private get kit(): IKit | null {
			return this.idNumeric === null ? null : this.$store.getters['kit/kitById'](this.idNumeric);
		}

		private get iconUrl(): string | null {
			return this.kit === null || !this.$store.state.ui.showAvatars
				? null
				: KitHelper.iconUrl(this.kit);
		}

		private get iconUrlHd(): string | null {
			return this.kit === null ? null : KitHelper.iconUrlHd(this.kit);
		}

		private get hasKitPermission(): boolean {
			return this.idNumeric !== null && this.$store.getters['kit/hasKitPermission'](this.idNumeric);
		}

		private get skins(): ISkin[] {
			return this.kit === null ? [] : this.$store.getters['skin/skinsByIds'](this.kit.skin_ids);
		}

		private get profitData(): { reference: number, current: number } | null {
			const relevantSkins = this.skins.filter(s => s.tradeable && s.price !== null && s.price_item_store !== null);
			if (relevantSkins.length === 0) return null;

			return relevantSkins.reduce((acc: { reference: number, current: number }, cur: ISkin) => {
				acc.reference += cur.price_item_store ?? 0;
				acc.current += cur.price ?? 0;
				return acc;
			}, {reference: 0, current: 0});
		}

		private requestDelete(): void {
			if (!this.hasKitPermission || this.idNumeric === null) return;

			this.loading = true;
			this.$store.dispatch('kit/delete', this.idNumeric)
				.then(() => this.$router.push({name: Routes.kits}))
				.finally(() => this.loading = false);
		}

		@Watch('kit.label', {immediate: true})
		private onKitLabelChanged(value: string | null): void {
			RouterHelper.updateTitle(value);
		}

		private performRemoveFromKit(id: number): void {
			if (!this.hasKitPermission || this.idNumeric === null || this.kit === null) return;

			const confirm = this.$refs.confirm as unknown as ConfirmDialog;
			confirm.openDialog().then((res: boolean) => {
				if (!res) return;

				this.loading = true;
				this.$store.dispatch('kit/setSkins', {
					id: this.idNumeric,
					skinIds: this.kit!.skin_ids.filter(skinId => skinId !== id),
				})
					.finally(() => this.loading = false);
			});
		}

	}
