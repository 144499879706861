import {Module} from "vuex";
import {State} from "@/store";
import {ITokenPair} from "@/models/ITokenPair";
import {timerAsync} from "@/helpers/TimerAsync";
import Vue from "vue";
import {IUser} from "@/models/IUser";
import jwtDecode from "jwt-decode";

export interface User {
	token: string | null;
	refreshToken: string | null;
	isRefreshingToken: { value: boolean; setDate: number };
	isAxiosRefreshingToken: boolean;
	userId: number | null;
	user: IUser | null;
	roles: string[] | null;
}

const user: Module<User, State> = {
	namespaced: true,
	state: {
		token: null,
		refreshToken: null,
		isRefreshingToken: {value: false, setDate: Date.now()},
		isAxiosRefreshingToken: false,
		userId: null,
		user: null,
		roles: null,
	},
	getters: {
		isLoggedIn: (state: User) => (): boolean => {
			return state.token !== null;
		},
		isAdmin: (state: User) => (): boolean => {
			return state.roles?.includes('admin') ?? false;
		}
	},
	mutations: {
		setTokenData(state: User, payload: ITokenPair | null): void {
			state.token = payload?.token ?? null;
			state.refreshToken = payload?.refresh_token ?? null;

			if (state.token === null) {
				state.userId = null;
				state.roles = null;
			} else {
				try {
					const decoded = jwtDecode(state.token) as { jti: number, roles: string[] };
					state.userId = decoded.jti;
					state.roles = decoded.roles;
				} catch (err) {
					console.error('Error while decoding token', err);
					state.userId = null;
					state.roles = null;
				}
			}
		},
		setIsAxiosRefreshingToken(state: User, payload: boolean): void {
			state.isAxiosRefreshingToken = payload;
		},
		setIsRefreshingToken(state: User, payload: boolean): void {
			state.isRefreshingToken.value = payload;
			state.isRefreshingToken.setDate = Date.now();
		},
		setUser(state: User, user: IUser | null): void {
			state.user = user;
		},
	},
	actions: {
		async refreshToken(context) {
			if (context.state.refreshToken === null) {
				console.error('no refresh token, can\'t refresh');
				return;
			}

			if (context.state.isRefreshingToken.value) {
				while (context.state.isRefreshingToken.value && (Date.now() - context.state.isRefreshingToken.setDate < 10000)) {
					await timerAsync(1000);
				}
				if (Date.now() - context.state.isRefreshingToken.setDate < 10000) {
					return;
				}
			}

			context.commit('setIsRefreshingToken', true);
			try {
				const tokenPair = await Vue.$authService.refresh(context.state.refreshToken);

				context.commit('setTokenData', tokenPair);
				await context.dispatch('loadLoggedInUserData');
				context.commit('setIsRefreshingToken', false);
			} catch (err) {
				console.error('error in user store: refreshToken', err);
				context.commit('setIsRefreshingToken', false);
				await context.dispatch('logout');
			}
		},
		async logout(context) {
			try {
				await Vue.$authService.logout().then(async () => {
					await context.dispatch('clearLoggedInUserData');
				});
			} catch (e) {
				console.error('logout failed', e);
			}
		},
		async loadLoggedInUserData(context) {
			let user: IUser | null = null;
			if (context.state.userId !== null) {
				try {
					user = await Vue.$userService.user(context.state.userId);
					context.commit('setUser', user);
					console.log('loadLoggedInUserData: success');
				} catch (e) {
					console.error('fetching logged in user data failed', e);
				}
			}
		},
		async clearLoggedInUserData(context) {
			context.commit('setTokenData', null);
			context.commit('setUser', null);
			context.commit('inventory/clearUserData', undefined, {root: true});
			context.commit('skin/clearUserData', undefined, {root: true});
			context.commit('kit/clearUserData', undefined, {root: true});
		},
	},
};

export default user;
