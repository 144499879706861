
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import ILoadout from "@/models/ILoadout";

	@Component
	export default class LoadoutCreateDialog extends Vue {

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		private loading: boolean = false;
		private label: string | null = null;
		private description: string | null = null;
		private isPublic: boolean = true;

		private get canSave(): boolean {
			return this.label !== null && this.label.trim().length > 0;
		}

		private reset(): void {
			this.label = null;
			this.description = null;
			this.isPublic = false;
		}

		private trySave(): void {
			if (!this.canSave || this.label === null) return;
			const label = this.label.trim();

			this.loading = true;
			this.$store.dispatch('loadout/create', {
				label: label,
				description: this.description,
				isPublic: this.isPublic,
			})
				.then(res => this.save(res))
				.finally(() => this.loading = false);
		}

		@Watch('open')
		private onOpenChanged(value: boolean): void {
			if (!value) this.reset();
		}

		@Emit('cancel')
		public cancel(): void {
			//do nothing
		}

		@Emit('save')
		public save(loadout: ILoadout): ILoadout {
			return loadout;
		}

	}
