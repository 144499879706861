import IListFilter from "@/models/filter/IListFilter";
import {IKit} from "@/models/IKit";
import WordFilter from "@/helpers/WordFilter";
import store from "@/store";

export default class KitListFilter implements IListFilter<IKit> {

	public text: string | null = null;
	public isFavourite: boolean | null = null;
	public owned: number | null = null;

	public clear(): void {
		this.text = null;
		this.isFavourite = null;
		this.owned = null;
	}

	public hasFilter(): boolean {
		return this.text !== null
			|| this.isFavourite !== null
			|| this.owned !== null;
	}

	public applyFilter(models: IKit[]): IKit[] {
		return models.filter(kit => {
			if (this.text !== null && kit.id.toString() !== this.text && !WordFilter.matches(this.text, kit.label)) return false;
			if (this.isFavourite !== null && this.isFavourite !== store.getters['kit/isFavourite'](kit.id)) return false;
			if (this.owned !== null) {
				const numOwned = store.getters['inventory/numOwned'](kit.skin_ids);
				switch (this.owned) {
					case 1:
						//fully
						if (numOwned !== kit.skin_ids.length) return false;
						break;
					case 2:
						//partially
						if (numOwned === 0 || numOwned === kit.skin_ids.length) return false;
						break;
					case 3:
						if (numOwned > 0) return false;
						break;
				}
			}

			return true;
		});
	}

}
