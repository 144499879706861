
	import Vue from 'vue';
	import {Component, Prop, Watch} from 'vue-property-decorator';
	import {ICrawlJobLog} from "@/models/ICrawlJobLog";
	import DialogHandler from "@/components/common/DialogHandler";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import DateHelper from "@/helpers/DateHelper";
	import AsiListTableHeader from "@/components/common/AsiListTableHeader";
	import CrawlJobLogIterationDataDialog from "@/components/crawl-job-log/CrawlJobLogIterationDataDialog.vue";

	@Component({
		components: {CrawlJobLogIterationDataDialog}
	})
	export default class CrawlJobLogs extends Vue {

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		@Prop({type: String, default: null})
		public textFilter!: string | null;

		@Prop({type: Number, default: null})
		public skinId!: number | null;

		@Prop({type: String, default: null})
		public job!: string | null;

		private loadingInternal: number = 0;
		private entries: ICrawlJobLog[] = [];
		private usernames: Map<number, string> = new Map<number, string>();
		private items: Map<number, string> = new Map<number, string>();
		private skins: Map<number, string> = new Map<number, string>();
		private iterationDataDialog: DialogHandler = new DialogHandler(this.clearIterationData);
		private iterationData: string | null = null;

		public created(): void {
			this.loadUsernames();
			this.loadEntries();
		}

		private get entriesFinal(): ICrawlJobLog[] {
			const filter = this.textFilter?.trim().toLowerCase() ?? null;
			if (filter === null || filter.length === 0) return this.entries;

			return this.entries.filter(e => {
				if (e.skin_id !== null && this.skinLabel(e.skin_id).toLowerCase().indexOf(filter) === -1) return false;
				if (e.item_id !== null && this.itemLabel(e.item_id).toLowerCase().indexOf(filter) === -1) return false;
				return true;
			});
		}

		private get headers(): AsiListTableHeader[] {
			const ret = [
				new AsiListTableHeader('', 'id', true, true, 'center', '5rem'),
				new AsiListTableHeader('Job', 'job', true, true, 'start'),
				new AsiListTableHeader('Result', 'result', true, true, 'center', '8rem'),
				new AsiListTableHeader('Started', 'started_at', true, true, 'center', '10%'),
				new AsiListTableHeader('Ended', 'ended_at', true, true, 'center', '10%'),
			];

			if (this.entries.some(e => e.user_id !== null)) {
				ret.push(new AsiListTableHeader('User', 'user_id', true, true, 'start', '25%'));
			}
			if (this.entries.some(e => e.item_id !== null)) {
				ret.push(new AsiListTableHeader('Item', 'item_id', true, true, 'start', '25%'));
			}
			if (this.skinId === null && this.entries.some(e => e.skin_id !== null)) {
				ret.push(new AsiListTableHeader('Skin', 'skin_id', true, true, 'start', '25%'));
			}

			ret.push(new AsiListTableHeader('Iteration Data', 'iteration_data', false, false, 'end', '10rem'));

			return ret;
		}

		@Watch('skinId')
		@Watch('job')
		private onSelectionChanged(): void {
			this.loadEntries();
		}

		@Watch('loadingInternal')
		private onLoadingInternalChanged(value: number): void {
			this.$emit('update:loading', value > 0);
		}

		private showIterationData(data: string | null): void {
			if (data === null) return;
			this.iterationData = data;
			this.iterationDataDialog.open();
		}

		private itemLabel(id: number): string {
			let item = this.items.get(id);
			if (item !== undefined) return item;
			item = this.$store.getters['item/itemById'](id)?.label ?? undefined;
			if (item !== undefined) {
				this.items.set(id, item);
			}
			return item ?? id.toString();
		}

		private skinLabel(id: number): string {
			let skin = this.skins.get(id);
			if (skin !== undefined) return skin;
			skin = this.$store.getters['skin/skinById'](id)?.label ?? undefined;
			if (skin !== undefined) {
				this.skins.set(id, skin);
			}
			return skin ?? id.toString();
		}

		private usernameLabel(id: number): string {
			return this.usernames.get(id) ?? '?';
		}

		private loadUsernames(): void {
			const options = new AsiListTableOptions();
			options.itemsPerPage = 0;

			this.loadingInternal++;
			this.$userService.users(options)
				.then(data => data.data.forEach(u => this.usernames.set(u.id, u.username)))
				.catch(err => console.error(err))
				.finally(() => this.loadingInternal--);
		}

		private loadEntries(): void {
			this.loadingInternal++;
			this.$crawlJobLogService.crawlJobLogs(this.job, this.skinId)
				.then(data => this.entries = data)
				.catch(err => console.error(err))
				.finally(() => this.loadingInternal--);
		}

		private clearIterationData(): void {
			this.iterationData = null;
		}

		private finalizeJobName(job: string): string {
			return job
				.split('-')
				.map(p => p.substring(0, 1).toUpperCase() + p.substring(1))
				.join(' ');
		}

		private formatDateTime(unixTimestamp: number | null): string | null {
			if (unixTimestamp === null) return null;
			return DateHelper.formatDateTime(unixTimestamp, true);
		}

	}
