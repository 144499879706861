
	import Vue from 'vue';
	import {Component, Emit, Prop} from 'vue-property-decorator';

	@Component
	export default class EntityCard extends Vue {

		@Prop({type: String, required: true})
		public title!: string;

		@Prop({type: Boolean, default: false})
		public showIcon!: boolean;

		@Prop({type: String, default: null})
		public iconUrl!: string | null;

		@Prop({type: String, default: null})
		public iconBgColor!: string | null;

		@Prop({type: Boolean, default: false})
		public showProgress!: boolean;

		@Prop({type: Number, default: null})
		public progress!: number | null;

		@Prop({type: String, default: 'grey'})
		public progressColor!: string;

		@Prop({type: Boolean, default: true})
		public showTopLeftIcon!: boolean;

		@Prop({type: String, default: null})
		public topLeftIcon!: string | null;

		@Prop({type: String, default: 'grey'})
		public topLeftIconColor!: string;

		@Prop({type: String, default: null})
		public topLeftIconBgColor!: string | null;

		@Prop({type: Boolean, default: true})
		public showTopRightIcon!: boolean;

		@Prop({type: String, default: null})
		public topRightIcon!: string | null;

		@Prop({type: String, default: 'grey'})
		public topRightIconColor!: string;

		@Prop({type: String, default: null})
		public topRightIconBgColor!: string | null;

		private hover: boolean = false;

		private get iconContainerStyles(): object {
			const iconBgColor = this.iconBgColor ?? '#e5e5e5';
			const gradients = ['repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(0, 0, 0, 0.0075) 5px, rgba(0, 0, 0, 0.0075) 10px)'];
			if (this.showTopLeftIcon && this.topLeftIcon !== null && this.topLeftIconBgColor !== null) {
				gradients.push(`radial-gradient(at top left, ${this.topLeftIconBgColor}, transparent 40%)`);
			}
			if (this.showTopRightIcon && this.topRightIcon !== null && this.topRightIconBgColor !== null) {
				gradients.push(`radial-gradient(at top right, ${this.topRightIconBgColor}, transparent 40%)`);
			}
			gradients.push(`radial-gradient(transparent 40%, ${iconBgColor})`);

			return {
				'cursor': this.hasClickHandler ? 'pointer' : 'inherit',
				'background-color': '#fff',
				'background-image': gradients.join(', '),
			};
		}

		private get hasChipSlot(): boolean {
			return !!this.$slots.chip || !!this.$scopedSlots['chip'];
		}

		private get hasContentSlot(): boolean {
			return !!this.$slots.content || !!this.$scopedSlots['content'];
		}

		private get hasClickHandler(): boolean {
			//@ts-ignore
			return this.$listeners && this.$listeners.click;
		}

		@Emit('click')
		public click(event: Event): Event {
			return event;
		}

	}
