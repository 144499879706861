export default class DateHelper {

	public static formatDateTime(unixTimestamp: number, dense: boolean = false, locale: string = 'de-CH'): string {
		return (new Date(unixTimestamp * 1000)).toLocaleDateString(locale, {
			weekday: dense ? undefined : 'short',
			year: '2-digit',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		});
	}

	public static formatDate(unixTimestamp: number, dense: boolean = false, locale: string = 'de-CH'): string {
		return (new Date(unixTimestamp * 1000)).toLocaleDateString(locale, {
			weekday: dense ? undefined : 'short',
			year: dense ? '2-digit' : 'numeric',
			month: '2-digit',
			day: '2-digit',
		});
	}

}
