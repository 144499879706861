import AxiosService from "@/services/AxiosService";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import Vue from "vue";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IItemService from "@/services/definition/IItemService";
import {IItem} from "@/models/IItem";
import IItemData from "@/models/IItemData";

export default class AxiosItemService extends AxiosService implements IItemService {

	protected defineBaseUrl(): string {
		return 'item';
	}

	public items(options: AsiListTableOptions): Promise<IPaginatedResponse<IItem>> {
		return Vue.$axios.get<IItem[]>(this.url('index'), {
			params: {
				...AxiosService.finalizeOptions(options)
			}
		})
			.then(res => {
				return {
					data: res.data,
					total: Number.parseInt(res.headers['x-pagination-total-count']),
				} as IPaginatedResponse<IItem>;
			});
	}

	public item(id: number): Promise<IItem> {
		return Vue.$axios.get<IItem>(this.url('view'), {
			params: {
				id: id,
			}
		}).then(res => res.data);
	}

	public latestChange(): Promise<number> {
		return Vue.$axios.get<number>(this.url('latest-change')).then(res => res.data);
	}

	public data(id: number): Promise<IItemData> {
		return Vue.$axios.get<IItemData>(this.url('data'), {
			params: {
				id: id,
			}
		}).then(res => res.data);
	}

	public setStackSize(id: number, stackSize: number | null): Promise<null> {
		return Vue.$axios.patch<null>(this.url(`set-stack-size`), {
			id: id,
			stack_size: stackSize,
		}).then(res => res.data);
	}

}
