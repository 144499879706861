
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";

	export type SortTemplateOption = { label: string, icon: string | null, sortBy: string[], sortDesc: boolean[] }

	@Component
	export default class SortControl extends Vue {

		@Prop({type: Object, required: true})
		public value!: AsiListTableOptions;

		@Prop({type: Array, default: null})
		public defaultSortBy!: string[] | null;

		@Prop({type: Array, default: null})
		public defaultSortDesc!: boolean[] | null;

		@Prop({type: Array, default: () => []})
		public sortTemplateOptions!: SortTemplateOption[];

		private sortBy: string[] = [];
		private sortDesc: boolean[] = [];

		private get hasSort(): boolean {
			if (this.defaultSortBy !== null && (this.defaultSortBy.length !== this.sortBy.length || this.defaultSortBy.some((s, i) => s !== this.sortBy[i]))) {
				return true;
			}
			if (this.defaultSortDesc !== null && (this.defaultSortDesc.length !== this.sortDesc.length || this.defaultSortDesc.some((s, i) => s !== this.sortDesc[i]))) {
				return true;
			}
			return false;
		}

		private get color(): string | null {
			return this.hasSort ? 'error' : null;
		}

		@Watch('value', {deep: true, immediate: true})
		private onOptionsChanged(value: AsiListTableOptions): void {
			this.sortBy = value.sortBy;
			this.sortDesc = value.sortDesc;
		}

		@Emit('input')
		public input(): AsiListTableOptions {
			const options = new AsiListTableOptions();
			options.page = this.value.page;
			options.itemsPerPage = this.value.itemsPerPage;
			options.sortBy = this.sortBy;
			options.sortDesc = this.sortDesc;
			return options;
		}

		private resetDefault(): void {
			this.sortBy = this.defaultSortBy ?? [];
			this.sortDesc = this.defaultSortDesc ?? [];
			this.input();
		}

		private clear(): void {
			this.sortBy = [];
			this.sortDesc = [];
			this.input();
		}

		private applySortTemplateOption(tpl: SortTemplateOption): void {
			let changed = false;
			tpl.sortBy.forEach(s => {
				if (!this.sortBy.includes(s)) {
					this.sortBy.push(s);
					changed = true;
				}
			});
			tpl.sortDesc.forEach(s => {
				if (!this.sortDesc.includes(s)) {
					this.sortDesc.push(s);
					changed = true;
				}
			});

			if (changed) this.input();
		}

	}
