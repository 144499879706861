import {State} from "@/store";
import {Module} from "vuex";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import Vue from "vue";
import {IItemGroup} from "@/models/IItemGroup";

export interface ItemGroup {
	loading: boolean,
	loaded: boolean,
	itemGroups: IItemGroup[];
	latestChange: number | null;
}

const itemGroup: Module<ItemGroup, State> = {
	namespaced: true,
	state: {
		loading: false,
		loaded: false,
		itemGroups: [] as IItemGroup[],
		latestChange: null,
	},
	getters: {
		itemGroupById: (state: ItemGroup) => (id: number): IItemGroup | null => {
			return state.itemGroups.find(i => i.id === id) ?? null;
		},
		itemGroupsByIds: (state: ItemGroup) => (ids: number[]): IItemGroup[] => {
			return state.itemGroups.filter(ig => ids.includes(ig.id));
		},
	},
	mutations: {
		setLoading(state: ItemGroup, isLoading: boolean): void {
			state.loading = isLoading;
		},
		setLoaded(state: ItemGroup, isLoaded: boolean): void {
			state.loaded = isLoaded;
		},
		setItemGroups(state: ItemGroup, entries: IItemGroup[]): void {
			state.itemGroups = entries;
		},
		setLatestChange(state: ItemGroup, latestChange: number | null): void {
			state.latestChange = latestChange;
		},
	},
	actions: {
		async loadLatestChange(context) {
			const latestChange = await Vue.$itemGroupService.latestChange();
			context.commit('setLatestChange', latestChange);
			return latestChange;
		},
		async loadItemGroups(context, force: boolean = false) {
			if (context.state.loading) {
				console.log('loadItemGroups: already loading -> skip');
				return;
			}
			if (!force && context.state.loaded) {
				const latestChangeBefore = context.state.latestChange;
				const latestChangeCurrent = await context.dispatch('loadLatestChange');
				if (latestChangeBefore === latestChangeCurrent) {
					console.log('loadItemGroups: latest change checked and same as local -> skip');
					return;
				} else {
					console.log('loadItemGroups: latest change checked and NOT same as local!', latestChangeBefore, latestChangeCurrent);
				}
			}
			console.log(`loadItemGroups: loading (${force ? 'forced' : 'unforced'})`);

			const options = new AsiListTableOptions();
			options.page = 1;
			options.itemsPerPage = 0;

			context.commit('setLoading', true);
			try {
				const entries = (await Vue.$itemGroupService.itemGroups(options)).data;
				context.commit('setItemGroups', entries);
			} catch (e) {
				context.commit('setItems', []);
				console.error('error while loading item groups', e);
			} finally {
				context.commit('setLoading', false);
				context.commit('setLoaded', true);
			}
		},
	},

};

export default itemGroup;
