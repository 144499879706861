
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';

	@Component
	export default class SkinChipLinkAuthorWorkshop extends Vue {

		@Prop({type: String, default: null})
		public author!: string | null;

		@Prop({type: String, default: null})
		public authorWorkshopUrl!: string | null;

		@Prop({type: Boolean, default: false})
		public dense!: boolean;

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public xSmall!: boolean;

		@Prop({type: String, default: 'secondary'})
		public color!: string;

		@Prop({type: Boolean, default: false})
		public authorNameOnly!: boolean;

		private get label(): string {
			return this.dense ? 'A' : this.authorLabel;
		}

		private get authorLabel(): string {
			return `${this.author ?? 'Author'}${this.authorNameOnly ? '' : 's Workshop'}`;
		}

		private openLink(): void {
			if (this.authorWorkshopUrl === null) return;
			window.open(this.authorWorkshopUrl, '_blank');
		}

	}
