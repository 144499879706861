
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {IItem} from "@/models/IItem";
	import ItemAvatar from "@/components/item/ItemAvatar.vue";
	import IItemData from "@/models/IItemData";
	import CraftItemSwitch from "@/components/craft-calculator/CraftItemSwitch.vue";
	import {Routes} from "@/helpers/Routes";

	@Component({
		components: {CraftItemSwitch, ItemAvatar}
	})
	export default class CraftIngredient extends Vue {

		@Prop({type: Object, required: true})
		public data!: { item: IItem, amount: number, itemData: IItemData };

		private numberFormatter: Intl.NumberFormat = new Intl.NumberFormat('de-CH');

		private get finalAmount(): number {
			return this.data.amount;
		}

		private get formattedAmount(): string {
			return this.numberFormatter.format(this.finalAmount);
		}

		private get stackQuantityString(): string | null {
			if (this.data.item.stack_size === null) return 'Stack size missing';

			const stacks = Math.floor(this.finalAmount / this.data.item.stack_size);
			if (stacks === 0) return 'Less than a stack';
			const rest = this.finalAmount - stacks * this.data.item.stack_size;

			const parts = [];
			parts.push(`${stacks} ${stacks === 1 ? 'Stack' : 'Stacks'}`);
			if (rest > 0) parts.push(this.numberFormatter.format(rest));

			return parts.join(' + ');
		}

		private get showCraftItemSwitch(): boolean {
			return this.data.itemData.ingredients.length > 0;
		}

		private showItem(): void {
			this.$router.push({name: Routes.item, params: {id: this.data.item.id.toString()}});
		}

	}
