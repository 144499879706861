
	import Vue from 'vue';
	import {Component, Emit, Prop} from 'vue-property-decorator';
	import LoadoutSlot from "@/components/loadout/LoadoutSlot.vue";
	import ILoadoutSlot from "@/models/ILoadoutSlot";
	import ILoadout from "@/models/ILoadout";

	@Component({
		components: {LoadoutSlot}
	})
	export default class LoadoutSlots extends Vue {


		@Prop({type: Object, required: true})
		public loadout!: ILoadout;

		@Prop({type: Number, required: true})
		public type!: number;

		@Prop({type: Number, required: true})
		public size!: number;

		@Prop({type: Number, default: 6})
		public columns!: number;

		@Prop({type: Number, default: undefined})
		public imageSize!: number | undefined;

		@Prop({type: Boolean, default: false})
		public editEnabled!: boolean;

		private get models(): ILoadoutSlot[] {
			switch (this.type) {
				case 0:
					return this.loadout.inventory_slots;
				case 1:
					return this.loadout.hotbar_slots;
				case 2:
					return this.loadout.attire_slots;
				default:
					return [];
			}
		}

		private get styles(): object {
			return {
				'grid-template-columns': `repeat(${this.columns}, 1fr)`,
			};
		}

		private modelAtPosition(position: number): ILoadoutSlot | null {
			return this.models.find(m => m.position === position) ?? null;
		}

		@Emit('slotClicked')
		public slotClicked(position: number): number {
			return position;
		}

		@Emit('requestSaveSlot')
		public requestSaveSlot(type: number, position: number, data: { item_id: number, item_amount: number, description: string | null } | null): { type: number, position: number, data: { item_id: number, item_amount: number, description: string | null } | null } {
			return {
				type: type,
				position: position,
				data: data,
			};
		}

	}
