
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import {IUser} from "@/models/IUser";
	import UserUpdatePasswordDialog from "@/components/user/UserUpdatePasswordDialog.vue";
	import DialogHandler from "@/components/common/DialogHandler";

	@Component({
		components: {UserUpdatePasswordDialog}
	})
	export default class UserMenu extends Vue {

		private loadingInventory: boolean = false;
		private loadingItemStore: boolean = false;
		private updatePasswordDialog: DialogHandler = new DialogHandler();

		private get user(): IUser | null {
			return this.$store.state.user.user;
		}

		private get roles(): string {
			return this.$store.state.user.roles?.join(', ') ?? '';
		}

		private get isAdmin(): boolean {
			return this.$store.getters['user/isAdmin']();
		}

		private get showAvatars(): boolean {
			return this.$store.state.ui.showAvatars;
		}

		private set showAvatars(value: boolean) {
			this.$store.commit('ui/setShowAvatars', value);
		}

		private get crawlingPricingEntriesEnabled(): boolean {
			return this.$store.state.skin.crawlingPricingEntriesEnabled;
		}

		private set crawlingPricingEntriesEnabled(value: boolean) {
			this.$store.commit('skin/setCrawlingPricingEntriesEnabled', value);
		}

		private logout(): void {
			this.$store.dispatch('user/logout');
		}

		private async reimportInventory(): Promise<boolean> {
			if (this.loadingInventory) Promise.resolve(false);

			this.loadingInventory = true;
			let result = true;
			try {
				await this.$store.dispatch('inventory/reimportInventory');
				await this.$store.dispatch('inventory/loadInventoryEntries', true);
			} catch (e) {
				result = false;
			} finally {
				this.loadingInventory = false;
			}
			return Promise.resolve(result);
		}

		private reimportItemStore(): void {
			if (this.loadingItemStore || !this.isAdmin) return;

			this.loadingItemStore = true;
			this.$skinService.importItemStore()
				.then(res => {
					console.log('Imported item store:', res);
					if (res !== null) {
						this.$store.dispatch('skin/loadSkins', true);
					}
				})
				.catch(err => console.error(err))
				.finally(() => this.loadingItemStore = false);
		}
	}
