export default class DialogHandler {

	private readonly closeDelay!: number;
	private readonly unloadedCallback: (() => void) | null;
	private readonly openPreconditionCallback: (() => boolean) | null;
	private timeout: any = null;
	public isLoaded: boolean = false;
	public isOpen: boolean = false;

	public constructor(onUnloaded: (() => void) | null = null, openPrecondition: (() => boolean) | null = null, closeDelay: number = 300,) {
		this.unloadedCallback = onUnloaded;
		this.openPreconditionCallback = openPrecondition;
		this.closeDelay = closeDelay;
	}

	public open(): void {
		if (this.openPreconditionCallback !== null && !this.openPreconditionCallback()) {
			console.error('open precondition not satisfied', this.openPreconditionCallback);
			return;
		}

		this.stop();
		this.isLoaded = true;
		this.isOpen = true;
	}

	public close(): void {
		this.stop();
		this.isOpen = false;
		this.timeout = setTimeout(() => {
			this.isLoaded = false;
			if (this.unloadedCallback !== null) {
				console.log('calling unloaded callback');
				this.unloadedCallback();
			}
			this.timeout = null;
		}, this.closeDelay);
	}

	public reset(): void {
		this.stop();
		this.isOpen = false;
		this.isLoaded = false;
	}

	public stop(): void {
		clearTimeout(this.timeout);
	}

}
