import {ISkin} from "@/models/ISkin";
import ISkinWorkshopData from "@/models/ISkinWorkshopData";

export default class SkinHelper {

	public static iconUrl(skin: ISkin): string | null {
		return skin.has_icon ? `${process.env.VUE_APP_API_BASE_URL}skin/icon?id=${skin.id}` : null;
	}

	public static iconUrlHd(skin: ISkin): string | null {
		return skin.has_icon_hd ? `${process.env.VUE_APP_API_BASE_URL}skin/icon-hd?id=${skin.id}` : null;
	}

	public static marketUrl(skin: ISkin): string {
		return `https://steamcommunity.com/market/listings/252490/${encodeURIComponent(skin.market_hash_name ?? skin.market_name ?? skin.label)}`;
	}

	public static workshopUrl(skin: ISkin): string {
		return `https://steamcommunity.com/sharedfiles/filedetails/?id=${skin.workshop_id}`;
	}

	public static workshopImagesThumbs(workshopData: ISkinWorkshopData): string[] {
		return workshopData.image_urls.map(url => SkinHelper.workShopImgUrlToThumb(url, 512));
	}

	public static workshopImagesFull(workshopData: ISkinWorkshopData): string[] {
		return workshopData.image_urls.map(url => SkinHelper.workShopImgUrlToThumb(url, 2048));
	}

	protected static workShopImgUrlToThumb(url: string, imgSize: number): string {
		return url
			.replace(/imw=\d+/, `imw=${imgSize}`)
			.replace(/imh=\d+/, `imh=${imgSize}`);
	}

}
