
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';

	@Component
	export default class Timestamp extends Vue {

		@Prop({required: true})
		public timestamp!: number | null;

		@Prop({type: Boolean, default: false})
		public created!: boolean;

		@Prop({type: Boolean, default: false})
		public updated!: boolean;

		@Prop({type: Boolean, default: false})
		public noDate!: boolean;

		@Prop({type: Boolean, default: false})
		public noTime!: boolean;

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public muted!: boolean;

		private get output(): string {
			if (this.timestamp === null) return '?';
			return (new Date(this.timestamp * 1000)).toLocaleDateString('de-CH', {
				weekday: this.noDate ? undefined : 'short',
				year: this.noDate ? undefined : '2-digit',
				month: this.noDate ? undefined : '2-digit',
				day: this.noDate ? undefined : '2-digit',
				hour: this.noTime ? undefined : '2-digit',
				minute: this.noTime ? undefined : '2-digit',
			});
		}

		private get icon(): string {
			if (this.created) return 'mdi-asterisk';
			if (this.updated) return 'mdi-pencil';
			return 'mdi-clock';
		}

	}
