
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {ISkin} from "@/models/ISkin";
	import SkinHelper from "@/helpers/SkinHelper";

	@Component
	export default class SkinChipLinkWorkshop extends Vue {

		@Prop({type: Object, required: true})
		public skin!: ISkin;

		@Prop({type: Boolean, default: false})
		public dense!: boolean;

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public xSmall!: boolean;

		@Prop({type: String, default: 'warning'})
		public color!: string;

		private get label(): string {
			return this.dense ? 'W' : 'Workshop';
		}

		private openLink(): void {
			window.open(SkinHelper.workshopUrl(this.skin), '_blank');
		}

	}
