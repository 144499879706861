
	import Vue from 'vue';
	import {Component, Emit, Prop} from 'vue-property-decorator';
	import ILoadoutSlot from "@/models/ILoadoutSlot";
	import {IItem} from "@/models/IItem";
	import ItemAvatar from "@/components/item/ItemAvatar.vue";
	import LoadoutSlotDialog from "@/components/loadout/LoadoutSlotDialog.vue";
	import DialogHandler from "@/components/common/DialogHandler";

	@Component({
		components: {LoadoutSlotDialog, ItemAvatar}
	})
	export default class LoadoutSlot extends Vue {

		@Prop({type: Object, default: null})
		public model!: ILoadoutSlot | null;

		@Prop({type: Number, default: 56})
		public imageSize!: number;

		@Prop({type: Boolean, default: false})
		public editEnabled!: boolean;

		private hovered: boolean = false;
		private slotDialog: DialogHandler = new DialogHandler();

		private get canEdit(): boolean {
			return this.editEnabled;
		}

		private get item(): IItem | null {
			return this.model === null ? null : this.$store.getters['item/itemById'](this.model.item_id);
		}

		@Emit('clicked')
		public clicked(): void {
			if (this.editEnabled) this.slotDialog.open();
			return;
		}

		@Emit('requestSaveSlot')
		public requestSaveSlot(data: { itemId: number, itemAmount: number, description: string | null }): { itemId: number, itemAmount: number, description: string | null } {
			this.slotDialog.close();
			return data;
		}

	}
