import AxiosService from "@/services/AxiosService";
import IInventoryEntryService from "@/services/definition/IInventoryEntryService";
import {IInventoryEntry} from "@/models/IInventoryEntry";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import Vue from "vue";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";

export default class AxiosInventoryEntryService extends AxiosService implements IInventoryEntryService {

	protected defineBaseUrl(): string {
		return 'inventory-entry';
	}

	public inventoryEntries(options: AsiListTableOptions): Promise<IPaginatedResponse<IInventoryEntry>> {
		return Vue.$axios.get<IInventoryEntry[]>(this.url('index'), {
			params: {
				...AxiosService.finalizeOptions(options)
			}
		})
			.then(res => {
				return {
					data: res.data,
					total: Number.parseInt(res.headers['x-pagination-total-count']),
				} as IPaginatedResponse<IInventoryEntry>;
			});
	}

	public reimport(): Promise<null> {
		return Vue.$axios.put(this.url('reimport')).then(res => res.data);
	}

	public latestChange(): Promise<number> {
		return Vue.$axios.get<number>(this.url('latest-change')).then(res => res.data);
	}

}
