
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import ILoadout from "@/models/ILoadout";

	@Component({})
	export default class LoadoutLabelDialog extends Vue {

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		@Prop({type: Object, required: true})
		public loadout!: ILoadout;

		private loading: boolean = false;
		private labelInternal: string | null = null;

		private get canSave(): boolean {
			return this.$store.getters['loadout/hasLoadoutPermission'](this.loadout.id)
				&& this.labelInternal !== null && this.labelInternal.trim().length > 0
				&& this.labelInternal !== this.loadout.label;
		}

		private reset(): void {
			this.labelInternal = this.loadout.label;
		}

		private trySave(): void {
			if (!this.canSave || this.labelInternal === null) return;

			this.loading = true;
			this.$store.dispatch('loadout/setLabel', {id: this.loadout.id, label: this.labelInternal})
				.then(() => this.save(this.labelInternal ?? '?'))
				.finally(() => this.loading = false);
		}

		@Watch('loadout.label', {immediate: true})
		private onLoadoutLabelChanged(): void {
			this.reset();
		}

		@Watch('open')
		private onOpenChanged(value: boolean): void {
			if (!value) this.reset();
		}

		@Emit('cancel')
		public cancel(): void {
			//do nothing
		}

		@Emit('save')
		public save(label: string): string {
			return label;
		}

	}
