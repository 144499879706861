
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {IItem} from "@/models/IItem";
	import {Routes} from "@/helpers/Routes";
	import ItemHelper from "@/helpers/ItemHelper";
	import EntityCard from "@/components/common/EntityCard.vue";

	@Component({
		components: {EntityCard}
	})
	export default class ItemCard extends Vue {

		@Prop({type: Object, required: true})
		public item!: IItem;

		private get iconUrl(): string | null {
			return ItemHelper.iconUrl(this.item);
		}

		private showItem(): void {
			this.$router.push({name: Routes.item, params: {id: this.item.id.toString()}});
		}

	}
