import IListFilter from "@/models/filter/IListFilter";
import WordFilter from "@/helpers/WordFilter";
import {IItem} from "@/models/IItem";

export default class ItemListFilter implements IListFilter<IItem> {

	public text: string | null = null;
	public groupId: number | null = null;

	public clear(): void {
		this.text = null;
		this.groupId = null;
	}

	public hasFilter(): boolean {
		return this.text !== null
			|| this.groupId !== null;
	}

	public applyFilter(models: IItem[]): IItem[] {
		return models.filter(item => {
			if (this.text !== null && item.id.toString() !== this.text && !WordFilter.matches(this.text, item.label)) return false;
			if (this.groupId !== null && item.group_id !== this.groupId) return false;

			return true;
		});
	}

}
