
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import {IUser} from "@/models/IUser";

	@Component
	export default class UserCreateDialog extends Vue {

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		private loading: boolean = false;
		private username: string | null = null;
		private steamId: string | null = null;
		private password: string | null = null;
		private regexSteamId: RegExp = new RegExp(/^\d{17}$/);
		private regexPassword: RegExp = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/);
		private showText: boolean = false;

		private get canSave(): boolean {
			return this.username !== null && this.username.trim().length > 0
				&& this.steamId !== null && this.regexSteamId.test(this.steamId)
				&& this.password !== null && this.regexPassword.test(this.password);
		}

		private reset(): void {
			this.username = null;
			this.steamId = null;
			this.password = null;
		}

		private trySave(): void {
			if (!this.canSave || this.username === null || this.steamId === null || this.password === null) {
				return;
			}

			this.loading = true;
			this.$userService.create(this.username, this.steamId, this.password)
				.then(res => this.save(res))
				.finally(() => this.loading = false);
		}

		@Watch('open')
		private onOpenChanged(value: boolean): void {
			if (!value) this.reset();
		}

		@Emit('cancel')
		public cancel(): void {
			//do nothing
		}

		@Emit('save')
		public save(user: IUser): IUser {
			return user;
		}

	}
