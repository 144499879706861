
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import CurrencyHelper from "@/helpers/CurrencyHelper";

	@Component
	export default class ProfitValues extends Vue {

		@Prop({type: Number, required: true})
		public referencePrice!: number | null;

		@Prop({type: Number, required: true})
		public price!: number | null;

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public dark!: boolean;

		private get profitAbsolute(): number | null {
			return this.referencePrice === null || this.price === null
				? null
				: CurrencyHelper.profitAbsolute(this.referencePrice, this.price);
		}

		private get profitPercent(): number | null {
			return this.referencePrice === null || this.price === null
				? null
				: CurrencyHelper.profitPercent(this.referencePrice, this.price);
		}

		private get color(): string | undefined {
			if (this.profitAbsolute === null || this.profitAbsolute === 0) return undefined;
			return this.profitAbsolute > 0 ? 'success' : 'error';
		}

		private get sign(): string {
			return this.profitAbsolute !== null && this.profitAbsolute > 0 ? '+' : '';
		}

	}
