
	import Vue from 'vue';
	import {Component, Prop, Watch} from 'vue-property-decorator';
	import SkinCard from "@/components/skin/SkinCard.vue";
	import SkinPricingEntriesMultiFetchButton from "@/components/skin/SkinPricingEntriesMultiFetchButton.vue";
	import {ISkin} from "@/models/ISkin";
	import DateHelper from "@/helpers/DateHelper";

	@Component({
		components: {SkinPricingEntriesMultiFetchButton, SkinCard}
	})
	export default class HomeNewSkins extends Vue {

		@Prop({type: Number, required: true})
		public cardSize!: number;

		@Prop({type: Object, required: true})
		public cardContainerStyles!: object;

		@Prop({type: Boolean, required: true})
		public isAdmin!: boolean;

		private dateMenuOpen: boolean = false;
		private pickerDate: string | null = `${(new Date()).getFullYear()}-${((new Date()).getMonth() + 1).toString().padStart(2, '0')}`;
		private skinMap: Map<string, ISkin[]> = new Map<string, ISkin[]>();
		private loading: boolean = false;

		public created(): void {
			this.loading = true;
			this.initializeSkinMap().finally(() => this.loading = false);
		}

		private get skins(): ISkin[] {
			if (this.selectedReleaseDate === null || this.loading) return [];
			return this.skinMap.get(this.selectedReleaseDate)?.slice(0, 50) ?? [];
		}

		private get numHiddenSkins(): number {
			if (this.selectedReleaseDate === null || this.loading) return 0;
			return (this.skinMap.get(this.selectedReleaseDate)?.length ?? 50) - 50;
		}

		private get releaseDates(): string[] {
			return this.$store.state.skin.releaseDates;
		}

		private get selectedReleaseDate(): string | null {
			return this.$store.state.ui.home.selectedReleaseDate;
		}

		private set selectedReleaseDate(value: string | null) {
			this.$store.commit('ui/setHomeSelectedReleaseDate', value);
		}

		private get selectedReleaseDateFormatted(): string | null {
			if (this.selectedReleaseDate === null) return '?';
			const date = new Date(this.selectedReleaseDate);
			return DateHelper.formatDate(Math.round(date.valueOf() / 1000), true);
		}

		private get newestSelected(): boolean {
			return this.selectedReleaseDate === null
				? true
				: this.releaseDates.indexOf(this.selectedReleaseDate) === 0;
		}

		private get oldestSelected(): boolean {
			return this.selectedReleaseDate === null
				? true
				: this.releaseDates.indexOf(this.selectedReleaseDate) === this.releaseDates.length - 1;
		}

		@Watch('cardSize')
		private onCardSizeChanged(): void {
			(this.$refs.newestSkins as SkinCard[] | undefined)?.forEach((card: SkinCard) => card.updateCardWidth());
		}

		private showNewest(): void {
			if (this.releaseDates.length > 0 && !this.newestSelected) {
				this.selectedReleaseDate = this.releaseDates[0];
				this.pickerDate = this.selectedReleaseDate.substring(0, 7);
			}
		}

		private prev(): void {
			if (this.newestSelected || this.selectedReleaseDate === null) return;
			this.selectedReleaseDate = this.releaseDates[this.releaseDates.indexOf(this.selectedReleaseDate) - 1];
			this.pickerDate = this.selectedReleaseDate.substring(0, 7);
		}

		private next(): void {
			if (this.oldestSelected || this.selectedReleaseDate === null) return;
			this.selectedReleaseDate = this.releaseDates[this.releaseDates.indexOf(this.selectedReleaseDate) + 1];
			this.pickerDate = this.selectedReleaseDate.substring(0, 7);
		}

		private async initializeSkinMap(): Promise<void> {
			this.skinMap.clear();
			this.$store.state.skin.releaseDates.forEach((d: string) => this.skinMap.set(d, []));
			(this.$store.state.skin.skins as ISkin[])
				.filter(s => s.release_date !== null)
				.forEach(s => {
					const skins = this.skinMap.get(s.release_date!) as ISkin[] | undefined;
					if (skins !== undefined) skins.push(s);
				});

			this.skinMap.forEach((value: ISkin[], key: string) => {
				this.skinMap.set(key, value.sort((a, b) => a.label.localeCompare(b.label)));
			});
		}

	}
