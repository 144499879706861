import AxiosService from "@/services/AxiosService";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import Vue from "vue";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import ISkinService from "@/services/definition/ISkinService";
import {ISkin} from "@/models/ISkin";
import {ISkinPriceOverview} from "@/models/ISkinPriceOverview";
import {ISkinPricingEntry} from "@/models/ISkinPricingEntry";
import {ISkinImportItemStoreResult} from "@/models/ISkinImportItemStoreResult";
import ISkinWorkshopData from "@/models/ISkinWorkshopData";
import ISkinIconUrls from "@/models/ISkinIconUrls";

export default class AxiosSkinService extends AxiosService implements ISkinService {

	protected defineBaseUrl(): string {
		return 'skin';
	}

	public skins(options: AsiListTableOptions): Promise<IPaginatedResponse<ISkin>> {
		return Vue.$axios.get<ISkin[]>(this.url('index'), {
			params: {
				...AxiosService.finalizeOptions(options)
			}
		})
			.then(res => {
				return {
					data: res.data,
					total: Number.parseInt(res.headers['x-pagination-total-count']),
				} as IPaginatedResponse<ISkin>;
			});
	}

	public skin(id: number): Promise<ISkin> {
		return Vue.$axios.get<ISkin>(this.url('view'), {
			params: {
				id: id,
			},
		}).then(res => res.data);
	}

	public workshopData(id: number): Promise<ISkinWorkshopData> {
		return Vue.$axios.get<ISkinWorkshopData>(this.url('workshop-data'), {
			params: {
				id: id,
			},
		}).then(res => res.data);
	}

	public priceOverview(id: number): Promise<ISkinPriceOverview> {
		return Vue.$axios.get<ISkinPriceOverview>(this.url('price-overview'), {
			params: {
				id: id,
			},
		}).then(res => res.data);
	}

	public marketDetail(id: number): Promise<object> {
		return Vue.$axios.get<object>(this.url('market-detail'), {
			params: {
				id: id,
			},
		}).then(res => res.data);
	}

	public latestPricingEntry(id: number): Promise<ISkinPricingEntry | null> {
		return Vue.$axios.get<ISkinPricingEntry | null>(this.url('latest-pricing-entry'), {
			params: {
				id: id,
			},
		}).then(res => res.data);
	}

	public pricingEntries(id: number, crawlIfNecessary: boolean): Promise<ISkinPricingEntry[]> {
		return Vue.$axios.get<ISkinPricingEntry[]>(this.url('pricing-entries'), {
			params: {
				id: id,
				'crawl_if_necessary': crawlIfNecessary,
			},
		}).then(res => res.data);
	}

	public importItemStore(): Promise<ISkinImportItemStoreResult | null> {
		return Vue.$axios.put<ISkinImportItemStoreResult | null>(this.url('import-item-store'))
			.then(res => res.data);
	}

	public favourites(): Promise<number[]> {
		return Vue.$axios.get<number[]>(this.url('favourites')).then(res => res.data);
	}

	public setFavourite(id: number, isFavourite: boolean): Promise<null> {
		const promise = isFavourite
			? Vue.$axios.post<null>(this.url(`favourite`), {id: id})
			: Vue.$axios.delete(this.url(`favourite`), {params: {id: id}});
		return promise.then(null);
	}

	public latestChange(): Promise<number> {
		return Vue.$axios.get<number>(this.url('latest-change')).then(res => res.data);
	}

	public setItem(id: number, itemId: number | null): Promise<null> {
		return Vue.$axios.patch<null>(this.url(`set-item`), {
			id: id,
			item_id: itemId,
		}).then(res => res.data);
	}

	public setSetPriceItemStore(id: number, priceItemStore: number | null): Promise<null> {
		return Vue.$axios.patch<null>(this.url(`set-price-item-store`), {
			id: id,
			price_item_store: priceItemStore,
		}).then(res => res.data);
	}

	public setWorkshopId(id: number, workshopId: number | null): Promise<null> {
		return Vue.$axios.patch<null>(this.url(`set-workshop-id`), {
			id: id,
			workshop_id: workshopId,
		}).then(res => res.data);
	}

	public setIconUrl(id: number, url: string | null): Promise<null> {
		return Vue.$axios.patch<null>(this.url(`set-icon-url`), {
			id: id,
			icon_url: url,
		}).then(res => res.data);
	}

	public setIconUrlHd(id: number, url: string | null): Promise<null> {
		return Vue.$axios.patch<null>(this.url(`set-icon-url-hd`), {
			id: id,
			icon_url_hd: url,
		}).then(res => res.data);
	}

	public iconUrls(id: number): Promise<ISkinIconUrls> {
		return Vue.$axios.get<ISkinIconUrls>(this.url(`icon-urls`), {
			params: {
				id: id,
			},
		}).then(res => res.data);
	}

}
