import AxiosService from "@/services/AxiosService";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import Vue from "vue";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IKitService from "@/services/definition/IKitService";
import {IKit} from "@/models/IKit";

export default class AxiosKitService extends AxiosService implements IKitService {

	protected defineBaseUrl(): string {
		return 'kit';
	}

	public kits(options: AsiListTableOptions): Promise<IPaginatedResponse<IKit>> {
		return Vue.$axios.get<IKit[]>(this.url('index'), {
			params: {
				...AxiosService.finalizeOptions(options)
			}
		})
			.then(res => {
				return {
					data: res.data,
					total: Number.parseInt(res.headers['x-pagination-total-count']),
				} as IPaginatedResponse<IKit>;
			});
	}

	public kit(id: number): Promise<IKit> {
		return Vue.$axios.get<IKit>(this.url('view'), {
			params: {
				id: id,
			}
		}).then(res => res.data);
	}

	public create(label: string, userId: number | null): Promise<IKit> {
		return Vue.$axios.post<IKit>(this.url('create'), {
			label: label,
			user_id: userId,
		}).then(res => res.data);
	}

	public rename(id: number, label: string): Promise<null> {
		return Vue.$axios.patch<null>(this.url(`rename`), {
			id: id,
			label: label,
		}).then(res => res.data);
	}

	public delete(id: number): Promise<null> {
		return Vue.$axios.delete(this.url('delete'), {
			params: {
				id: id
			},
		}).then(null);
	}

	public setSkins(id: number, skinIds: number[]): Promise<null> {
		return Vue.$axios.patch<null>(this.url(`set-skins`), {
			id: id,
			skin_ids: skinIds,
		}).then(res => res.data);
	}

	public setCoverSkin(id: number, coverSkinId: number | null): Promise<null> {
		return Vue.$axios.patch<null>(this.url(`set-cover-skin`), {
			id: id,
			cover_skin_id: coverSkinId,
		}).then(res => res.data);
	}

	public favourites(): Promise<number[]> {
		return Vue.$axios.get<number[]>(this.url('favourites')).then(res => res.data);
	}

	public setFavourite(id: number, isFavourite: boolean): Promise<null> {
		const promise = isFavourite
			? Vue.$axios.post<null>(this.url(`favourite`), {id: id})
			: Vue.$axios.delete(this.url(`favourite`), {params: {id: id}});
		return promise.then(null);
	}

	public latestChange(): Promise<number> {
		return Vue.$axios.get<number>(this.url('latest-change')).then(res => res.data);
	}

}
