
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import ICraftCalculatorLevel from "@/models/ICraftCalculatorLevel";
	import ItemAvatar from "@/components/item/ItemAvatar.vue";
	import CraftItemSwitch from "@/components/craft-calculator/CraftItemSwitch.vue";

	@Component({
		name: 'craft-tree-level',
		components: {CraftItemSwitch, ItemAvatar},
	})
	export default class CraftTreeLevel extends Vue {

		@Prop({type: Object, required: true})
		public level!: ICraftCalculatorLevel;

		private get formattedAmount(): string {
			// const amount = this.level.depth === 0
			// 	? (this.level.itemData.craft_batch_size ?? 1) * this.level.amount
			// 	: this.level.amount;
			return (new Intl.NumberFormat('de-CH')).format(this.level.amount);
		}

		private get showCraftItem(): boolean {
			return this.level.depth > 0 && this.level.childLevels.length > 0;
		}

		private get craftItem(): boolean {
			return this.$store.getters['craftCalculator/isItemCrafted'](this.level.item.id);
		}

	}
