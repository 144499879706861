
	import Vue from 'vue';
	import {Component, Watch} from 'vue-property-decorator';
	import {ITokenPair} from "@/models/ITokenPair";
	import Logo from "@/components/common/Logo.vue";

	@Component({
		components: {Logo}
	})
	export default class Login extends Vue {

		private loading: boolean = false;
		private showPassword: boolean = false;
		private username: string | null = null;
		private password: string | null = null;
		private errorText: string | null = null;

		private get isLoggedIn(): boolean {
			return this.$store.getters['user/isLoggedIn']();
		}

		private get canLogin(): boolean {
			return this.username !== null && this.password !== null && this.username.length > 0 && this.password.length > 0;
		}

		private get mobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		}

		@Watch('username')
		@Watch('password')
		private onUsernameChanged(): void {
		    this.errorText = null;
		}

		private requestLogin(): void {
			if (!this.canLogin || this.username === null || this.password === null) return;

			this.loading = true;
			this.$authService.authenticate(this.username, this.password)
				.then((tokenPair: ITokenPair) => {
					this.$store.commit('user/setTokenData', tokenPair);
					this.$store.dispatch('user/loadLoggedInUserData');
				})
				.catch(err => {
					this.errorText = 'Login failed!';
					console.error('Login failed', err);
				})
				.finally(() => this.loading = false);
		}

	}
