import AxiosService from "@/services/AxiosService";
import ISteamService from "@/services/definition/ISteamService";
import Vue from "vue";

export default class AxiosSteamService extends AxiosService implements ISteamService {

	protected defineBaseUrl(): string {
		return 'https://steamcommunity.com';
	}

	public inventory(steamId: string, appId: number, contextId: number): Promise<string> {
		return Vue.$axios.get<string>(this.url(`inventory/${steamId}/${appId}/${contextId}?l=english&count=5000`), {
			headers: {
				'Access-Control-Allow-Origin': '*',
			},
		})
			.then(res => res.data);
	}

}
