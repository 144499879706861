
	import Vue from 'vue';
	import {Component, Emit, Prop} from 'vue-property-decorator';

	@Component
	export default class CrawlJobLogIterationDataDialog extends Vue {

		@Prop({type: String, required: true})
		public iterationData!: string;

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		@Emit('cancel')
		public cancel(): void {
			return;
		}

	}
