
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import {IKit} from "@/models/IKit";
	import {ISkin} from "@/models/ISkin";
	import SkinAutocomplete from "@/components/skin/SkinAutocomplete.vue";
	import SkinAvatar from "@/components/skin/SkinAvatar.vue";

	@Component({
		components: {SkinAvatar, SkinAutocomplete}
	})
	export default class KitCoverSkinDialog extends Vue {

		@Prop({type: Object, required: true})
		public kit!: IKit;

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		private loading: boolean = false;
		private coverSkinId: number | null = null;

		private get options(): ISkin[] {
			return this.$store.state.skin.skins
				.filter((s: ISkin) => s.id === this.kit.cover_skin_id || this.kit.skin_ids.includes(s.id))
				.sort((a: ISkin, b: ISkin) => {
					return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
				});
		}

		private get selectedSkin(): ISkin | null {
			return this.coverSkinId === null ? null : this.$store.getters['skin/skinById'](this.coverSkinId);
		}

		private reset(): void {
			this.coverSkinId = this.kit.cover_skin_id;
		}

		private trySave(): void {
			this.loading = true;
			this.$store.dispatch('kit/setCoverSkin', {id: this.kit.id, coverSkinId: this.coverSkinId})
				.then(() => this.save(this.coverSkinId))
				.finally(() => this.loading = false);
		}

		@Watch('kit', {deep: true, immediate: true})
		private onKitChanged(): void {
			this.reset();
		}

		@Watch('open')
		private onOpenChanged(value: boolean): void {
			if (!value) this.reset();
		}

		@Emit('cancel')
		public cancel(): void {
			//do nothing
		}

		@Emit('save')
		public save(coverSkinId: number | null): number | null {
			return coverSkinId;
		}

	}
