export enum Routes {
	login = 'Login',
	home = 'Home',
	inventory = 'Inventory',
	itemGroups = 'ItemGroups',
	itemGroup = 'ItemGroup',
	items = 'Items',
	item = 'Item',
	skins = 'Skins',
	skin = 'Skin',
	kits = 'Kits',
	kit = 'Kit',
	users = 'Users',
	user = 'User',
	loadouts = 'Loadouts',
	craftCalculator = 'CraftCalculator',
	crawlJobLogs = 'CrawlJobLogs',
	proxies = 'Proxies',
}
