export default class CurrencyHelper {

	public static profitAbsolute(referencePrice: number, price: number): number {
		return price - referencePrice;
	}

	public static profitPercent(referencePrice: number, price: number): number {
		if (referencePrice === 0) return 0;
		return (price - referencePrice) / referencePrice * 100;
	}

}
