import { render, staticRenderFns } from "./InventoryList.vue?vue&type=template&id=5cd9d901&scoped=true&"
import script from "./InventoryList.vue?vue&type=script&lang=ts&"
export * from "./InventoryList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cd9d901",
  null
  
)

export default component.exports