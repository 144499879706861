
	import Vue from 'vue';
	import {Component, Prop, Watch} from 'vue-property-decorator';
	import {IItem} from "@/models/IItem";
	import AsiListTableHeader from "@/components/common/AsiListTableHeader";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import {Routes} from "@/helpers/Routes";
	import ItemGroupChip from "@/components/item-group/ItemGroupChip.vue";
	import ItemCard from "@/components/item/ItemCard.vue";
	import ArrayHelper, {MultiSortHandler} from "@/helpers/ArrayHelper";
	import ReloadButton from "@/components/common/ReloadButton.vue";
	import SkinListFilter from "@/models/filter/SkinListFilter";
	import ListHelper from "@/helpers/ListHelper";
	import ItemListFilter from "@/models/filter/ItemListFilter";
	import ItemListPreference from "@/models/list-preference/ItemListPreference";
	import FilterControl from "@/components/common/FilterControl.vue";
	import SortControl from "@/components/common/SortControl.vue";
	import ItemGroupAutocomplete from "@/components/item-group/ItemGroupAutocomplete.vue";
	import ItemAvatar from "@/components/item/ItemAvatar.vue";
	import IOption from "@/models/IOption";
	import ItemChipLinkRustlabs from "@/components/item/ItemChipLinkRustlabs.vue";

	@Component({
		components: {
			ItemChipLinkRustlabs,
			ItemAvatar,
			ItemGroupAutocomplete, SortControl, FilterControl, ReloadButton, ItemCard, ItemGroupChip
		}
	})
	export default class ItemList extends Vue {

		@Prop({type: String, default: null})
		public memoryKey!: string | null;

		@Prop({type: Number, default: null})
		public itemGroupId!: number;

		@Prop({type: Boolean, default: false})
		public hideItemGroupFilter!: boolean;

		@Prop({type: Boolean, default: false})
		public hideItemGroupColumn!: boolean;

		private readonly defaultSortBy: string[] = ['label'];
		private readonly defaultSortDesc: boolean[] = [false];

		private options: AsiListTableOptions = new AsiListTableOptions();
		private filter: ItemListFilter = new ItemListFilter();
		private preference: ItemListPreference = new ItemListPreference();

		private filterText: string | null = null;
		private filterTextTimeout: any = null;

		public created(): void {
			this.options.sortBy = this.defaultSortBy;
			this.options.sortDesc = this.defaultSortDesc;

			if (this.memoryKey !== null) {
				const options = this.$store.getters['ui/getListOptions'](this.memoryKey);
				if (options !== null) this.options = options;

				const filter = this.$store.getters['ui/getListFilter'](this.memoryKey);
				if (filter !== null) {
					Object.setPrototypeOf(filter, new ItemListFilter());
					this.$store.commit('ui/setListFilter', {key: this.memoryKey, filter: filter});
					this.filter = filter;
					this.filterText = filter.text;
				}

				const preference = this.$store.getters['ui/getListPreference'](this.memoryKey);
				if (preference !== null) this.preference = preference;
			}

			this.loadItems();
		}

		private get itemsPerPageOptions(): number[] {
			return ListHelper.itemsPerPageOptions;
		}

		private get nullableBooleanOptions(): IOption<boolean | null>[] {
			return ListHelper.nullableBooleanOptions;
		}

		private get items(): IItem[] {
			let relevantItems = this.$store.state.item.items;
			if (this.itemGroupId !== null) {
				relevantItems = relevantItems.filter((i: IItem) => i.group_id === this.itemGroupId);
			}

			return this.filter.applyFilter(relevantItems);
		}

		private get headers(): AsiListTableHeader[] {
			const ret = [
				new AsiListTableHeader('', 'avatar', false, false, 'center', '5rem'),
				new AsiListTableHeader('Label', 'label', true, true, 'start'),
				new AsiListTableHeader('Stack Size', 'stack_size', true, true, 'end', '10%'),
			];

			if (!this.hideItemGroupColumn) {
				ret.push(new AsiListTableHeader('Group', 'group', true, true, 'start', '15rem'));
			}

			return ret;
		}

		@Watch('options', {deep: true})
		private onOptionsChanged(value: AsiListTableOptions): void {
			if (this.memoryKey !== null) {
				this.$store.commit('ui/setListOptions', {key: this.memoryKey, options: value});
			}
		}

		@Watch('filter', {deep: true})
		private onFilterChanged(value: SkinListFilter): void {
			if (this.memoryKey !== null) {
				this.$store.commit('ui/setListFilter', {key: this.memoryKey, filter: value});
			}
			if (this.filterText !== value.text) {
				this.filterText = value.text;
			}
		}

		@Watch('filterText')
		private onFilterTextChanged(value: string | null): void {
			clearTimeout(this.filterTextTimeout);
			this.filterTextTimeout = setTimeout(() => {
				this.filter.text = value;
				console.log('changing after debouncing');
			}, value !== null && value.length > 0 ? 500 : 25);
		}

		@Watch('preference', {deep: true})
		private onPreferenceChanged(value: ItemListPreference): void {
			if (this.memoryKey !== null) {
				this.$store.commit('ui/setListPreference', {key: this.memoryKey, preference: value});
			}
		}

		private loadItems(force: boolean = false): void {
			this.$store.dispatch('item/loadItems', force);
		}

		private sortEntries(entries: IItem[], sortBy: string[], sortDesc: boolean[]): IItem[] {
			return ArrayHelper.multiSort<IItem>(entries, sortBy, sortDesc, new Map<string, MultiSortHandler<IItem>>([
				['label', (a, b, column, desc) => {
					return a.label.toLowerCase().localeCompare(b.label.toLowerCase()) * (desc ? -1 : 1);
				}],
				['group', (a, b, column, desc) => {
					const groupA = this.$store.getters['itemGroup/itemGroupById'](a.group_id)?.label.toLowerCase() ?? null;
					const groupB = this.$store.getters['itemGroup/itemGroupById'](b.group_id)?.label.toLowerCase() ?? null;
					if (groupA === null && groupB === null) return 0;
					if (groupA !== null && groupB === null) return -1;
					if (groupA === null && groupB !== null) return 1;
					return groupA.localeCompare(groupB) * (desc ? -1 : 1);
				}],
			]));
		}

		private showItem(id: number): void {
			const item = this.$store.getters['item/itemById'](id);
			if (item === null) return;
			this.$router.push({name: Routes.item, params: {id: item.id.toString()}});
		}

		private formatNumber(value: number): string {
			return (new Intl.NumberFormat('de-CH')).format(value);
		}

	}
