import {IInventoryEntry} from "@/models/IInventoryEntry";
import {State} from "@/store";
import {Module} from "vuex";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import Vue from "vue";
import IProgress from "@/models/IProgress";

export interface Inventory {
	loading: boolean,
	loaded: boolean,
	inventoryEntries: IInventoryEntry[];
	skinAmountMap: object,
	latestChange: number | null;
}

const inventory: Module<Inventory, State> = {
	namespaced: true,
	state: {
		loading: false,
		loaded: false,
		inventoryEntries: [] as IInventoryEntry[],
		skinAmountMap: Object.create(null),
		latestChange: null,
	},
	getters: {
		entryBySkinId: (state: Inventory) => (id: number): IInventoryEntry | null => {
			return state.inventoryEntries.find(e => e.skin_id === id) ?? null;
		},
		amountBySkinId: (state: Inventory) => (id: number): number => {
			// @ts-ignore
			return state.skinAmountMap[id] ?? 0;
		},
		ownsSkinId: (state: Inventory) => (id: number): boolean => {
			return state.inventoryEntries.some(entry => entry.skin_id === id);
		},
		numOwned: (state: Inventory, getters) => (skinIds: number[]) => {
			return skinIds.reduce((acc: number, cur: number) => {
				return acc + (getters['ownsSkinId'](cur) ? 1 : 0);
			}, 0);
		},
		ownsAll: (state: Inventory, getters) => (skinIds: number[]): boolean => {
			return !skinIds.some(id => !getters['ownsSkinId'](id));
		},
		skinProgress: (state: Inventory, getters) => (skinIds: number[]): IProgress => {
			const progress = (getters['numOwned'](skinIds) / skinIds.length) * 100;
			let color: string;
			if (progress === 0) {
				color = 'grey';
			} else if (progress === 100) {
				color = 'success';
			} else {
				color = 'info';
			}
			return {progress: progress, color: color} as IProgress;
		},
		ownedValueOfSkins: (state: Inventory, getters, rootState, rootGetters) => (skinIds: number[]): number => {
			return state.inventoryEntries
				.filter((e: IInventoryEntry) => e.skin_id !== null && skinIds.includes(e.skin_id))
				.reduce((acc: number, e: IInventoryEntry) => {
					const skin = rootGetters['skin/skinById'](e.skin_id);
					return skin === null ? acc : acc + (e.amount * (skin.price ?? 0));
				}, 0);
		},
		openValueOfSkins: (state: Inventory, getters, rootState, rootGetters) => (skinIds: number[]): number => {
			return skinIds.filter(id => !getters['ownsSkinId'](id))
				.reduce((acc: number, cur: number) => {
					const skin = rootGetters['skin/skinById'](cur);
					return skin === null ? acc : acc + (skin.price ?? 0);
				}, 0);
		},
		spareValueOfSkins: (state: Inventory, getters, rootState, rootGetters) => (skinIds: number[]): number => {
			return state.inventoryEntries
				.filter(e => e.skin_id !== null && skinIds.includes(e.skin_id) && e.amount > 1)
				.reduce((acc: number, cur: IInventoryEntry) => {
					const skin = rootGetters['skin/skinById'](cur.skin_id);
					return skin === null ? acc : acc + (skin.price ?? 0) * (cur.amount - 1);
				}, 0);
		},
		totalValue: (state: Inventory, getters, rootState: State, rootGetters): number => {
			return state.inventoryEntries.reduce((acc: number, cur: IInventoryEntry): number => {
				const skin = rootGetters['skin/skinById'];
				if (skin === null) return acc;
				return acc + cur.amount * (skin.price ?? 0);
			}, 0);
		},
	},
	mutations: {
		setLoading(state: Inventory, isLoading: boolean): void {
			state.loading = isLoading;
		},
		setLoaded(state: Inventory, isLoaded: boolean): void {
			state.loaded = isLoaded;
		},
		setInventoryEntries(state: Inventory, entries: IInventoryEntry[]): void {
			state.inventoryEntries = entries;
			state.skinAmountMap = entries.reduce((acc: object, cur: IInventoryEntry) => {
				if (cur.skin_id === null) return acc;
				Vue.set(acc, cur.skin_id, cur.amount);
				return acc;
			}, Object.create(null));
		},
		clearUserData(state: Inventory): void {
			state.inventoryEntries = [] as IInventoryEntry[];
			state.skinAmountMap = Object.create(null);
			state.loaded = false;
		},
		setLatestChange(state: Inventory, latestChange: number | null): void {
			state.latestChange = latestChange;
		},
	},
	actions: {
		async loadLatestChange(context) {
			const latestChange = await Vue.$inventoryEntryService.latestChange();
			context.commit('setLatestChange', latestChange);
			return latestChange;
		},
		async loadInventoryEntries(context, force: boolean = false) {
			if (context.state.loading) {
				console.log('loadInventoryEntries: already loading -> skip');
				return;
			}
			if (!force && context.state.loaded) {
				const latestChangeBefore = context.state.latestChange;
				const latestChangeCurrent = await context.dispatch('loadLatestChange');
				if (latestChangeBefore === latestChangeCurrent) {
					console.log('loadInventoryEntries: latest change checked and same as local -> skip');
					return;
				} else {
					console.log('loadInventoryEntries: latest change checked and NOT same as local!', latestChangeBefore, latestChangeCurrent);
				}
			}
			console.log(`loadInventoryEntries: loading (${force ? 'forced' : 'unforced'})`);

			const options = new AsiListTableOptions();
			options.page = 1;
			options.itemsPerPage = 0;

			context.commit('setLoading', true);
			try {
				const entries = (await Vue.$inventoryEntryService.inventoryEntries(options)).data;
				context.commit('setInventoryEntries', entries);
			} catch (e) {
				context.commit('setInventoryEntries', []);
				console.error('error while loading inventory entries', e);
			} finally {
				context.commit('setLoading', false);
				context.commit('setLoaded', true);
			}
		},
		async reimportInventory(context) {
			context.commit('setLoading', true);
			try {
				await Vue.$inventoryEntryService.reimport();
			} catch (e) {
				context.commit('setInventoryEntries', []);
				console.error('error while reimporting inventory entries', e);
			} finally {
				context.commit('setLoading', false);
			}
		},
	},

};

export default inventory;
