export type MultiSortHandler<T> = (a: T, b: T, column: string, desc: boolean) => number;

export default class ArrayHelper {

	public static multiSort<T>(data: T[], sortBy: string[], sortDesc: boolean[], columnHandlers: Map<string, MultiSortHandler<T>> = new Map()): T[] {
		if (sortBy.length === 0) return data;

		return data.sort((a: T, b: T) => {
			for (let i = 0; i < sortBy.length; i++) {
				const column = sortBy[i];
				const desc = sortDesc[i] ?? false;

				const handler: MultiSortHandler<T> = columnHandlers.get(column) ?? this.handleColumnDefault;
				//@ts-ignore
				const res = handler(a, b, column, desc);
				if (res !== 0) return res;
			}

			return 0;
		});
	}

	private static handleColumnDefault(a: any, b: any, column: string, desc: boolean): number {
		const valA = a[column];
		const valB = b[column];

		if (valA === valB) return 0;
		return desc ? (valB < valA ? -1 : 1) : (valA < valB ? -1 : 1);
	}

	public static identical<T>(a: T[], b: T[]): boolean {
		if (a.length !== b.length) return false;
		for (let i = 0; i < a.length; i++) {
			if (a[i] !== b[i]) return false;
		}
		return true;
	}

}
