import {Module} from "vuex";
import {State} from "@/store";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IListFilter from "@/models/filter/IListFilter";
import IListPreference from "@/models/list-preference/IListPreference";

export interface UI {
	listOptions: object;
	listFilters: object;
	listPreferences: object;
	home: {
		cardSize: number,
		selectedReleaseDate: string | null,
	},
	loadoutCardSize: number;
	craftCalculatorNotCraftedItemIds: number[],
	crawlJobLog: {
		selectedJob: string | null,
	},
	proxy: {
		selectedStates: number[],
	},
	showAvatars: boolean,
}

const ui: Module<UI, State> = {
	namespaced: true,
	state: {
		listOptions: {},
		listFilters: {},
		listPreferences: {},
		home: {
			cardSize: 9,
			selectedReleaseDate: null,
		},
		loadoutCardSize: 500,
		craftCalculatorNotCraftedItemIds: [] as number[],
		crawlJobLog: {
			selectedJob: null as string | null,
		},
		proxy: {
			selectedStates: [0],
		},
		showAvatars: true,
	},
	getters: {
		getListOptions: (state) => (key: string): AsiListTableOptions | null => {
			//@ts-ignore
			return state.listOptions[key] ?? null;
		},
		getListFilter: (state) => (key: string): IListFilter<any> | null => {
			//@ts-ignore
			return state.listFilters[key] ?? null;
		},
		getListPreference: (state) => (key: string): IListPreference | null => {
			//@ts-ignore
			return state.listPreferences[key] ?? null;
		},
		getCraftCalculatorIsItemCrafted: (state) => (itemId: number): boolean => {
			return state.craftCalculatorNotCraftedItemIds.includes(itemId);
		},
	},
	mutations: {
		setListOptions(state: UI, payload: { key: string; options: AsiListTableOptions }) {
			//@ts-ignore
			state.listOptions[payload.key] = payload.options;
		},
		setListFilter(state: UI, payload: { key: string; filter: IListFilter<any> }) {
			//@ts-ignore
			state.listFilters[payload.key] = payload.filter;
		},
		setListPreference(state: UI, payload: { key: string; preference: IListPreference }) {
			//@ts-ignore
			state.listPreferences[payload.key] = payload.preference;
		},
		clearListMemory(state: UI) {
			state.listOptions = {};
			state.listFilters = {};
			state.listPreferences = {};
		},
		setHomeCardSize(state: UI, size: number) {
			state.home.cardSize = size;
		},
		setHomeSelectedReleaseDate(state: UI, value: string | null) {
			state.home.selectedReleaseDate = value;
		},
		setLoadoutCardSize(state: UI, size: number) {
			state.loadoutCardSize = size;
		},
		setCrawlJobLogSelectedJob(state: UI, job: string | null) {
			state.crawlJobLog.selectedJob = job;
		},
		setProxySelectedStates(state: UI, states: number[]) {
			state.proxy.selectedStates = states;
		},
		setShowAvatars(state: UI, value: boolean) {
			state.showAvatars = value;
		},
	},
};

export default ui;
