export default class AsiListTableOptions {

	public sortBy: string[];
	public sortDesc: boolean[];
	public page: number;
	public itemsPerPage: number;

	public constructor(sortBy: string[] = [], sortDesc: boolean[] = [], page: number = 1, itemsPerPage: number = 20) {
		this.sortBy = sortBy;
		this.sortDesc = sortDesc;
		this.page = page;
		this.itemsPerPage = itemsPerPage;
	}

}
