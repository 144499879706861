
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import Loadout from "@/components/loadout/Loadout.vue";
	import ILoadout from "@/models/ILoadout";
	import LoadoutCreateDialog from "@/components/loadout/LoadoutCreateDialog.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import ReloadButton from "@/components/common/ReloadButton.vue";

	@Component({
		components: {ReloadButton, LoadoutCreateDialog, Loadout}
	})
	export default class LoadoutsView extends Vue {

		private loading: boolean = false;
		private createDialog: DialogHandler = new DialogHandler();
		private searchUsername: string | null = null;
		private searchLabel: string | null = null;

		public created(): void {
			this.loadLoadouts();
		}

		private get loadouts(): ILoadout[] {
			return this.$store.state.loadout.loadouts;
		}

		private get ownLoadouts(): ILoadout[] {
			return this.loadouts
				.filter(l => l.user_id === this.$store.state.user.userId)
				.sort((a: ILoadout, b: ILoadout) => {
					return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
				});
		}

		private get sharedLoadouts(): ILoadout[] {
			return this.loadouts
				.filter(l => {
					if (l.user_id === this.$store.state.user.userId) return false;
					if (this.searchLabel !== null && l.label.toLowerCase().indexOf(this.searchLabel.toLowerCase()) === -1) {
						return false;
					}
					if (this.searchUsername !== null && l.username !== null && l.username.toLowerCase().indexOf(this.searchUsername.toLowerCase()) === -1) {
						return false;
					}
					return true;
				})
				.sort((a: ILoadout, b: ILoadout) => {
					return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
				});
		}

		private get loadoutCardSize(): number {
			return this.$store.state.ui.loadoutCardSize;
		}

		private set loadoutCardSize(size: number) {
			this.$store.commit('ui/setLoadoutCardSize', size);
		}

		private loadLoadouts(): void {
			this.$store.dispatch('loadout/loadLoadouts');
		}

	}
