import ILoadoutService from "@/services/definition/ILoadoutService";
import ILoadout from "@/models/ILoadout";
import Vue from "vue";
import AxiosService from "@/services/AxiosService";

export default class AxiosLoadoutService extends AxiosService implements ILoadoutService {

	protected defineBaseUrl(): string {
		return 'loadout';
	}

	public loadouts(): Promise<ILoadout[]> {
		return Vue.$axios.get<ILoadout[]>(this.url('index'))
			.then(res => res.data);
	}

	public loadout(id: number): Promise<ILoadout> {
		return Vue.$axios.get<ILoadout>(this.url('view'), {
			params: {
				id: id,
			}
		}).then(res => res.data);
	}

	public create(label: string, description: string | null, isPublic: boolean): Promise<ILoadout> {
		return Vue.$axios.post<ILoadout>(this.url('create'), {
			label: label,
			description: description,
			is_public: isPublic,
		}).then(res => res.data);
	}

	public setLabel(id: number, label: string): Promise<null> {
		return Vue.$axios.patch<null>(this.url(`set-label`), {
			id: id,
			label: label,
		}).then(res => res.data);
	}

	public setDescription(id: number, description: string | null): Promise<null> {
		return Vue.$axios.patch<null>(this.url(`set-description`), {
			id: id,
			description: description,
		}).then(res => res.data);
	}

	public setIsPublic(id: number, isPublic: boolean): Promise<null> {
		return Vue.$axios.patch<null>(this.url(`set-is-public`), {
			id: id,
			is_public: isPublic,
		}).then(res => res.data);
	}

	public setSlot(id: number, type: number, position: number, data: { item_id: number; item_amount: number; description: string | null } | null): Promise<null> {
		return Vue.$axios.patch<null>(this.url(`set-slot`), {
			id: id,
			type: type,
			position: position,
			data: data,
		}).then(res => res.data);
	}

	public delete(id: number): Promise<null> {
		return Vue.$axios.delete(this.url('delete'), {
			params: {
				id: id
			},
		}).then(null);
	}

}
