import Vue from 'vue';
import Vuex from 'vuex';
import user, {User} from "@/store/modules/user";
import createPersistedState from "vuex-persistedstate";
import inventory, {Inventory} from "@/store/modules/inventory";
import item, {Item} from "@/store/modules/item";
import skin, {Skin} from "@/store/modules/skin";
import itemGroup, {ItemGroup} from "@/store/modules/itemgroups";
import kit, {Kit} from "@/store/modules/kit";
import ui, {UI} from "@/store/modules/ui";
import loadout, {Loadout} from "@/store/modules/loadout";
import craftCalculator, {CraftCalculator} from "@/store/modules/craftcalculator";

Vue.use(Vuex);

export interface State {
	user: User;
	itemGroup: ItemGroup;
	item: Item;
	skin: Skin;
	kit: Kit;
	inventory: Inventory;
	loadout: Loadout;
	ui: UI;
	craftCalculator: CraftCalculator;
}

const store = new Vuex.Store<State>({
	mutations: {},
	actions: {},
	modules: {
		user: user,
		itemGroup: itemGroup,
		item: item,
		skin: skin,
		kit: kit,
		inventory: inventory,
		loadout: loadout,
		ui: ui,
		craftCalculator: craftCalculator,
	},
	plugins: [
		createPersistedState({
			storage: window.localStorage,
			key: 'plm-skin-manager',
			reducer: (persistedState: State) => {
				const stateFilter = JSON.parse(JSON.stringify(persistedState)) as State;

				// @ts-ignore
				delete stateFilter.user.isRefreshingToken;
				// @ts-ignore
				delete stateFilter.user.isAxiosRefreshingToken;
				// @ts-ignore
				delete stateFilter.itemGroup.loading;
				// @ts-ignore
				delete stateFilter.item.loading;
				// @ts-ignore
				delete stateFilter.item.itemDataLoadingIds;
				// @ts-ignore
				delete stateFilter.item.itemData;
				// @ts-ignore
				delete stateFilter.skin.loading;
				// @ts-ignore
				delete stateFilter.skin.loadingFavourites;
				// @ts-ignore
				delete stateFilter.skin.workshopData;
				// @ts-ignore
				delete stateFilter.kit.loading;
				// @ts-ignore
				delete stateFilter.kit.loadingFavourites;
				// @ts-ignore
				delete stateFilter.inventory.loading;
				// @ts-ignore
				delete stateFilter.loadout;

				return stateFilter;
			}
		}),
	],
});

export default store;
