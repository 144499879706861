
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';

	@Component
	export default class CraftItemSwitch extends Vue {

		@Prop({type: Number, required: true})
		public itemId!: number;

		private get craftValue(): boolean {
			return this.$store.getters['craftCalculator/isItemCrafted'](this.itemId);
		}

		private set craftValue(craft: boolean) {
			this.$store.commit('craftCalculator/setItemCrafted', {itemId: this.itemId, craft: craft});
		}

	}
