
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import ILoadout from "@/models/ILoadout";

	@Component({})
	export default class LoadoutDescriptionDialog extends Vue {

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		@Prop({type: Object, required: true})
		public loadout!: ILoadout;

		private loading: boolean = false;
		private descriptionInternal: string | null = null;

		private get canSave(): boolean {
			return this.$store.getters['loadout/hasLoadoutPermission'](this.loadout.id);
		}

		private reset(): void {
			this.descriptionInternal = this.loadout.description;
		}

		private trySave(): void {
			if (!this.canSave) return;

			this.loading = true;
			this.$store.dispatch('loadout/setDescription', {id: this.loadout.id, description: this.descriptionInternal})
				.then(() => this.save(this.descriptionInternal ?? '?'))
				.finally(() => this.loading = false);
		}

		@Watch('loadout.description', {immediate: true})
		private onLoadoutDescriptionChanged(): void {
			this.reset();
		}

		@Watch('open')
		private onOpenChanged(value: boolean): void {
			if (!value) this.reset();
		}

		@Emit('cancel')
		public cancel(): void {
			//do nothing
		}

		@Emit('save')
		public save(description: string): string | null {
			return description;
		}

	}
