
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import {IKit} from "@/models/IKit";
	import SkinAutocomplete from "@/components/skin/SkinAutocomplete.vue";
	import SkinAvatar from "@/components/skin/SkinAvatar.vue";

	@Component({
		components: {SkinAvatar, SkinAutocomplete}
	})
	export default class KitRenameDialog extends Vue {

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		@Prop({type: Object, required: true})
		public kit!: IKit;

		private loading: boolean = false;
		private labelInternal: string | null = null;

		private get global(): boolean {
			return this.kit.user_id === null;
		}

		private get isAdmin(): boolean {
			return this.$store.getters['user/isAdmin']();
		}

		private get canSave(): boolean {
			return this.labelInternal !== null && this.labelInternal.trim().length > 0 && this.labelInternal !== this.kit.label
				&& ((this.global && this.isAdmin) || this.$store.state.user.userId !== null);
		}

		private reset(): void {
			this.labelInternal = this.kit.label;
		}

		private trySave(): void {
			if (!this.canSave || this.labelInternal === null) return;

			this.loading = true;
			this.$store.dispatch('kit/rename', {id: this.kit.id, label: this.labelInternal})
				.then(() => this.save(this.labelInternal ?? '?'))
				.finally(() => this.loading = false);
		}

		@Watch('kit.label', {immediate: true})
		private onKitLabelChanged(): void {
			this.reset();
		}

		@Watch('open')
		private onOpenChanged(value: boolean): void {
			if (!value) this.reset();
		}

		@Emit('cancel')
		public cancel(): void {
			//do nothing
		}

		@Emit('save')
		public save(label: string): string {
			return label;
		}

	}
