import { render, staticRenderFns } from "./PropertyBlockEntry.vue?vue&type=template&id=5bd0b509&scoped=true&"
import script from "./PropertyBlockEntry.vue?vue&type=script&lang=ts&"
export * from "./PropertyBlockEntry.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bd0b509",
  null
  
)

export default component.exports