
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import {ISkin} from "@/models/ISkin";
	import ISkinIconUrls from "@/models/ISkinIconUrls";

	@Component
	export default class SkinSetIconUrlDialog extends Vue {

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		@Prop({type: Object, required: true})
		public skin!: ISkin;

		public loading: boolean = false;
		public iconUrls: ISkinIconUrls | null = null;
		public iconUrl: string | null = null;

		@Watch('skin.has_icon')
		private onSkinHasIconChanged(): void {
			this.loadIconUrls();
		}

		@Watch('open', {immediate: true})
		private onOpenChanged(value: boolean): void {
			if (value) {
				this.loadIconUrls();
			} else {
				this.iconUrls = null;
				this.iconUrl = null;
			}
		}

		@Emit('cancel')
		public cancel(): void {
			//do nothing
		}

		@Emit('save')
		public save(url: string | null): string | null {
			return url;
		}

		private loadIconUrls(): void {
			this.loading = true;
			this.$skinService.iconUrls(this.skin.id)
				.then(data => {
					this.iconUrls = data;
					this.iconUrl = this.iconUrls.icon_url;
				})
				.catch(err => console.error(err))
				.finally(() => this.loading = false);
		}

		public trySave(): void {
			this.loading = true;
			this.$store.dispatch('skin/setIconUrl', {id: this.skin.id, url: this.iconUrl})
				.then(() => this.save(this.iconUrl))
				.finally(() => this.loading = false);
		}

	}
