
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';

	@Component
	export default class ResponsiveNav extends Vue {

		@Prop({type: Boolean, default: false})
		public mobileMode!: boolean;

	}
