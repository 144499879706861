
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {ISkin} from "@/models/ISkin";
	import SkinTradeableIcon from "@/components/skin/SkinTradeableIcon.vue";

	@Component({
		components: {SkinTradeableIcon}
	})
	export default class SkinTradeableChip extends Vue {

		@Prop({type: Object, required: true})
		public skin!: ISkin;

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public xSmall!: boolean;

		private get color(): string {
			switch (this.skin.tradeable) {
				case true:
					return 'success';
				case false:
					return 'error';
				case null:
				default:
					return 'grey';

			}
		}

		private get icon(): string {
			return this.skin.tradeable === null ? 'mdi-help' : 'mdi-cart';
		}

	}
