
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';

	@Component
	export default class KitFavouriteButton extends Vue {

		@Prop({type: Number, required: true})
		public id!: number;

		private loading: boolean = false;

		private get isFavourite(): boolean {
			return this.$store.getters['kit/isFavourite'](this.id);
		}

		private get loadingFavourites(): boolean {
			return this.$store.state.kit.loadingFavourites;
		}

		private toggleFavourite(): void {
			this.loading = true;
			this.$store.dispatch('kit/setFavourite', {id: this.id, isFavourite: !this.isFavourite})
				.finally(() => this.loading = false);
		}

	}
