
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {ISkinPricingEntry} from "@/models/ISkinPricingEntry";
	import {Line as LineChart} from 'vue-chartjs';
	import {
		CategoryScale,
		Chart as ChartJS,
		Legend,
		LinearScale,
		LineElement,
		PointElement,
		TimeScale,
		Title,
		Tooltip
	} from 'chart.js';
	import 'chartjs-adapter-date-fns';
	//@ts-ignore
	import chartTrendline from 'chartjs-plugin-trendline';
	import zoomPlugin from 'chartjs-plugin-zoom';

	ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, TimeScale, PointElement, CategoryScale);
	ChartJS.register(chartTrendline);
	ChartJS.register(zoomPlugin);

	@Component({
		components: {LineChart}
	})
	export default class SkinPricingHistoryChart extends Vue {

		@Prop({type: Array, required: true})
		public pricingEntries!: ISkinPricingEntry[];

		private get entriesSorted(): ISkinPricingEntry[] {
			return this.pricingEntries.map(e => e).sort((a, b) => a.created_at - b.created_at);
		}

		// private get rollingAverageBuy(): { x: number, y: number }[] {
		// 	return this.entriesSorted
		// 		.reduce((acc: { x: number, ySum: number, num: number }[], cur: ISkinPricingEntry, index: number, arr: ISkinPricingEntry[]) => {
		// 			let curIndex = index;
		// 			let curDate = new Date(cur.created_at * 1000);
		// 			curDate.setHours(12, 0, 0, 0);
		//
		// 			const cutoffDate = new Date(curDate);
		// 			cutoffDate.setDate(cutoffDate.getDate() - 3);
		//
		// 			let entry = acc.find(e => e.x === curDate.getTime());
		// 			if (entry === undefined) {
		// 				entry = {x: curDate.getTime(), ySum: 0, num: 0};
		// 				acc.push(entry);
		// 			}
		//
		// 			while (curIndex >= 0 && curDate >= cutoffDate) {
		// 				const c = arr[curIndex];
		// 				curDate = new Date(c.created_at * 1000);
		// 				curDate.setHours(12, 0, 0, 0);
		//
		// 				entry.ySum += c.buy0 ?? 0;
		// 				entry.num++;
		//
		// 				curIndex--;
		// 			}
		//
		// 			return acc;
		// 		}, [])
		// 		.map(e => {
		// 			return {
		// 				x: e.x,
		// 				y: e.ySum / e.num,
		// 			};
		// 		});
		// }

		private get chartData(): object {
			const threeMonthCutoffDate = new Date();
			threeMonthCutoffDate.setDate(threeMonthCutoffDate.getDate() - 90);

			return {
				datasets: [
					{
						label: 'Lowest Sale Offer',
						lineTension: 0,
						backgroundColor: '#f00',
						borderColor: '#955',
						borderWidth: 2,
						data: this.entriesSorted.map(e => {
							return {
								x: e.created_at * 1000,
								y: e.sell0,
							};
						}),
						trendlineLinear: {
							colorMin: '#99555599',
							colorMax: '#99555599',
							lineStyle: 'dotted',
							width: 2,
							projection: false,
						},
					},
					{
						label: 'Lowest Sale Offer (3 Months)',
						lineTension: 0,
						backgroundColor: 'transparent',
						borderColor: 'transparent',
						borderWidth: 2,
						data: this.entriesSorted
							.filter(e => new Date(e.created_at * 1000) > threeMonthCutoffDate)
							.map(e => {
								return {
									x: e.created_at * 1000,
									y: e.sell0,
								};
							}),
						trendlineLinear: {
							colorMin: '#995555ff',
							colorMax: '#995555ff',
							lineStyle: 'dotted',
							width: 2,
							projection: false,
						},
					},
					{
						label: 'Highest Buy Order',
						lineTension: 0,
						backgroundColor: '#0f09',
						borderColor: '#595',
						borderWidth: 2,
						data: this.entriesSorted.map(e => {
							return {
								x: e.created_at * 1000,
								y: e.buy0,
							};
						}),
						trendlineLinear: {
							colorMin: '#55995599',
							colorMax: '#55995599',
							lineStyle: 'dotted',
							width: 2,
							projection: false,
						},
					},
					{
						label: 'Highest Buy Order (3 Months)',
						lineTension: 0,
						backgroundColor: 'transparent',
						borderColor: 'transparent',
						borderWidth: 2,
						data: this.entriesSorted
							.filter(e => new Date(e.created_at * 1000) > threeMonthCutoffDate)
							.map(e => {
								return {
									x: e.created_at * 1000,
									y: e.buy0,
								};
							}),
						trendlineLinear: {
							colorMin: '#559955ff',
							colorMax: '#559955ff',
							lineStyle: 'dotted',
							width: 2,
							projection: false,
						},
					},
					// {
					// 	label: 'Rolling Average Buy',
					// 	lineTension: 0,
					// 	backgroundColor: '#0088cc99',
					// 	borderColor: '#0088cc',
					// 	borderWidth: 1,
					// 	lineStyle: 'dashed',
					// 	data: this.rollingAverageBuy,
					// }
				],
			};
		}

		private get chartOptions(): object {
			return {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					x: {
						//display: false,
						type: 'time',
						time: {
							//https://date-fns.org/docs/format
							unit: 'hour',
							isoWeekday: true,
							displayFormats: {
								day: 'dd.MM. HH:mm',
								hour: 'dd.MM. HH:mm',
							},
							tooltipFormat: 'EE dd.MM.yyyy HH:mm:ss',
						},
						min: this.entriesSorted.reduce((acc: number, cur: ISkinPricingEntry) => {
							return cur.created_at < acc ? cur.created_at : acc;
						}, Number.MAX_VALUE) * 1000 - 3600000,
						max: (new Date()).getTime() + 3600000,
						ticks: {
							//maxRotation: 0,
							callback: function (value: string) {
								return ['00:', '03:', '06:', '09:', '12:', '15:', '18:', '21:']
									.some(h => value.indexOf(h) > -1) ? value : '';
							}
						},
					},
					y: {
						ticks: {
							callback: function (value: number) {
								return '$ ' + value.toFixed(2);
							},
						},
					},
				},
				plugins: {
					legend: {
						display: false,
					},
					zoom: {
						zoom: {
							wheel: {
								enabled: true,
								modifierKey: 'ctrl',
							},
							pinch: {
								enabled: true
							},
							mode: 'x',
						},
						pan: {
							enabled: true,
							mode: 'x',
						},
					},
				},
			};
		}

	}
