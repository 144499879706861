import IListPreference from "@/models/list-preference/IListPreference";

export default class ItemListPreference implements IListPreference {

	public static readonly defaultImgSize: number = 38;
	public static readonly defaultCardSize: number = 13;

	public cardView: boolean = false;
	public imgSize: number = ItemListPreference.defaultImgSize;
	public cardSize: number = ItemListPreference.defaultCardSize;

	public reset(): void {
		this.cardView = false;
		this.imgSize = ItemListPreference.defaultImgSize;
		this.cardSize = ItemListPreference.defaultCardSize;
	}

}
