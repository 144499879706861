
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import SkinAutocomplete from "@/components/skin/SkinAutocomplete.vue";
	import SkinAvatar from "@/components/skin/SkinAvatar.vue";
	import {ISkin} from "@/models/ISkin";
	import ItemAutocomplete from "@/components/item/ItemAutocomplete.vue";

	@Component({
		components: {ItemAutocomplete, SkinAvatar, SkinAutocomplete}
	})
	export default class SkinSetPriceItemStoreDialog extends Vue {

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		@Prop({type: Object, required: true})
		public skin!: ISkin;

		private loading: boolean = false;
		private priceItemStore: number | null = null;

		private reset(): void {
			this.priceItemStore = this.skin.price_item_store;
		}

		private trySave(): void {
			this.loading = true;
			this.$store.dispatch('skin/setPriceItemStore', {id: this.skin.id, priceItemStore: this.priceItemStore})
				.then(() => this.save(this.priceItemStore))
				.finally(() => this.loading = false);
		}

		@Watch('skin.price_item_store', {immediate: true})
		private onSkinPriceItemStoreChanged(): void {
			this.reset();
		}

		@Watch('open')
		private onOpenChanged(value: boolean): void {
			if (!value) this.reset();
		}

		@Emit('cancel')
		public cancel(): void {
			//do nothing
		}

		@Emit('save')
		public save(priceItemStore: number | null): number | null {
			return priceItemStore;
		}

	}
