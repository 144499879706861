
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {ISkin} from "@/models/ISkin";
	import CurrencyHelper from "@/helpers/CurrencyHelper";
	import ProfitValues from "@/components/common/ProfitValues.vue";

	@Component({
		components: {ProfitValues}
	})
	export default class SkinPriceChip extends Vue {

		@Prop({type: Object, required: true})
		public skin!: ISkin;

		@Prop({type: Boolean, default: false})
		public noReload!: boolean;

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public xSmall!: boolean;

		@Prop({type: String, default: null})
		public color!: string;

		@Prop({type: Boolean, default: false})
		public noTooltip!: boolean;

		@Prop({type: Boolean, default: false})
		public tooltipLeft!: boolean;

		@Prop({type: Boolean, default: false})
		public tooltipRight!: boolean;

		private get showTooltip(): boolean {
			return !this.noTooltip && this.skin.tradeable === true && this.skin.price !== null && this.skin.price_item_store !== null;
		}

		private get key(): string {
			return `${this.skin.id}-${this.skin.price?.toFixed(2) ?? '?'}`;
		}

		private get loading(): boolean {
			return this.$store.getters['skin/isSkinLoading'](this.skin.id);
		}

		private get disabled(): boolean {
			return this.$store.state.skin.loading;
		}

		private get profitPercent(): number | null {
			return this.skin.price_item_store === null || this.skin.price === null
				? null
				: CurrencyHelper.profitPercent(this.skin.price_item_store, this.skin.price);
		}

		private get trendIconData(): { color: string | null, rotation: number } | null {
			if (!this.skin.tradeable) return null;
			const percent = this.profitPercent;
			if (percent === null) return null;

			const ret = {color: null, rotation: 0} as { color: string | null, rotation: number };

			if (percent >= 20) {
				ret.color = 'success';
			} else if (percent <= -20) {
				ret.color = 'error';
			}

			if (percent >= 100) {
				ret.rotation = 90;
			} else if (percent <= -100) {
				ret.rotation = -90;
			} else {
				ret.rotation = percent / 100 * 90;
			}
			ret.rotation *= -1;

			return ret;
		}

		private reloadPrice(): void {
			this.$store.dispatch('skin/loadMarketDetail', this.skin.id);
		}

	}
