import {IItemGroup} from "@/models/IItemGroup";
import store from "@/store";
import ItemHelper from "@/helpers/ItemHelper";

export default class ItemGroupHelper {

	public static iconUrl(itemGroup: IItemGroup): string | null {
		const item = store.state.item.items.find(i => {
			return i.has_icon && i.group_id === itemGroup.id;
		});

		return item === undefined ? null : ItemHelper.iconUrl(item);
	}

}
