import {State} from "@/store";
import {Module} from "vuex";
import Vue from "vue";
import ILoadout from "@/models/ILoadout";

export interface Loadout {
	loading: boolean,
	loaded: boolean,
	loadingLoadoutIds: number[],
	loadouts: ILoadout[],
}

const loadout: Module<Loadout, State> = {
	namespaced: true,
	state: {
		loading: false,
		loaded: false,
		loadingLoadoutIds: [] as number[],
		loadouts: [] as ILoadout[],
	},
	getters: {
		loadoutById: (state: Loadout) => (id: number): ILoadout | null => {
			return state.loadouts.find(i => i.id === id) ?? null;
		},
		loadoutsByIds: (state: Loadout) => (ids: number[]): ILoadout[] => {
			return state.loadouts.filter(l => ids.includes(l.id));
		},
		isLoadoutLoading: (state: Loadout) => (id: number): boolean => {
			return state.loadingLoadoutIds.includes(id);
		},
		hasLoadoutPermission: (state: Loadout, getters, rootState, rootGetters) => (id: number): boolean => {
			const userId = rootState.user.userId;
			const loadout = getters['loadoutById'](id);
			if (userId === null || loadout === null) return false;
			const isAdmin = rootGetters['user/isAdmin'](userId);
			return loadout.user_id === userId || isAdmin;
		}
	},
	mutations: {
		setLoading(state: Loadout, isLoading: boolean): void {
			state.loading = isLoading;
		},
		setLoaded(state: Loadout, isLoaded: boolean): void {
			state.loaded = isLoaded;
		},
		setLoadoutLoading(state: Loadout, payload: { id: number, isLoading: boolean }): void {
			const isIncluded = state.loadingLoadoutIds.includes(payload.id);
			if (payload.isLoading && !isIncluded) {
				state.loadingLoadoutIds.push(payload.id);
			} else if (!payload.isLoading && isIncluded) {
				state.loadingLoadoutIds = state.loadingLoadoutIds.filter(id => id !== payload.id);
			}
		},
		setLoadouts(state: Loadout, entries: ILoadout[]): void {
			state.loadouts = entries;
		},
		setLoadout(state: Loadout, loadout: ILoadout): void {
			const index = state.loadouts.findIndex(l => l.id === loadout.id);
			state.loadouts.splice(
				index < 0 ? state.loadouts.length + 1 : index,
				index < 0 ? 0 : 1,
				loadout,
			);
		},
		removeLoadout(state: Loadout, id: number): void {
			state.loadouts = state.loadouts.filter(l => l.id !== id);
		},
	},
	actions: {
		async loadLoadouts(context) {
			if (context.state.loading) {
				console.log('loadLoadouts: already loading -> skip');
				return;
			}

			console.log(`loadLoadouts: loading`);

			context.commit('setLoading', true);
			try {
				context.commit('setLoadouts', await Vue.$loadoutService.loadouts());
			} catch (e) {
				context.commit('setLoadouts', []);
				console.error('error while loading loadouts', e);
			} finally {
				context.commit('setLoading', false);
				context.commit('setLoaded', true);
			}
		},
		async loadLoadout(context, id: number) {
			context.commit('setLoadoutLoading', {id: id, isLoading: true});
			try {
				const loadout = await Vue.$loadoutService.loadout(id);
				context.commit('setLoadout', loadout);
			} catch (e) {
				console.error(`error while loading loadout ${id}`, e);
			} finally {
				context.commit('setLoadoutLoading', {id: id, isLoading: false});
			}
		},
		async create(context, payload: { label: string, description: string | null, isPublic: boolean }) {
			try {
				const model = await Vue.$loadoutService.create(payload.label, payload.description, payload.isPublic);
				await context.dispatch('loadLoadout', model.id);
				return Promise.resolve(model);
			} catch (e) {
				console.error(`error while creating loadout ${payload.label}`, e);
			}
		},
		async delete(context, id: number) {
			try {
				await Vue.$loadoutService.delete(id);
				context.commit('removeLoadout', id);
			} catch (e) {
				console.error(`error while deleting loadout ${id}`, e);
			}
		},
		async setLabel(context, payload: { id: number, label: string }) {
			context.commit('setLoadoutLoading', {id: payload.id, isLoading: true});
			try {
				await Vue.$loadoutService.setLabel(payload.id, payload.label);
				await context.dispatch('loadLoadout', payload.id);
			} catch (e) {
				console.error(`error while setting loadout label ${payload.id}`, e);
			} finally {
				context.commit('setLoadoutLoading', {id: payload.id, isLoading: false});
			}
		},
		async setDescription(context, payload: { id: number, description: string | null }) {
			context.commit('setLoadoutLoading', {id: payload.id, isLoading: true});
			try {
				await Vue.$loadoutService.setDescription(payload.id, payload.description);
				await context.dispatch('loadLoadout', payload.id);
			} catch (e) {
				console.error(`error while setting loadout description ${payload.id}`, e);
			} finally {
				context.commit('setLoadoutLoading', {id: payload.id, isLoading: false});
			}
		},
		async setIsPublic(context, payload: { id: number, isPublic: boolean }) {
			context.commit('setLoadoutLoading', {id: payload.id, isLoading: true});
			try {
				await Vue.$loadoutService.setIsPublic(payload.id, payload.isPublic);
				await context.dispatch('loadLoadout', payload.id);
			} catch (e) {
				console.error(`error while setting loadout is public ${payload.id}`, e);
			} finally {
				context.commit('setLoadoutLoading', {id: payload.id, isLoading: false});
			}
		},
		async setSlot(context, payload: { id: number, type: number, position: number, data: { item_id: number, item_amount: number, description: string | null } | null }) {
			context.commit('setLoadoutLoading', {id: payload.id, isLoading: true});
			try {
				await Vue.$loadoutService.setSlot(payload.id, payload.type, payload.position, payload.data);
				await context.dispatch('loadLoadout', payload.id);
			} catch (e) {
				console.error(`error while setting slot of loadout ${payload.id} (type: ${payload.type}, pos: ${payload.position}): ${payload.data}`, e);
			} finally {
				context.commit('setLoadoutLoading', {id: payload.id, isLoading: false});
			}
		},
	},

};

export default loadout;
