
	import Vue from 'vue';
	import {Component, Prop, Watch} from 'vue-property-decorator';
	import AsiListTableHeader from "@/components/common/AsiListTableHeader";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import ArrayHelper, {MultiSortHandler} from "@/helpers/ArrayHelper";
	import ReloadButton from "@/components/common/ReloadButton.vue";
	import SkinListFilter from "@/models/filter/SkinListFilter";
	import ListHelper from "@/helpers/ListHelper";
	import FilterControl from "@/components/common/FilterControl.vue";
	import SortControl from "@/components/common/SortControl.vue";
	import UserListFilter from "@/models/filter/UserListFilter";
	import {IUser} from "@/models/IUser";
	import UserListPreference from "@/models/list-preference/UserListPreference";
	import DateHelper from "@/helpers/DateHelper";
	import UserUpdatePasswordDialog from "@/components/user/UserUpdatePasswordDialog.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
	import UserCreateDialog from "@/components/user/UserCreateDialog.vue";
	import UserUpdateDialog from "@/components/user/UserUpdateDialog.vue";

	@Component({
		components: {
			UserUpdateDialog,
			UserCreateDialog,
			ConfirmDialog,
			UserUpdatePasswordDialog,
			SortControl, FilterControl, ReloadButton
		}
	})
	export default class UserList extends Vue {

		@Prop({type: String, default: null})
		public memoryKey!: string | null;

		private readonly defaultSortBy: string[] = ['username'];
		private readonly defaultSortDesc: boolean[] = [false];

		private usersInternal: IUser[] = [];
		private loading: boolean = false;
		private options: AsiListTableOptions = new AsiListTableOptions();
		private filter: UserListFilter = new UserListFilter();
		private preference: UserListPreference = new UserListPreference();

		private filterText: string | null = null;
		private filterTextTimeout: any = null;

		private selectedUser: IUser | null = null;
		private createDialog: DialogHandler = new DialogHandler();
		private updateDialog: DialogHandler = new DialogHandler(() => this.selectedUser = null);
		private updatePasswordDialog: DialogHandler = new DialogHandler(() => this.selectedUser = null);

		public created(): void {
			this.options.sortBy = this.defaultSortBy;
			this.options.sortDesc = this.defaultSortDesc;

			if (this.memoryKey !== null) {
				const options = this.$store.getters['ui/getListOptions'](this.memoryKey);
				if (options !== null) this.options = options;

				const filter = this.$store.getters['ui/getListFilter'](this.memoryKey);
				if (filter !== null) {
					Object.setPrototypeOf(filter, new UserListFilter());
					this.$store.commit('ui/setListFilter', {key: this.memoryKey, filter: filter});
					this.filter = filter;
					this.filterText = filter.text;
				}

				const preference = this.$store.getters['ui/getListPreference'](this.memoryKey);
				if (preference !== null) this.preference = preference;
			}

			this.loadUsers();
		}

		private get itemsPerPageOptions(): number[] {
			return ListHelper.itemsPerPageOptions;
		}

		private get users(): IUser[] {
			return this.filter.applyFilter(this.usersInternal);
		}

		private get headers(): AsiListTableHeader[] {
			return [
				new AsiListTableHeader('ID', 'id', true, true, 'center', '5rem'),
				new AsiListTableHeader('Steam ID', 'steam_id', true, true, 'start', '12rem'),
				new AsiListTableHeader('Username', 'username', true, true, 'start'),
				new AsiListTableHeader('Active', 'is_active', true, true, 'center', '7rem'),
				new AsiListTableHeader('Inventory', 'inventory', true, true, 'center', '10%'),
				new AsiListTableHeader('Created', 'created_at', true, true, 'center', '10%'),
				new AsiListTableHeader('Updated', 'updated_at', true, true, 'center', '10%'),
				new AsiListTableHeader('', 'actions', false, false, 'end', '8rem'),
			];
		}

		@Watch('options', {deep: true})
		private onOptionsChanged(value: AsiListTableOptions): void {
			if (this.memoryKey !== null) {
				this.$store.commit('ui/setListOptions', {key: this.memoryKey, options: value});
			}
		}

		@Watch('filter', {deep: true})
		private onFilterChanged(value: SkinListFilter): void {
			if (this.memoryKey !== null) {
				this.$store.commit('ui/setListFilter', {key: this.memoryKey, filter: value});
			}
			if (this.filterText !== value.text) {
				this.filterText = value.text;
			}
		}

		@Watch('filterText')
		private onFilterTextChanged(value: string | null): void {
			clearTimeout(this.filterTextTimeout);
			this.filterTextTimeout = setTimeout(() => {
				this.filter.text = value;
				console.log('changing after debouncing');
			}, value !== null && value.length > 0 ? 500 : 25);
		}

		@Watch('preference', {deep: true})
		private onPreferenceChanged(value: UserListPreference): void {
			if (this.memoryKey !== null) {
				this.$store.commit('ui/setListPreference', {key: this.memoryKey, preference: value});
			}
		}

		private sortEntries(entries: IUser[], sortBy: string[], sortDesc: boolean[]): IUser[] {
			return ArrayHelper.multiSort<IUser>(entries, sortBy, sortDesc, new Map<string, MultiSortHandler<IUser>>([
				['username', (a, b, column, desc) => {
					return a.username.toLowerCase().localeCompare(b.username.toLowerCase()) * (desc ? -1 : 1);
				}],
				['steam_id', (a, b, column, desc) => {
					return (b.steam_id - a.steam_id) * (desc ? -1 : 1);
				}],
			]));
		}

		private formatDateTime(unixTimestamp: number | null): string | null {
			if (unixTimestamp === null) return null;
			return DateHelper.formatDateTime(unixTimestamp, true);
		}

		private loadUsers(): void {
			const options = new AsiListTableOptions();
			options.itemsPerPage = 0;
			options.page = 1;

			this.loading = true;
			this.$userService.users(options)
				.then(data => this.usersInternal = data.data)
				.catch(err => console.error(err))
				.finally(() => this.loading = false);
		}

		private toggleActive(user: IUser): void {
			this.loading = true;

			const promise: Promise<null> = user.is_active
				? this.$userService.deactivate(user.id)
				: this.$userService.activate(user.id);

			promise
				.then(() => this.loadUsers())
				.catch(err => console.error(err))
				.finally(() => this.loading = false);
		}

		private requestDelete(id: number): void {
			const confirm = this.$refs.confirm as unknown as ConfirmDialog;
			confirm.openDialog().then((res: boolean) => {
				if (!res) return;

				this.loading = true;
				this.$userService.delete(id)
					.then(() => this.loadUsers())
					.catch(err => console.error(err))
					.finally(() => this.loading = false);
			});
		}

	}
