
	import Vue from 'vue';
	import {Component, Watch} from 'vue-property-decorator';
	import IProxy from "@/models/IProxy";
	import ReloadButton from "@/components/common/ReloadButton.vue";
	import AsiListTableHeader from "@/components/common/AsiListTableHeader";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import ListHelper from "@/helpers/ListHelper";
	import DateHelper from "@/helpers/DateHelper";

	@Component({
		components: {ReloadButton}
	})
	export default class ProxiesView extends Vue {

		private loading: boolean = false;
		private proxies: IProxy[] = [] as IProxy[];
		private options: AsiListTableOptions = new AsiListTableOptions(['state', 'last_used_at'], [false, true]);
		private expanded: any[] = [];

		public created(): void {
			this.loadProxies();
		}

		private get selectedStates(): number[] {
			return this.$store.state.ui.proxy.selectedStates;
		}

		private set selectedStates(states: number[]) {
			this.$store.commit('ui/setProxySelectedStates', states);
		}

		private get headers(): AsiListTableHeader[] {
			return [
				new AsiListTableHeader('ID', 'id', true, true, 'center', '5rem'),
				new AsiListTableHeader('Last Used', 'last_used_at', true, true, 'center', '15rem'),
				new AsiListTableHeader('Source', 'source', true, true, 'start', '10%'),
				new AsiListTableHeader('Protocol', 'protocol', true, true, 'start', '10%'),
				new AsiListTableHeader('Address', 'address', true, true, 'start'),
				new AsiListTableHeader('Port', 'port', true, true, 'center', '10%'),
				new AsiListTableHeader('State', 'state', true, true, 'center', '7rem'),
			];
		}

		private get itemsPerPageOptions(): number[] {
			return ListHelper.itemsPerPageOptions;
		}

		@Watch('selectedStates')
		private onSelectedStatesChanged(): void {
			this.loadProxies();
		}

		private loadProxies(): void {
			this.loading = true;
			this.$proxyService.proxies(this.selectedStates)
				.then(data => this.proxies = data)
				.catch(() => this.proxies = [])
				.finally(() => this.loading = false);
		}

		private toggleState(id: number): void {
			const proxy = this.proxies.find(p => p.id === id) as IProxy | undefined;
			if (proxy === undefined) return;

			this.loading = true;
			this.$proxyService.setState(id, proxy.state === 0 ? 1 : 0)
				.then(this.loadProxies)
				.finally(() => this.loading = false);
		}

		private formatDateTime(unixTimestamp: number | null): string | null {
			if (unixTimestamp === null) return null;
			return DateHelper.formatDateTime(unixTimestamp, true);
		}

		private toggleExpanded(proxy: IProxy): void {
			if (this.expanded.some(p => p.id === proxy.id)) {
				this.expanded = this.expanded.filter(p => p.id !== proxy.id);
			} else {
				this.expanded.push(proxy);
			}
		}

	}
