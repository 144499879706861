
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {IItemGroup} from "@/models/IItemGroup";
	import ItemGroupHelper from "@/helpers/ItemGroupHelper";
	import {Routes} from "@/helpers/Routes";

	@Component
	export default class ItemGroupChip extends Vue {

		@Prop({type: [String, Number], required: true})
		public id!: string | number;

		private get itemGroup(): IItemGroup | null {
			return this.$store.getters['itemGroup/itemGroupById'](this.id);
		}

		private get iconUrl(): string | null {
			return this.itemGroup === null ? null : ItemGroupHelper.iconUrl(this.itemGroup);
		}

		private showItemGroup(): void {
			this.$router.push({name: Routes.itemGroup, params: {id: this.id.toString()}});
		}

	}
