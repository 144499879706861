
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import ItemList from "@/components/item/ItemList.vue";

	@Component({
		components: {ItemList}
	})
	export default class ItemsView extends Vue {

	}
