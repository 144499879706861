
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import IOption from "@/models/IOption";
	import CrawlJobLogs from "@/components/crawl-job-log/CrawlJobLogs.vue";

	@Component({
		components: {CrawlJobLogs}
	})
	export default class CrawlJobLogsView extends Vue {

		private loadingEntries: boolean = false;
		private loadingJobOptions: boolean = false;
		private jobOptionsInternal: string[] = [];
		private textFilter: string | null = null;

		public created(): void {
			this.loadJobOptions();
		}

		private get selectedJob(): string | null {
			return this.$store.state.ui.crawlJobLog.selectedJob;
		}

		private set selectedJob(job: string | null) {
			this.$store.commit('ui/setCrawlJobLogSelectedJob', job);
		}

		private get jobOptions(): IOption<string>[] {
			return this.jobOptionsInternal.map(j => {
				return {
					text: this.finalizeJobName(j),
					value: j,
				};
			});
		}

		private loadJobOptions(): void {
			this.loadingJobOptions = true;
			this.$crawlJobLogService.jobs()
				.then(data => this.jobOptionsInternal = data)
				.catch(err => console.error(err))
				.finally(() => this.loadingJobOptions = false);
		}

		private finalizeJobName(job: string): string {
			return job
				.split('-')
				.map(p => p.substring(0, 1).toUpperCase() + p.substring(1))
				.join(' ');
		}

	}
