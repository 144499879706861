
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import {IKit} from "@/models/IKit";

	@Component
	export default class KitCreateDialog extends Vue {

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		private loading: boolean = false;
		private label: string | null = null;
		private global: boolean = false;

		private get isAdmin(): boolean {
			return this.$store.getters['user/isAdmin']();
		}

		private get canSave(): boolean {
			return this.label !== null && this.label.trim().length > 0
				&& ((this.global && this.isAdmin) || this.$store.state.user.userId !== null);
		}

		private reset(): void {
			this.label = null;
		}

		private trySave(): void {
			if (!this.canSave || this.label === null) return;
			const label = this.label.trim();

			this.loading = true;
			this.$store.dispatch('kit/create', {
				label: label,
				userId: this.global ? null : this.$store.state.user.userId,
			})
				.then(res => this.save(res))
				.finally(() => this.loading = false);
		}

		@Watch('open')
		private onOpenChanged(value: boolean): void {
			if (!value) this.reset();
		}

		@Emit('cancel')
		public cancel(): void {
			//do nothing
		}

		@Emit('save')
		public save(kit: IKit): IKit {
			return kit;
		}

	}
