
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {IItem} from "@/models/IItem";
	import {Routes} from "@/helpers/Routes";
	import ItemHelper from "@/helpers/ItemHelper";

	@Component
	export default class ItemChip extends Vue {

		@Prop({type: [String, Number], required: true})
		public id!: string | number;

		private get item(): IItem | null {
			return this.$store.getters['item/itemById'](this.id);
		}

		private get iconUrl(): string | null {
			return this.item === null ? null : ItemHelper.iconUrl(this.item);
		}

		private showItem(): void {
			this.$router.push({name: Routes.item, params: {id: this.id.toString()}});
		}

	}
