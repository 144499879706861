
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import ICraftCalculatorLevel from "@/models/ICraftCalculatorLevel";
	import {IItem} from "@/models/IItem";
	import CraftIngredient from "@/components/craft-calculator/CraftIngredient.vue";
	import IItemData from "@/models/IItemData";

	@Component({
		components: {CraftIngredient}
	})
	export default class CraftIngredients extends Vue {

		@Prop({type: Array, required: true})
		public levels!: ICraftCalculatorLevel[];

		private get ingredients(): { item: IItem, amount: number, itemData: IItemData }[] {
			if (this.levels.length === 0) return [];

			const ingredients = [] as { item: IItem, amount: number, itemData: IItemData }[];
			for (let i = 0; i < this.levels.length; i++) {
				this.resolveIngredientsRecursive(this.levels[i], ingredients);
			}

			return ingredients.sort((a: { item: IItem, amount: number, itemData: IItemData }, b: { item: IItem, amount: number, itemData: IItemData }) => {
				return a.item.label.toLowerCase().localeCompare(b.item.label.toLowerCase());
			});
		}

		private resolveIngredientsRecursive(level: ICraftCalculatorLevel, ingredients: { item: IItem, amount: number, itemData: IItemData }[]): void {
			const id = level.item.id;

			if (level.depth !== 0 && (level.childLevels.length === 0 || this.notCraftedItemIds.includes(level.item.id))) {
				let entry = ingredients.find(i => i.item.id === id);
				if (entry === undefined) {
					const item = this.$store.getters['item/itemById'](id);
					if (item === null) throw new Error(`Item with id ${id} not found`);
					entry = {item: item, amount: 0, itemData: level.itemData};
					ingredients.push(entry);
				}
				entry.amount += level.amount;
			} else {
				for (let i = 0; i < level.childLevels.length; i++) {
					this.resolveIngredientsRecursive(level.childLevels[i], ingredients);
				}
			}
		}

		private get notCraftedItemIds(): number[] {
			return this.$store.state.craftCalculator.notCraftedItemIds;
		}

	}
