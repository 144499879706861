
	import Vue from 'vue';
	import {Component, Prop, Watch} from 'vue-property-decorator';
	import AsiListTableHeader from "@/components/common/AsiListTableHeader";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import {Routes} from "@/helpers/Routes";
	import {IKit} from "@/models/IKit";
	import KitCard from "@/components/kit/KitCard.vue";
	import ArrayHelper, {MultiSortHandler} from "@/helpers/ArrayHelper";
	import DialogHandler from "@/components/common/DialogHandler";
	import KitCreateDialog from "@/components/kit/KitCreateDialog.vue";
	import KitFavouriteButton from "@/components/kit/KitFavouriteButton.vue";
	import ReloadButton from "@/components/common/ReloadButton.vue";
	import KitListFilter from "@/models/filter/KitListFilter";
	import ListHelper from "@/helpers/ListHelper";
	import KitListPreference from "@/models/list-preference/KitListPreference";
	import SkinListFilter from "@/models/filter/SkinListFilter";
	import FilterControl from "@/components/common/FilterControl.vue";
	import SortControl, {SortTemplateOption} from "@/components/common/SortControl.vue";
	import KitAvatar from "@/components/kit/KitAvatar.vue";
	import IProgress from "@/models/IProgress";
	import KitProgressCircular from "@/components/kit/KitProgressCircular.vue";
	import IOption from "@/models/IOption";
	import KitHelper from "@/helpers/KitHelper";

	@Component({
		components: {
			KitProgressCircular,
			KitAvatar,
			SortControl,
			FilterControl,
			ReloadButton, KitFavouriteButton, KitCreateDialog, KitCard
		}
	})
	export default class KitList extends Vue {

		@Prop({type: Array, default: () => []})
		public value!: number[];

		@Prop({type: String, default: null})
		public memoryKey!: string | null;

		@Prop({type: Boolean, default: false})
		public showSelect!: boolean;

		private readonly defaultSortBy: string[] = ['favourite', 'label'];
		private readonly defaultSortDesc: boolean[] = [false, false];

		private options: AsiListTableOptions = new AsiListTableOptions();
		private filter: KitListFilter = new KitListFilter();
		private preference: KitListPreference = new KitListPreference();
		private createDialog: DialogHandler = new DialogHandler();
		private selectedKits: IKit[] = [];

		private filterText: string | null = null;
		private filterTextTimeout: any = null;

		public created(): void {
			this.options.sortBy = this.defaultSortBy;
			this.options.sortDesc = this.defaultSortDesc;

			if (this.memoryKey !== null) {
				const options = this.$store.getters['ui/getListOptions'](this.memoryKey);
				if (options !== null) this.options = options;

				const filter = this.$store.getters['ui/getListFilter'](this.memoryKey);
				if (filter !== null) {
					Object.setPrototypeOf(filter, new KitListFilter());
					this.filter = filter;
					this.filterText = filter.text;
				}

				const preference = this.$store.getters['ui/getListPreference'](this.memoryKey);
				if (preference !== null) this.preference = preference;
			}

			this.loadKits();
		}

		private get isMobile(): boolean {
			return this.$vuetify.breakpoint.xs;
		}

		private get itemsPerPageOptions(): number[] {
			return ListHelper.itemsPerPageOptions;
		}

		private get nullableBooleanOptions(): IOption<boolean | null>[] {
			return ListHelper.nullableBooleanOptions;
		}

		private get ownedOptions(): IOption<number | null>[] {
			return KitHelper.ownedOptions;
		}

		private get kits(): IKit[] {
			return this.filter.applyFilter(this.$store.state.kit.kits);
		}

		private get headers(): AsiListTableHeader[] {
			return [
				new AsiListTableHeader('', 'favourite', true, true, 'center', '5rem'),
				new AsiListTableHeader('', 'avatar', false, false, 'center', '5rem'),
				new AsiListTableHeader('Label', 'label', true, true, 'start'),
				new AsiListTableHeader('# Skins', 'skins', true, true, 'center', '6%'),
				new AsiListTableHeader('# Owned', 'owned', true, true, 'center', '6%'),
				new AsiListTableHeader('Value', 'value', true, true, 'center', '6%'),
				new AsiListTableHeader('Inventory', 'valueOwned', true, true, 'center', '6%'),
				new AsiListTableHeader('Open', 'valueMissing', true, true, 'center', '6%'),
			];
		}

		private get cardView(): boolean {
			return this.$store.state.kit.cardView;
		}

		private set cardView(cardView: boolean) {
			this.$store.commit('kit/setCardView', cardView);
		}

		private get filterCompleteOptions(): { text: string, value: boolean | null }[] {
			return [
				{text: 'Complete and Incomplete', value: null},
				{text: 'Complete only', value: true},
				{text: 'Incomplete only', value: false},
			];
		}

		private get sortTemplateOptions(): SortTemplateOption[] {
			return [
				{
					label: 'Most Complete First',
					icon: 'mdi-check',
					sortBy: ['owned', 'label'],
					sortDesc: [true, false],
				},
			];
		}

		@Watch('value', {immediate: true})
		private onValueChanged(value: number[]): void {
			if (ArrayHelper.identical<number>(value, this.selectedKits.map(s => s.id))) return;
			this.selectedKits = this.$store.state.kit.kits.filter((k: IKit) => value.includes(k.id));
		}

		@Watch('selectedKits')
		private onSelectedKitsChanged(value: IKit[]): void {
			if (ArrayHelper.identical<number>(this.value, value.map(k => k.id))) return;
			this.$emit('input', value.map(k => k.id));
		}

		@Watch('options', {deep: true})
		private onOptionsChanged(value: AsiListTableOptions): void {
			if (this.memoryKey !== null) {
				this.$store.commit('ui/setListOptions', {key: this.memoryKey, options: value});
			}
		}

		@Watch('filter', {deep: true})
		private onFilterChanged(value: SkinListFilter): void {
			if (this.memoryKey !== null) {
				this.$store.commit('ui/setListFilter', {key: this.memoryKey, filter: value});
			}
			if (this.filterText !== value.text) {
				this.filterText = value.text;
			}
		}

		@Watch('filterText')
		private onFilterTextChanged(value: string | null): void {
			clearTimeout(this.filterTextTimeout);
			this.filterTextTimeout = setTimeout(() => {
				this.filter.text = value;
				console.log('changing after debouncing');
			}, value !== null && value.length > 0 ? 500 : 25);
		}

		@Watch('preference', {deep: true})
		private onPreferenceChanged(value: SkinListFilter): void {
			if (this.memoryKey !== null) {
				this.$store.commit('ui/setListPreference', {key: this.memoryKey, preference: value});
			}
		}

		private loadKits(force: boolean = false): void {
			this.$store.dispatch('kit/loadKits', force);
		}

		private sortEntries(entries: IKit[], sortBy: string[], sortDesc: boolean[]): IKit[] {
			return ArrayHelper.multiSort<IKit>(entries, sortBy, sortDesc, new Map<string, MultiSortHandler<IKit>>([
				['label', (a, b, column, desc) => {
					return a.label.toLowerCase().localeCompare(b.label.toLowerCase()) * (desc ? -1 : 1);
				}],
				['favourite', (a, b, column, desc) => {
					return (this.$store.getters['kit/isFavourite'](b.id) - this.$store.getters['kit/isFavourite'](a.id)) * (desc ? -1 : 1);
				}],
				['skins', (a, b, column, desc) => {
					return (b.skin_ids.length - a.skin_ids.length) * (desc ? -1 : 1);
				}],
				['owned', (a, b, column, desc) => {
					return (this.progress(a).progress - this.progress(b).progress) * (desc ? -1 : 1);
				}],
				['value', (a, b, column, desc) => {
					return (this.kitValue(a) - this.kitValue(b)) * (desc ? -1 : 1);
				}],
				['valueOwned', (a, b, column, desc) => {
					return (this.kitValueOwned(a) - this.kitValueOwned(b)) * (desc ? -1 : 1);
				}],
				['valueMissing', (a, b, column, desc) => {
					return (this.kitValueOpen(a) - this.kitValueOpen(b)) * (desc ? -1 : 1);
				}],
			]));
		}

		private showKit(id: number): void {
			this.$router.push({name: Routes.kit, params: {id: id.toString()}});
		}

		private numOwned(kit: IKit): number {
			return this.$store.getters['inventory/numOwned'](kit.skin_ids);
		}

		private kitValue(kit: IKit): number {
			return this.$store.getters['skin/valueOfSkins'](kit.skin_ids);
		}

		private kitValueOwned(kit: IKit): number {
			return this.$store.getters['inventory/ownedValueOfSkins'](kit.skin_ids);
		}

		private kitValueOpen(kit: IKit): number {
			return this.$store.getters['inventory/openValueOfSkins'](kit.skin_ids);
		}

		private progress(kit: IKit): IProgress {
			return this.$store.getters['inventory/skinProgress'](kit.skin_ids);
		}

	}
