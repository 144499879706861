import AxiosService from "@/services/AxiosService";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import Vue from "vue";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import IItemGroupService from "@/services/definition/IItemGroupService";
import {IItemGroup} from "@/models/IItemGroup";

export default class AxiosItemGroupService extends AxiosService implements IItemGroupService {

	protected defineBaseUrl(): string {
		return 'item-group';
	}

	public itemGroups(options: AsiListTableOptions): Promise<IPaginatedResponse<IItemGroup>> {
		return Vue.$axios.get<IItemGroup[]>(this.url('index'), {
			params: {
				...AxiosService.finalizeOptions(options)
			}
		})
			.then(res => {
				return {
					data: res.data,
					total: Number.parseInt(res.headers['x-pagination-total-count']),
				} as IPaginatedResponse<IItemGroup>;
			});
	}

	public latestChange(): Promise<number> {
		return Vue.$axios.get<number>(this.url('latest-change')).then(res => res.data);
	}

}
