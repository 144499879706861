import AxiosService from "@/services/AxiosService";
import ICrawlJobLogService from "@/services/definition/ICrawlJobLogService";
import {ICrawlJobLog} from "@/models/ICrawlJobLog";
import Vue from "vue";

export default class AxiosCrawlJobLogService extends AxiosService implements ICrawlJobLogService {

	protected defineBaseUrl(): string {
		return 'crawl-job-log';
	}

	public jobs(): Promise<string[]> {
		return Vue.$axios.get<string[]>(this.url('jobs')).then(res => res.data);
	}

	public crawlJobLogs(jobName: string | null, skinId: number | null): Promise<ICrawlJobLog[]> {
		return Vue.$axios.get<ICrawlJobLog[]>(this.url('index'), {
			params: {
				job: jobName,
				skin_id: skinId,
			},
		}).then(res => res.data);
	}

}
