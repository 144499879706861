import IAuthService from "@/services/definition/IAuthService";
import AxiosService from "@/services/AxiosService";
import {ITokenPair} from "@/models/ITokenPair";
import Vue from "vue";

export default class AxiosAuthService extends AxiosService implements IAuthService {

	protected defineBaseUrl(): string {
		return 'auth';
	}

	public authenticate(username: string, password: string): Promise<ITokenPair> {
		return Vue.$axios.post<ITokenPair>(this.url('authenticate'), {
			username: username,
			password: password,
		}).then(res => res.data);
	}

	public refresh(refreshToken: string): Promise<ITokenPair> {
		return Vue.$axios.post<ITokenPair>(this.url('refresh'), {
			refresh_token: refreshToken,
		}).then(res => res.data);
	}

	public logout(): Promise<null> {
		return Vue.$axios.post<null>(this.url('logout')).then(res => res.data);
	}

}
