
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import IPropertyBlockEntry from "@/models/IPropertyBlockEntry";
	import PropertyBlockEntry from "@/components/common/PropertyBlockEntry.vue";

	@Component({
		components: {PropertyBlockEntry}
	})
	export default class PropertyBlock extends Vue {

		@Prop({type: String, required: true})
		public title!: string;

		@Prop({type: Array, default: null})
		public entries!: IPropertyBlockEntry[] | null;

		private get hasSlotTitleAppend(): boolean {
			return !!this.$slots.titleAppend || !!this.$scopedSlots['titleAppend'];
		}

		private get hasSlotPrepend(): boolean {
			return !!this.$slots.prepend || !!this.$scopedSlots['prepend'];
		}

		private get hasSlotAppend(): boolean {
			return !!this.$slots.append || !!this.$scopedSlots['append'];
		}

		private get hasSlotEntryIcon(): boolean {
			return !!this.$slots.entryIcon || !!this.$scopedSlots['entryIcon'];
		}

	}
