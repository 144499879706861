
	import Vue from 'vue';
	import {Component, Emit, Prop} from 'vue-property-decorator';
	import SkinChip from "@/components/skin/SkinChip.vue";
	import AsiListTableHeader from "@/components/common/AsiListTableHeader";
	import SkinAvatar from "@/components/skin/SkinAvatar.vue";
	import {ISkin} from "@/models/ISkin";

	@Component({
		components: {SkinAvatar, SkinChip}
	})
	export default class SkinPricingEntriesMultiFetchDialog extends Vue {

		private readonly statePending = 0;
		private readonly stateSuccess = 1;
		private readonly stateError = 2;
		private readonly stateNotTradeable = 3;

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		@Prop({type: Array, required: true})
		public skinIds!: number[];

		private intervalRange: number[] = [5, 10];
		private started: boolean = false;
		private idleTimeout: any = null;
		private idleUpdateInterval: any = null;
		private idlingFrom: number | null = null;
		private idlingFor: number | null = null;
		private idlingProgress: number | null = null;
		private debugMode: boolean = false;

		private skinIdData: Map<number, number> = new Map<number, number>();
		private skinModels: Map<number, ISkin | null> = new Map<number, ISkin | null>();
		private skinOldPrices: Map<number, number | null> = new Map<number, number | null>();
		private skinNewPrices: Map<number, number | null> = new Map<number, number | null>();
		private allIds: number[] = [];
		private idsToFetch: number[] = [];
		private idsFetched: number[] = [];
		private currentlyFetchedId: number | null = null;

		public created(): void {
			this.reset();
		}

		private get progress(): number {
			return Math.round(this.idsFetched.length / this.allIds.length * 100);
		}

		private get headers(): AsiListTableHeader[] {
			return [
				new AsiListTableHeader('', 'avatar', false, false, 'center', '3rem'),
				new AsiListTableHeader('Skin', 'label', false, false),
				new AsiListTableHeader('Old Price', 'oldPrice', false, false, 'end', '7rem'),
				new AsiListTableHeader('New Price', 'newPrice', false, false, 'end', '7rem'),
				new AsiListTableHeader('State', 'state', false, false, 'end', '3rem'),
			];
		}

		@Emit('cancel')
		public cancel(): void {
			this.reset();
		}

		private start(): void {
			if (this.started || this.idsToFetch.length === 0) {
				return;
			}

			this.started = true;
			this.fetchNext();
		}

		private stop(): void {
			clearTimeout(this.idleTimeout);
			clearInterval(this.idleUpdateInterval);
			this.started = false;
			this.idlingFrom = null;
			this.idlingFor = null;
			this.idlingProgress = null;
		}

		private fetchNext(): void {
			if (this.idsToFetch.length === 0) return;

			const id = this.idsToFetch[0];
			this.currentlyFetchedId = id;
			this.$skinService.pricingEntries(id, !this.debugMode)
				.then(() => {
					this.skinIdData.set(id, this.stateSuccess);
					this.$store.dispatch('skin/loadSkin', id)
						.then(() => this.skinNewPrices.set(id, this.$store.getters['skin/skinById'](id)?.price ?? null));
				})
				.catch(() => {
					this.skinIdData.set(id, this.stateError);
				})
				.finally(() => {
					this.updateIdFields();
					this.currentlyFetchedId = null;
					if (this.idsToFetch.length > 0) {
						this.idlingFrom = Date.now();
						this.idlingFor = this.randomDelaySeconds() * 1000;
						this.idlingProgress = null;
						this.idleUpdateInterval = setInterval(() => {
							this.idlingProgress = Math.round(((Date.now() - (this.idlingFrom ?? 0)) / (this.idlingFor ?? 1)) * 100);
						}, 200);

						this.idleTimeout = setTimeout(() => {
							clearInterval(this.idleUpdateInterval);
							this.idlingFrom = null;
							this.idlingFor = null;
							this.idlingProgress = null;
							this.fetchNext();
						}, this.idlingFor);
					} else {
						this.stop();
					}
				});
		}

		private reset(): void {
			this.stop();
			this.skinIdData.clear();
			this.skinModels.clear();
			this.skinOldPrices.clear();
			this.skinNewPrices.clear();
			this.skinIds.forEach(id => {
				const skin: ISkin | null = this.$store.getters['skin/skinById'](id);
				this.skinModels.set(id, skin);
				if (skin === null) return;
				this.skinOldPrices.set(id, skin.price);
				this.skinNewPrices.set(id, null);
				this.skinIdData.set(id, skin.tradeable ? this.statePending : this.stateNotTradeable);
			});
			this.updateIdFields();
		}

		private updateIdFields(): void {
			this.allIds = Array.from(this.skinIdData.keys());
			this.idsToFetch = this.allIds.filter(id => this.skinIdData.get(id) === this.statePending);
			this.idsFetched = this.allIds.filter(id => {
				const skinState = this.skinIdData.get(id);
				return skinState === this.stateSuccess || skinState === this.stateError;
			});
		}

		private randomDelaySeconds(): number {
			return this.intervalRange[0] + ((this.intervalRange[1] - this.intervalRange[0]) * Math.random());
		}

		private skinIcon(id: number): string {
			switch (this.skinIdData.get(id)) {
				case this.statePending:
					return 'mdi-clock-time-five';
				case this.stateSuccess:
					return 'mdi-check-circle';
				case this.stateError:
					return 'mdi-error-circle';
				case this.stateNotTradeable:
					return 'mdi-cart';
				default:
					return 'mdi-help-circle';
			}
		}

		private skinIconColor(id: number): string {
			switch (this.skinIdData.get(id)) {
				case this.statePending:
					return 'grey';
				case this.stateSuccess:
					return 'success';
				case this.stateError:
					return 'error';
				case this.stateNotTradeable:
					return 'error';
				default:
					return 'warning';
			}
		}

		private oldPrice(id: number): number | null {
			return this.skinOldPrices.get(id) ?? null;
		}

		private newPrice(id: number): number | null {
			return this.skinNewPrices.get(id) ?? null;
		}

	}
