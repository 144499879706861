
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import HomeFavouriteSkins from "@/components/home/HomeFavouriteSkins.vue";
	import HomeFavouriteKits from "@/components/home/HomeFavouriteKits.vue";
	import HomeNewSkins from "@/components/home/HomeNewSkins.vue";
	import HomeSpareSkins from "@/components/home/HomeSpareSkins.vue";

	@Component({
		components: {
			HomeSpareSkins,
			HomeNewSkins, HomeFavouriteKits, HomeFavouriteSkins
		}
	})
	export default class HomeView extends Vue {

		private get cardSize(): number {
			return this.$store.state.ui.home.cardSize;
		}

		private set cardSize(size: number) {
			this.$store.commit('ui/setHomeCardSize', size);
		}

		private get cardContainerStyles(): object {
			return {
				'grid-template-columns': `repeat(auto-fill, minmax(${this.cardSize}rem, 1fr))`,
			};
		}

		private get isAdmin(): boolean {
			return this.$store.getters['user/isAdmin']();
		}

	}
