
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import UserList from "@/components/user/UserList.vue";

	@Component({
		components: {UserList}
	})
	export default class UsersView extends Vue {

	}
