
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import Login from "@/components/common/Login.vue";

	@Component({
		components: {Login}
	})
	export default class LoginView extends Vue {


	}
