import AxiosService from "@/services/AxiosService";
import Vue from "vue";
import IProxy from "@/models/IProxy";
import IProxyService from "@/services/definition/IProxyService";

export default class AxiosProxyService extends AxiosService implements IProxyService {

	protected defineBaseUrl(): string {
		return 'proxy';
	}

	public proxies(states: number[] = []): Promise<IProxy[]> {
		return Vue.$axios.get<IProxy[]>(this.url('index'), {
			params: {
				states: states,
			},
		}).then(res => res.data);
	}

	public setState(id: number, state: number): Promise<void> {
		return Vue.$axios.patch<void>(this.url('set-state'), {
			id: id,
			state: state,
		}).then(res => res.data);
	}

}
