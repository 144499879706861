
	import Vue from 'vue';
	import {Component, Prop, Watch} from 'vue-property-decorator';
	import KitCard from "@/components/kit/KitCard.vue";
	import {IKit} from "@/models/IKit";
	import SkinCard from "@/components/skin/SkinCard.vue";

	@Component({
		components: {KitCard}
	})
	export default class HomeFavouriteKits extends Vue {

		@Prop({type: Number, required: true})
		public cardSize!: number;

		@Prop({type: Object, required: true})
		public cardContainerStyles!: object;

		@Prop({type: Boolean, required: true})
		public isAdmin!: boolean;

		private get favouriteKits(): IKit[] {
			return this.$store.getters['kit/favourites']()
				.sort((a: IKit, b: IKit) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
		}

		private reloadFavouriteKits(): void {
			this.$store.dispatch('kit/loadFavourites', true);
		}

		@Watch('cardSize')
		private onCardSizeChanged(): void {
			(this.$refs.favouriteSkins as SkinCard[] | undefined)?.forEach((card: SkinCard) => card.updateCardWidth());
		}

	}
