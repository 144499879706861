
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import IItemData from "@/models/IItemData";
	import {IItem} from "@/models/IItem";
	import ItemAvatar from "@/components/item/ItemAvatar.vue";
	import {Routes} from "@/helpers/Routes";
	import ItemHelper from "@/helpers/ItemHelper";
	import IPropertyBlockEntry from "@/models/IPropertyBlockEntry";
	import IPropertyBlock from "@/models/IPropertyBlock";
	import PropertyBlock from "@/components/common/PropertyBlock.vue";
	import ItemCard from "@/components/item/ItemCard.vue";

	interface IProperty {
		slug: string,
		label: string,
		value: string,
	}

	@Component({
		components: {ItemCard, PropertyBlock, ItemAvatar}
	})
	export default class ItemData extends Vue {

		@Prop({type: Number, required: true})
		public id!: number;

		private loading: boolean = false;
		private data: IItemData | null = null;
		private craftAmount: number = 1;
		private mixingAmount: number = 1;
		private numberFormatter: Intl.NumberFormat = new Intl.NumberFormat('de-CH');

		private get ingredients(): IPropertyBlockEntry[] {
			return (this.data?.ingredients ?? [])
				.map(entry => {
					const model = this.$store.getters['item/itemById'](entry.item_id) as IItem;
					return {
						slug: model?.label ?? '?',
						label: model?.label ?? '?',
						value: this.numberFormatter.format(entry.amount * this.craftAmount),
						model: model,
					} as IPropertyBlockEntry;
				})
				.sort((a, b) => {
					return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
				});
		}

		private get mixingIngredients(): IPropertyBlockEntry[] {
			return (this.data?.mixingIngredients ?? [])
				.map(entry => {
					const model = this.$store.getters['item/itemById'](entry.item_id) as IItem;
					return {
						slug: model?.label ?? '?',
						label: model?.label ?? '?',
						value: this.numberFormatter.format(entry.amount * this.mixingAmount),
						model: model,
					} as IPropertyBlockEntry;
				})
				.sort((a, b) => {
					return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
				});
		}

		private get mixingRecipeEntries(): IPropertyBlockEntry[] {
			return (this.data?.mixingRecipeEntries ?? [])
				.sort((a, b) => a.order - b.order)
				.map(entry => {
					const model = this.$store.getters['item/itemById'](entry.item_id) as IItem;
					return {
						slug: model?.label ?? '?',
						label: model?.label ?? '?',
						value: this.numberFormatter.format(entry.amount * this.mixingAmount),
						model: model,
					} as IPropertyBlockEntry;
				});
		}

		private get propertyBlocks(): IPropertyBlock[] {
			return (this.data?.properties ?? [])
				.reduce((acc: IPropertyBlock[], cur: IProperty) => {
					const label = ItemHelper.propertyGroupLabel(cur.slug);
					let block = acc.find(b => b.title === label);

					if (block === undefined) {
						block = {
							title: label,
							entries: [] as IPropertyBlockEntry[],
						} as IPropertyBlock;
						acc.push(block);
					}

					block.entries.push({
						slug: cur.slug,
						label: cur.label,
						value: ItemHelper.propertyValueFinalized(cur.value),
						model: null,
					} as IPropertyBlockEntry);

					return acc;
				}, []);
		}

		private get wbLevelColor(): string {
			return ItemHelper.wbLevelColor(this.data?.wb_level ?? null);
		}

		@Watch('id', {immediate: true})
		private onIdChanged(): void {
			this.loadItemData();
		}

		@Emit('dataLoaded')
		public dataLoaded(data: IItemData): IItemData {
			return data;
		}

		private loadItemData(): void {
			this.loading = true;
			this.$store.dispatch('item/loadItemData', this.id)
				.then(data => {
					this.data = data;
					this.dataLoaded(data);
				})
				.catch(err => console.error(err))
				.finally(() => this.loading = false);
		}

		private showItem(id: number): void {
			this.$router.push({name: Routes.item, params: {id: id.toString()}});
		}

	}
