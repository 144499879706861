import Vue, {PluginObject} from "vue";
import AxiosSteamService from "@/services/implementation/AxiosSteamService";
import AxiosInventoryEntryService from "@/services/implementation/AxiosInventoryEntryService";
import AxiosItemService from "@/services/implementation/AxiosItemService";
import AxiosSkinService from "@/services/implementation/AxiosSkinService";
import AxiosItemGroupService from "@/services/implementation/AxiosItemGroupService";
import AxiosKitService from "@/services/implementation/AxiosKitService";
import AxiosAuthService from "@/services/implementation/AxiosAuthService";
import AxiosUserService from "@/services/implementation/AxiosUserService";
import AxiosCrawlJobLogService from "@/services/implementation/AxiosCrawlJobLogService";
import AxiosLoadoutService from "@/services/implementation/AxiosLoadoutService";
import AxiosProxyService from "@/services/implementation/AxiosProxyService";

const authService = new AxiosAuthService();
const userService = new AxiosUserService();
const itemGroupService = new AxiosItemGroupService();
const itemService = new AxiosItemService();
const skinService = new AxiosSkinService();
const kitService = new AxiosKitService();
const inventoryEntryService = new AxiosInventoryEntryService();
const steamService = new AxiosSteamService();
const loadoutService = new AxiosLoadoutService();
const crawlJobLogService = new AxiosCrawlJobLogService();
const proxyService = new AxiosProxyService();

const Plugin = {
	install: function (vueInstance: typeof Vue): void {
		vueInstance.$authService = authService;
		vueInstance.$userService = userService;
		vueInstance.$itemGroupService = itemGroupService;
		vueInstance.$itemService = itemService;
		vueInstance.$skinService = skinService;
		vueInstance.$kitService = kitService;
		vueInstance.$inventoryEntryService = inventoryEntryService;
		vueInstance.$steamService = steamService;
		vueInstance.$loadoutService = loadoutService;
		vueInstance.$crawlJobLogService = crawlJobLogService;
		vueInstance.$proxyService = proxyService;

		Object.defineProperties(vueInstance.prototype, {
			$authService: {
				get() {
					return authService;
				}
			},
			$userService: {
				get() {
					return userService;
				}
			},
			$itemGroupService: {
				get() {
					return itemGroupService;
				}
			},
			$itemService: {
				get() {
					return itemService;
				}
			},
			$skinService: {
				get() {
					return skinService;
				}
			},
			$kitService: {
				get() {
					return kitService;
				}
			},
			$inventoryEntryService: {
				get() {
					return inventoryEntryService;
				}
			},
			$steamService: {
				get() {
					return steamService;
				}
			},
			$loadoutService: {
				get() {
					return loadoutService;
				}
			},
			$crawlJobLogService: {
				get() {
					return crawlJobLogService;
				}
			},
			$proxyService: {
				get() {
					return proxyService;
				}
			},
		});
	},
} as PluginObject<any>;

Vue.use(Plugin.install);
export default Plugin;
