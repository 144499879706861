
	import Vue from 'vue';
	import {Component, Emit, Prop} from 'vue-property-decorator';
	import IListFilter from "@/models/filter/IListFilter";
	import IFilterControlTemplate from "@/models/IFilterControlTemplate";

	@Component
	export default class FilterControl extends Vue {

		@Prop({type: Object, required: true})
		public filter!: IListFilter<any>;

		@Prop({type: Array, default: () => []})
		public templates!: IFilterControlTemplate<any>[];

		private get hasFilter(): boolean {
			return this.filter.hasFilter();
		}

		private get color(): string | null {
			return this.hasFilter ? 'error' : null;
		}

		@Emit('clear')
		public clear(): void {
			this.filter.clear();
		}

		private applyTemplate(template: IFilterControlTemplate<any>, clearFilter: boolean): void {
			if (clearFilter) this.filter.clear();
			template.apply(this.filter);
		}

	}
