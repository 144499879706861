
	import Vue from 'vue';
	import {Component, Emit, Prop} from 'vue-property-decorator';
	import CrawlJobLogs from "@/components/crawl-job-log/CrawlJobLogs.vue";

	@Component({
		components: {CrawlJobLogs}
	})
	export default class CrawlJobLogsDialog extends Vue {

		@Prop({type: String, default: null})
		public textFilter!: string | null;

		@Prop({type: Number, default: null})
		public skinId!: number | null;

		@Prop({type: String, default: null})
		public job!: string | null;

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		private loadingInternal: boolean = false;

		@Emit('cancel')
		public cancel(): void {
			return;
		}

	}
