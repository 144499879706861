
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import CraftOrder from "@/components/craft-calculator/CraftOrder.vue";
	import ICraftCalculatorLevel from "@/models/ICraftCalculatorLevel";
	import CraftIngredients from "@/components/craft-calculator/CraftIngredients.vue";
	import CraftOrderCreateCard from "@/components/craft-calculator/CraftOrderCreateCard.vue";
	import ItemAvatar from "@/components/item/ItemAvatar.vue";

	@Component({
		components: {ItemAvatar, CraftOrderCreateCard, CraftIngredients, CraftOrder}
	})
	export default class CraftCalculatorView extends Vue {

		private topLevels: ICraftCalculatorLevel[] = [] as ICraftCalculatorLevel[];
		private activeTab: number = 0;

		private get craftOrders(): { itemId: number, amount: number }[] {
			return this.$store.state.craftCalculator.craftOrders;
		}

		private addCraftOrder(order: { itemId: number, amount: number }): void {
			this.$store.commit('craftCalculator/setCraftOrder', order);
			const index = this.craftOrders.findIndex(o => o.itemId === order.itemId);
			this.activeTab = index === -1 ? 0 : index;
		}

		private orderTopLevelChanged(itemId: number, topLevel: ICraftCalculatorLevel | null): void {
			this.topLevels = this.topLevels.filter(l => l.item.id !== itemId);
			if (topLevel !== null) {
				this.topLevels.push(topLevel);
			}
		}

		private removeCraftOrder(itemId: number): void {
			this.orderTopLevelChanged(itemId, null);
			this.$store.commit('craftCalculator/removeCraftOrder', itemId);
			const activeTab = this.craftOrders.length - 1;
			this.activeTab = activeTab < 0 ? 0 : activeTab;
		}

	}
