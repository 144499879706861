
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import KitList from "@/components/kit/KitList.vue";

	@Component({
		components: {KitList}
	})
	export default class KitsView extends Vue {

	}
