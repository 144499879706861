
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {IItem} from "@/models/IItem";
	import {IItemGroup} from "@/models/IItemGroup";
	import ItemGroupHelper from "@/helpers/ItemGroupHelper";
	import DetailHeader from "@/components/common/DetailHeader.vue";
	import ItemList from "@/components/item/ItemList.vue";

	@Component({
		components: {ItemList, DetailHeader}
	})
	export default class ItemGroupView extends Vue {

		@Prop({type: String, required: true})
		public id!: string;

		private get idNumeric(): number | null {
			const id = Number.parseInt(this.id);
			return Number.isNaN(id) ? null : id;
		}

		private get itemGroup(): IItemGroup | null {
			return this.idNumeric === null ? null : this.$store.getters['itemGroup/itemGroupById'](this.idNumeric);
		}

		private get iconUrl(): string | null {
			return this.itemGroup === null ? null : ItemGroupHelper.iconUrl(this.itemGroup);
		}

		private get hasItemGroups(): boolean {
			return this.idNumeric === null ? false : this.$store.state.item.items.some((i: IItem) => i.group_id === this.idNumeric);
		}

	}
