import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import './plugins/axios';
import './plugins/services';
import store from './store';
import './scss/global.scss';

Vue.config.productionTip = false;
new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app');
