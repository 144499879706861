
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import DialogHandler from "@/components/common/DialogHandler";

	@Component
	export default class DetailAvatar extends Vue {

		@Prop({required: true})
		public iconUrl!: string | null;

		@Prop({type: String, default: null})
		public iconUrlHd!: string | null;

		@Prop({type: String, default: '#fafafa'})
		public bgColor!: string | null;

		@Prop({type: [String, Number], default: '5rem'})
		public size!: string | number;

		private hover: boolean = false;
		private dialog: DialogHandler = new DialogHandler();

		private get iconContainerStyles(): object {
			const gradients = [];
			//gradients.push('repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(0, 0, 0, 0.0075) 5px, rgba(0, 0, 0, 0.0075) 10px)');
			gradients.push(`radial-gradient(transparent 50%, ${this.bgColor})`);

			return {
				'cursor': this.hasClickHandler ? 'pointer' : 'inherit',
				'background-color': '#fff',
				'background-image': gradients.join(', '),
			};
		}

		private get hasClickHandler(): boolean {
			//@ts-ignore
			return this.$listeners && this.$listeners.click;
		}

		private get maxDimension(): string {
			return this.iconUrlHd === null ? '512px' : '768px';
		}

	}
