
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import SkinCard from "@/components/skin/SkinCard.vue";
	import SkinPricingEntriesMultiFetchButton from "@/components/skin/SkinPricingEntriesMultiFetchButton.vue";
	import {ISkin} from "@/models/ISkin";

	@Component({
		components: {SkinPricingEntriesMultiFetchButton, SkinCard}
	})
	export default class HomeSpareSkins extends Vue {

		@Prop({type: Number, required: true})
		public cardSize!: number;

		@Prop({type: Object, required: true})
		public cardContainerStyles!: object;

		@Prop({type: Boolean, required: true})
		public isAdmin!: boolean;

		private get spareSkins(): ISkin[] {
			return this.$store.state.skin.skins
				.filter((s: ISkin) => s.tradeable && this.$store.getters['inventory/amountBySkinId'](s.id) > 1 && s.price !== null)
				.sort((a: ISkin, b: ISkin) => (b.price ?? 0) - (a.price ?? 0));
		}

		private get spareSkinsValue(): number {
			return this.spareSkins.reduce((acc: number, cur: ISkin) => {
				if (cur.price === null) return acc;
				return acc + cur.price * (this.$store.getters['inventory/amountBySkinId'](cur.id) - 1);
			}, 0);
		}

	}
