import {IItem} from "@/models/IItem";

export default class ItemHelper {

	public static iconUrl(item: IItem): string | null {
		return item.has_icon ? `${process.env.VUE_APP_API_BASE_URL}item/icon?id=${item.id}` : null;
	}

	public static propertyValueFinalized(propertyValue: string): string[] {
		return propertyValue
			.split(/(\s{2,})|,\s{0,}/)
			.filter(s => s !== null && s !== undefined)
			.map(s => s.trim())
			.filter(s => s.length > 0);
	}

	public static wbLevelColor(level: number | null): string {
		switch (level) {
			case 1:
				return 'info';
			case 2:
				return 'warning';
			case 3:
				return 'error';
			case null:
			case 0:
			default:
				return 'success';
		}
	}

	public static propertyGroupLabel(propertySlug: string): string {
		switch (propertySlug) {
			case 'cold':
			case 'bite':
			case 'explosion':
			case 'melee':
			case 'projectile':
			case 'radiation':
			case 'waterproof':
				return 'Protection';

			case 'speed-reduction':
			case 'obstructs-vision':
			case 'blocks-aiming':
				return 'Player impact';

			case 'aim-cone':
			case 'hip-aim-cone':
			case 'aim-sway':
			case 'capacity':
			case 'draw':
			case 'rate-of-fire':
			case 'reload':
			case 'recoil':
			case 'damage':
			case 'explosion-delay':
			case 'explosion-radius':
			case 'attack-speed':
			case 'range':
			case 'throw':
				return 'Weapon';

			case 'decay':
			case 'upkeep':
				return 'Maintenance';

			case 'seats':
			case 'fuel-economy':
			case 'size':
			case 'acceleration':
			case 'top-speed':
				return 'Vehicle';

			case 'inputs':
			case 'outputs':
			case 'consumption':
			case 'generates-energy':
				return 'Electricity & Farming';

			case 'health':
			case 'health-over-time':
			case 'bleeding':
			case 'calories':
			case 'hydration':
			case 'bait-lvl':
				return 'Health & Food';

			case 'max-health':
			case 'ore-yield':
			case 'wood-yield':
			case 'scrap-yield':
				return 'Special Abilities';

			default:
				return 'Other';
		}
	}

}
