
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {IKit} from "@/models/IKit";
	import KitHelper from "@/helpers/KitHelper";

	@Component
	export default class KitAvatar extends Vue {

		@Prop({type: Object, required: true})
		public kit!: IKit;

		@Prop({type: Number, default: 38})
		public size!: number;

		@Prop({type: Boolean, default: false})
		public noTooltip!: boolean;

		private get iconUrl(): string | null {
			return this.$store.state.ui.showAvatars
				? KitHelper.iconUrl(this.kit)
				: null;
		}

	}
