import IListPreference from "@/models/list-preference/IListPreference";

export default class SkinListPreference implements IListPreference {

	public static readonly defaultImgSize: number = 38;
	public static readonly defaultCardSize: number = 13;

	public cardView: boolean = false;
	public imgSize: number = SkinListPreference.defaultImgSize;
	public cardSize: number = SkinListPreference.defaultCardSize;

	public reset(): void {
		this.cardView = false;
		this.imgSize = SkinListPreference.defaultImgSize;
		this.cardSize = SkinListPreference.defaultCardSize;
	}

}
