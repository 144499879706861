
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {ISkin} from "@/models/ISkin";

	@Component
	export default class SkinAmountChip extends Vue {

		@Prop({type: Object, required: true})
		public skin!: ISkin;

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public xSmall!: boolean;

		@Prop({type: Number, default: 0})
		public elevation!: number;

		private get amount(): number {
			return this.$store.getters['inventory/amountBySkinId'](this.skin.id);
		}

		private get value(): string {
			if (this.amount === 0 || this.skin.price === null) {
				return '?';
			}

			return '$ ' + (this.amount * this.skin.price).toFixed(2);
		}

		private get color(): string {
			switch (this.amount) {
				case 0:
					return 'grey';
				case 1:
					return 'info';
				default:
					return 'success';
			}
		}

		private get classString(): string | null {
			const classes: string[] = [];
			if (this.small) classes.push('px-3');
			if (this.xSmall) classes.push('px-2');
			if (this.elevation > 0) {
				classes.push(`elevation-${this.elevation}`);
			}
			return classes.length === 0 ? null : classes.join(' ');
		}

	}
