
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import {Routes} from "@/helpers/Routes";
	import Logo from "@/components/common/Logo.vue";
	import {IUser} from "@/models/IUser";
	import DialogHandler from "@/components/common/DialogHandler";
	import GlobalSearchDialog from "@/components/common/GlobalSearchDialog.vue";
	import UserMenu from "@/components/layout/UserMenu.vue";
	import ResponsiveNav from "@/components/layout/ResponsiveNav.vue";

	@Component({
		components: {ResponsiveNav, UserMenu, GlobalSearchDialog, Logo}
	})
	export default class MainNav extends Vue {

		private loadingInventory: boolean = false;
		private loadingItemStore: boolean = false;
		private globalSearchDialog: DialogHandler = new DialogHandler();

		private get isLoggedIn(): boolean {
			return this.$store.getters['user/isLoggedIn']();
		}

		private get user(): IUser | null {
			return this.$store.state.user.user;
		}

		private get roles(): string {
			return this.$store.state.user.roles?.join(', ') ?? '';
		}

		private get isAdmin(): boolean {
			return this.$store.getters['user/isAdmin']();
		}

		private get isHomeActive(): boolean {
			return this.$route.name === Routes.home;
		}

		private get isSkinsActive(): boolean {
			return this.$route.name === Routes.skins || this.$route.name === Routes.skin;
		}

		private get isKitsActive(): boolean {
			return this.$route.name === Routes.kits || this.$route.name === Routes.kit;
		}

		private get isItemGroupsActive(): boolean {
			return this.$route.name === Routes.itemGroups || this.$route.name === Routes.itemGroup;
		}

		private get isItemsActive(): boolean {
			return this.$route.name === Routes.items || this.$route.name === Routes.item;
		}

		private get isInventoryActive(): boolean {
			return this.$route.name === Routes.inventory;
		}

		private get isLoadoutsActive(): boolean {
			return this.$route.name === Routes.loadouts;
		}

		private get isCraftCalculatorActive(): boolean {
			return this.$route.name === Routes.craftCalculator;
		}

		private get isUsersActive(): boolean {
			return this.$route.name === Routes.users;
		}

		private get isCrawlJobLogActive(): boolean {
			return this.$route.name === Routes.crawlJobLogs;
		}

		private get isProxiesActive(): boolean {
			return this.$route.name === Routes.proxies;
		}

		private get mobileMode(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		}

		private showHome(): void {
			if (this.$router.currentRoute.name === Routes.home) return;
			this.$router.push({name: Routes.home});
		}

		private showItemGroups(): void {
			if (this.$router.currentRoute.name === Routes.itemGroups) return;
			this.$router.push({name: Routes.itemGroups});
		}

		private showItems(): void {
			if (this.$router.currentRoute.name === Routes.items) return;
			this.$router.push({name: Routes.items});
		}

		private showSkins(): void {
			if (this.$router.currentRoute.name === Routes.skins) return;
			this.$router.push({name: Routes.skins});
		}

		private showKits(): void {
			if (this.$router.currentRoute.name === Routes.kits) return;
			this.$router.push({name: Routes.kits});
		}

		private showInventory(): void {
			if (this.$router.currentRoute.name === Routes.inventory) return;
			this.$router.push({name: Routes.inventory});
		}

		private showLoadouts(): void {
			if (this.$router.currentRoute.name === Routes.loadouts) return;
			this.$router.push({name: Routes.loadouts});
		}

		private showCraftCalculator(): void {
			if (this.$router.currentRoute.name === Routes.craftCalculator) return;
			this.$router.push({name: Routes.craftCalculator});
		}

		private showUsers(): void {
			if (this.$router.currentRoute.name === Routes.users) return;
			this.$router.push({name: Routes.users});
		}

		private showCrawlJobLogs(): void {
			if (this.$router.currentRoute.name === Routes.crawlJobLogs) return;
			this.$router.push({name: Routes.crawlJobLogs});
		}

		private showProxies(): void {
			if (this.$router.currentRoute.name === Routes.proxies) return;
			this.$router.push({name: Routes.proxies});
		}

		private logout(): void {
			this.$store.dispatch('user/logout');
		}

		private reimportInventory(): void {
			if (this.loadingInventory) return;

			this.loadingInventory = true;
			this.$inventoryEntryService.reimport()
				.then(() => {
					this.$store.dispatch('inventory/loadInventoryEntries', true);
					this.$store.dispatch('skin/loadSkins', true);
				})
				.catch(err => console.error(err))
				.finally(() => this.loadingInventory = false);
		}

		private reimportItemStore(): void {
			if (this.loadingItemStore || !this.$store.getters['user/isAdmin']()) return;

			this.loadingItemStore = true;
			this.$skinService.importItemStore()
				.then(res => {
					console.log('Imported item store:', res);
					if (res !== null) {
						this.$store.dispatch('skin/loadSkins', true);
					}
				})
				.catch(err => console.error(err))
				.finally(() => this.loadingItemStore = false);
		}

	}
