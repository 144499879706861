
	import Vue from 'vue';
	import {Component, Emit, Prop} from 'vue-property-decorator';

	@Component
	export default class ReloadButton extends Vue {

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		@Emit('click')
		public click(event: Event): Event {
			return event;
		}

	}
