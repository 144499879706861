
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {IItemGroup} from "@/models/IItemGroup";
	import ItemGroupHelper from "@/helpers/ItemGroupHelper";

	@Component
	export default class ItemGroupAvatar extends Vue {

		@Prop({type: Object, required: true})
		public itemGroup!: IItemGroup;

		@Prop({type: Number, default: 38})
		public size!: number;

		@Prop({type: Boolean, default: false})
		public noTooltip!: boolean;

		private get iconUrl(): string | null {
			return ItemGroupHelper.iconUrl(this.itemGroup);
		}

	}
