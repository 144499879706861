import {Module} from "vuex";
import {State} from "@/store";

export interface CraftCalculator {
	notCraftedItemIds: number[],
	craftOrders: { itemId: number, amount: number }[],
}

const craftCalculator: Module<CraftCalculator, State> = {
	namespaced: true,
	state: {
		notCraftedItemIds: [] as number[],
		craftOrders: [] as { itemId: number, amount: number }[],
	},
	getters: {
		isItemCrafted: (state: CraftCalculator) => (id: number): boolean => {
			return !state.notCraftedItemIds.includes(id);
		},
	},
	mutations: {
		setItemCrafted(state: CraftCalculator, payload: { itemId: number, craft: boolean }) {
			const included = state.notCraftedItemIds.includes(payload.itemId);

			if (payload.craft && included) {
				state.notCraftedItemIds = state.notCraftedItemIds.filter(id => id !== payload.itemId);
			} else if (!payload.craft && !included) {
				state.notCraftedItemIds.push(payload.itemId);
			}
		},
		setCraftOrder(state: CraftCalculator, payload: { itemId: number, amount: number }) {
			const existing = state.craftOrders.find(co => co.itemId === payload.itemId);
			if (existing !== undefined) {
				existing.amount = payload.amount;
			} else {
				state.craftOrders.push(payload);
			}
		},
		removeCraftOrder(state: CraftCalculator, itemId: number) {
			if (!state.craftOrders.some(co => co.itemId === itemId)) return;
			state.craftOrders = state.craftOrders.filter(co => co.itemId !== itemId);
		}
	},
	actions: {}
};

export default craftCalculator;
