
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import SkinList from "@/components/skin/SkinList.vue";
	@Component({
		components: {SkinList}
	})
	export default class SkinsView extends Vue {

	}
