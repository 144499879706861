
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import IProgress from "@/models/IProgress";
	import {IKit} from "@/models/IKit";

	@Component
	export default class KitProgressCircular extends Vue {

		@Prop({type: Object, required: true})
		public kit!: IKit;

		@Prop({type: Number, default: undefined})
		public size!: number | undefined;

		private hover: boolean = false;

		private get progress(): IProgress {
			return this.$store.getters['inventory/skinProgress'](this.kit.skin_ids);
		}

		private get num(): number {
			return Math.round(this.kit.skin_ids.length * (this.progress.progress / 100));
		}

	}
